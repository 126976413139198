<template>
    <div class="site-wrapper">
        <div class="container">

            <!-- <h3>Passenger Manifests</h3> -->

            <div v-if="currentVessel != '' && currentVessel != undefined">

                <h1>{{ currentVessel.vesselName }}</h1>

                <h1 v-if="loadingData"><span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span></h1>

                <!-- ============================================================================== -->


                <div v-if="!loadingData">

                    <div v-if="customLogs.length > 0" class="logs-wrapper">

                        <h4>Custom Vessels Logs</h4>
                        <div class="spacer s30"></div>

                        <!-- <div v-for="(list, idx) in customLogs" class="buttons custom-logs" :key="`custom-${idx}`">
                            <span class="button is-medium complex is-skip" :class="{'is-danger': !list.lastLogComplete}" @click="toCustomChecklist(list)">
                                <span class="button-icon"><font-awesome-icon v-if="list.lastLogComplete" icon="list" /><font-awesome-icon v-else icon="exclamation-triangle" /></span>
                                <span class="button-text">{{list.checklistName}}</span>
                            </span>
                        </div> -->

                        <div v-for="(log, idx) in customLogs" :key="`custom-${idx}`" class="button complex is-medium is-skip" @click="toCustomLog(log)">
                            <div class="button-icon"><span><font-awesome-icon icon="pen" /></span></div>
                            <div class="button-text">
                                <div class="button-title">{{log.checklistName}}</div>
                                <div class="button-subtitle">{{ truncate(log.logDescription,100) }}</div>
                            </div>
                        </div>
                    

                    </div>

                    <div class="" v-else>
                        No logs found.
                    </div>

                </div>



                <!-- ============================================================================== -->

            </div>

            <div class="spacer s20"></div>

            <div class="buttons centered">
                <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp;
                    Back</span>
            </div>

            <div class="spacer s50"></div>




        </div>
    </div>
</template>
  
<script>

import { db } from '../main.js';
import { collection, query, doc, orderBy, where, onSnapshot, getDocs } from "firebase/firestore";
const DetailsModal = () => import('../reusables/DetailsModal.vue');

export default {

    mounted: function () {
        this.loadCustomLogs();
    },

    components: {
        // DetailsModal,
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
    },

    data: function () {
        return {
            customLogs: [],
            loadingData: false,


        }
    },

    methods: {


        close() {

        },

        goBack() {
            this.$router.go(-1);
        },

        truncate(string,limit) {
            // console.log(string,limit);
            if (string == undefined || string == '') return '';
            return string.length > limit ? string.slice(0,limit) + '...' : string;
        },

        async loadCustomLogs() {
            let self = this;
            if (this.currentVessel.vesselID) {
                this.loadingData = true;
                const q = query(collection(db, "vessels", this.currentVessel.vesselID, "customChecklists"), orderBy("index", "asc"));
                const data = onSnapshot(q, (querySnapshot) => {
                    self.customLogs = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        let allowWebLink = data.allowWebLink ? data.allowWebLink : false;
                        let showInQR = data.webLinkInQr ? data.webLinkInQr : false;
                        if (allowWebLink && showInQR) self.customLogs.push(data);
                    });
                },
                (error) => {
                    console.log('error',error);
                    self.loadingData = false;
                });
                setTimeout(() => {
                    console.log('loaded');
                    self.loadingData = false;
                }, 1500);

            }

        },

        formatDate: function (sentDate, format) {
            // return moment(sentDate).format(format);
            return new Date(sentDate).toDateString();
        },

        toCustomLog(log) {
            console.log(log);
            let currentCustomLog = log.id;
            let webLink = log.webLink ? log.webLink : '';
            this.$store.dispatch("SETCURRENTCUSTOMLOG", { currentCustomLog });
            let self = this;
            this.$router.push({
                name: 'VesselLogs',
                params: {
                    hasLog: webLink != '',
                    webLink: webLink,
                    sentCustomLog: log,
                },
            });
        }


    },




}

</script>
  
  
  
<style lang="scss" scoped>

// .button-icon {
//     width: 73px !important;
//     height: 37px;
// }
</style>
  
  
  
  
  
  
  
  
  
  
  
  
  
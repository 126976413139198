import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { db } from '../main.js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, doc, onSnapshot, increment } from 'firebase/firestore';

export default new Vuex.Store({
    state: {
        currentVessel: '',
        currentManifestID: '',
        currentPassengerID: '',
        currentPassengers: [],

        subscriptionInfo: {},
        user: {},
        currentVesselID: '',
        currentLogMeta: {},
        currentLogData: {},
        currentDefectMeta: {},
        currentDefectData: {},
        currentTokenMeta: {},
        currentTokenData: {},
        currentCustomLog: {},

        currentPassengerData: {},
        currentPassengerManifestData: {},
        paxCodeAttempts: 0,
        phoneVerified: false
    },

    modules: {},

    getters: {
        getCurrentVessel: (state) => {
            return state.currentVessel;
        },
        getCurrentManifestID: (state) => {
            return state.currentManifestID;
        },
        getCurrentPassengerID: (state) => {
            return state.currentPassengerID;
        },
        getCurrentPassengers: (state) => {
            return state.currentPassengers;
        },

        getUser: (state) => {
            return state.user;
        },
        getSubscriptionInfo: (state) => {
            return state.subscriptionInfo;
        },
        getCurrentVesselID: (state) => {
            return state.currentVesselID;
        },
        getCurrentLogMeta: (state) => {
            return state.currentLogMeta;
        },
        getCurrentLogData: (state) => {
            return state.currentLogData;
        },
        getCurrentDefectMeta: (state) => {
            return state.currentDefectMeta;
        },
        getCurrentDefectData: (state) => {
            return state.currentDefectData;
        },
        getCurrentTokenMeta: (state) => {
            return state.currentTokenMeta;
        },
        getCurrentTokenData: (state) => {
            return state.currentTokenData;
        },
        getCurrentCustomLog: (state) => {
            return state.currentCustomLog;
        },
        getCurrentPassengerData: (state) => {
            return state.currentPassengerData;
        },
        getCurrentPassengerManifestData: (state) => {
            return state.currentPassengerManifestData;
        },
        getPaxCodeAttempts: (state) => {
            return state.paxCodeAttempts;
        },
        getPhoneVerified: (state) => {
            return state.phoneVerified;
        }
    },

    mutations: {
        SETSELECTEDVESSEL: (state, payload) => {
            // console.log("PAYLOAD: ",payload.allData);
            state.currentVessel = payload.allData;
        },
        CLEARSELECTEDVESSEL: (state) => {
            state.currentVessel = '';
        },
        UPDATECURRENTMANIFEST: (state, payload) => {
            state.currentManifestID = payload.currentManifestID;
        },
        SETCURRENTCUSTOMLOG: (state, payload) => {
            state.currentCustomLog = payload.currentCustomLog;
        },
        UPDATECURRENTPASSENGER: (state, payload) => {
            state.currentPassengerID = payload.currentPassengerID;
        },
        UPDATECURRENTPASSENGERS: (state, payload) => {
            state.currentPassengers = payload.currentPassengers;
        },
        UPDATECURRENTPASSENGERDATA: (state, payload) => {
            state.currentPassengerData = payload.currentPassengerData;
        },
        UPDATECURRENTPASSENGERMANIFESTDATA: (state, payload) => {
            state.currentPassengerManifestData = payload.currentPassengerManifestData;
        },

        SETSUBSCRIPTIONINFO: (state) => {
            if (state.user) {
                // console.log('Setting subscription info:',state.user);

                const unsub = onSnapshot(doc(db, 'userdata', state.user.uid), (doc) => {
                    // console.log("Current data: ", doc.data());
                    let data = doc.data();
                    let tempDate = data.anniversaryDate ? data.anniversaryDate.toDate() : '';
                    let stringDate = String(tempDate);
                    data.anniversaryDate = stringDate;
                    state.subscriptionInfo = data;
                });
            } else {
                console.log('No user..');
            }
        },

        SETUSER: (state) => {
            // state.user = firebase.auth().currentUser;
            const auth = getAuth();
            state.user = auth.currentUser;
        },

        SETCURRENTVESSEL: (state, payload) => {
            state.currentVesselID = payload.vesselID;
        },

        SETCURRENTLOGMETA: (state, payload) => {
            state.currentLogMeta = payload.logMeta;
        },

        SETCURRENTLOGDATA: (state, payload) => {
            state.currentLogData = payload.logData;
        },

        SETCURRENTDEFECTMETA: (state, payload) => {
            state.currentDefectMeta = payload.logMeta;
        },
        SETCURRENTDEFECTDATA: (state, payload) => {
            state.currentDefectData = payload.logData;
        },

        SETCURRENTTOKENMETA: (state, payload) => {
            state.currentTokenMeta = payload.logMeta;
        },
        SETCURRENTTOKENDATA: (state, payload) => {
            state.currentTokenData = payload.logData;
        },

        UPDATEUSERSTATE: (state) => {
            // var user = firebase.auth().currentUser;

            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    state.user = user;
                    console.log('Updating user state:', user);
                    const unsub = onSnapshot(doc(db, 'userdata', user.uid), (doc) => {
                        console.log('Current data: ', doc.data());
                        let data = doc.data();
                        state.isUserAdmin = data.userrole == 'free';
                        state.subscriptionInfo.userrole = data.userrole ? data.userrole : '';
                    });
                    // ...
                } else {
                    console.log('No user found');
                    // ...
                }
            });

            // const auth = getAuth();
            // const user = auth.currentUser;
            // if (user) {
            //     //console.log("1: ", user.uid);
            //   // user.getIdTokenResult(true)
            //   //   .then((idTokenResult) => {
            //   //   if (idTokenResult.claims.isAdmin) {
            //   //     state.isUserAdmin = true;
            //   //   } else {
            //   //     state.isUserAdmin = false;
            //   //   }
            //   // });
            //   // alternative test for user admin => check if starter or fleet user role

            //   const unsub = onSnapshot(doc(db, 'userdata', user.uid), (doc) => {
            //       console.log("Current data: ", doc.data());
            //       let data = doc.data();
            //       state.isUserAdmin = data.userrole == 'free';
            //       state.subscriptionInfo.userrole = data.userrole ? data.userrole : '';
            //   });

            // }
        },
        INCREMENTPAXCODEATTEMPTS(state) {
            state.paxCodeAttempts++;
        },
        RESETPAXCODEATTEMPTS(state) {
            state.paxCodeAttempts = 0;
        },
        SETPHONEVERIFIED(state, payload) {
            state.phoneVerified = payload.value;
        },
        CLEARPASSENGERDATA(state) {
            state.currentPassengerData = {};
            state.currentPassengerManifestData = {};
            state.paxCodeAttempts = 10;
            state.phoneVerified = false;
        }
    },

    actions: {
        UPDATESELECTEDVESSEL: (context, payload) => {
            context.commit('SETSELECTEDVESSEL', payload);
        },
        CLEARSELECTEDVESSEL: (context) => {
            context.commit('CLEARSELECTEDVESSEL');
        },
        SETCURRENTMANIFEST: (context, payload) => {
            context.commit('UPDATECURRENTMANIFEST', payload);
        },
        SETCURRENTPASSENGER: (context, payload) => {
            context.commit('UPDATECURRENTPASSENGER', payload);
        },
        SETCURRENTPASSENGERS: (context, payload) => {
            context.commit('UPDATECURRENTPASSENGERS', payload);
        },
        SETCURRENTPASSENGERDATA: (context, payload) => {
            context.commit('UPDATECURRENTPASSENGERDATA', payload);
        },
        SETCURRENTPASSENGERMANIFESTDATA: (context, payload) => {
            context.commit('UPDATECURRENTPASSENGERMANIFESTDATA', payload);
        },

        updateUserState: (context) => {
            context.commit('UPDATEUSERSTATE');
        },
        SETSUBSCRIPTIONINFO: (context) => {
            context.commit('SETSUBSCRIPTIONINFO');
        },
        setUser: (context) => {
            context.commit('SETUSER');
        },
        setCurrentVessel: (context, payload) => {
            context.commit('SETCURRENTVESSEL', payload);
        },
        setCurrentLogMeta: (context, payload) => {
            context.commit('SETCURRENTLOGMETA', payload);
        },
        setCurrentLogData: (context, payload) => {
            context.commit('SETCURRENTLOGDATA', payload);
        },
        SETCURRENTCUSTOMLOG: (context, payload) => {
            context.commit('SETCURRENTCUSTOMLOG', payload);
        },
        setCurrentDefectMeta: (context, payload) => {
            context.commit('SETCURRENTDEFECTMETA', payload);
        },
        setCurrentDefectData: (context, payload) => {
            context.commit('SETCURRENTDEFECTDATA', payload);
        },
        setCurrentTokenMeta: (context, payload) => {
            context.commit('SETCURRENTTOKENMETA', payload);
        },
        setCurrentTokenData: (context, payload) => {
            context.commit('SETCURRENTTOKENDATA', payload);
        },
        incrementPaxCodeAttempts(context) {
            context.commit('INCREMENTPAXCODEATTEMPTS');
        },
        SETPHONEVERIFIED(context, payload) {
            context.commit('SETPHONEVERIFIED', payload);
        },
        CLEARPASSENGERDATA(context) {
            context.commit('CLEARPASSENGERDATA');
        }
    }
});

<template>
  <div class="site-wrapper">
    <div class="container">

      <h3>Visitor Check-in</h3>

      <div v-if="currentVessel != '' && currentVessel != undefined">

        <h1>{{ currentVessel.vesselName }}</h1>


        <!-- ============================================================================== -->

        <div class="step-wrapper">

          <div class="step-progress">

            <ul class="steps is-horizontal is-small">
              <li class="steps-segment" :class="{ 'is-active': step === 1 }">
                <span class="steps-marker">1</span>
              </li>
              <li class="steps-segment" :class="{ 'is-active': step === 2 }">
                <span class="steps-marker">2</span>
              </li>
              <li class="steps-segment" :class="{ 'is-active': step === 3 }">
                <span class="steps-marker">3</span>
              </li>
              <li class="steps-segment" :class="{ 'is-active': step === 4 }">
                <span class="steps-marker">4</span>
              </li>
            </ul>

          </div> <!-- // step-progress -->

          <div class="steps-content">

            <transition :name="transitionName">

              <div class="slide-section" v-if="step === 1">

                <h2>Your information</h2>
                <h6>Please enter your contact details below.</h6>

                <div class="spacer s20"></div>

                <div class="info-field">
                  <label>Full name</label>
                  <input type="text" class="input" placeholder="Type your full name.." v-model="info.name">
                </div>

                <div class="info-field">
                  <label>Mobile number</label>
                  <input type="text" class="input" placeholder="Type your number.." v-model="info.mobile">
                </div>

                <div class="info-field">
                  <label>Address</label>
                  <input type="text" class="input" placeholder="Type your address.." v-model="info.address">
                </div>

                <div class="info-field">
                  <label>What is your role?</label>
                  <div class="checkbox-wrapper">
                    <div class="field" v-for="role in allRoles" :key="role">
                      <input class="is-checkradio" type="checkbox" :id="role" :value="role" v-model="info.roles" />
                      <label :for="role">{{ role }}</label>
                    </div>
                  </div>
                </div>

                <div class="info-field">
                  <label>Any other important information?</label>
                  <textarea tclass="textarea" placeholder="Allergies, medications etc.." v-model="info.notes"></textarea>
                </div>

                <div class="clear"></div>

              </div>

            </transition>

            <transition :name="transitionName">

              <div class="slide-section" v-if="step === 2">

                <h2>Visitor Safety Checklist</h2>
                <h6>Check the items off in the list below that you are happy with.</h6>

                <div class="spacer s30"></div>

                <div v-if="checklist.length > 0" class="checklist-wrapper">

                  <div class="checklist-item" :class="{ 'checked': item.checked }" v-for="item in checklist" :key="item.id">
                    <div class="check-icon" @click="item.checked = !item.checked"><span
                        v-if="item.checked"><font-awesome-icon icon="check-circle" /></span><span
                        v-else><font-awesome-icon icon="circle" /></span></div>
                    <div class="item-content" @click="item.checked = !item.checked">{{ item.title }}</div>
                    <div v-if="item.details != ''" class="details-icon" @click="showDetails(item)"><font-awesome-icon
                        icon="info-circle" /></div>
                    <div class="clear"></div>
                  </div>

                </div>


                <div class="clear"></div>

              </div>

            </transition>

            <transition :name="transitionName">

              <div class="slide-section" v-if="step === 3">

                <h2>Signature required</h2>
                <h6>Sign in the box below to confirm that you have completed this visitor check-in.</h6>

                <div class="signatureWrapper" :class="[hasSignature ? 'signed' : '']">
                  <VueSignaturePad ref="signaturePad" :options="{ onBegin, onEnd }" />
                </div>
                <div class="buttons signatureButtons">
                  <span v-show="hasSignature" class="button is-warning" @click="undo()">Undo</span>
                  <span v-show="hasSignature" class="button is-danger" @click="clearSignature()">Clear</span>
                </div>



                <div class="clear"></div>

              </div>

            </transition>

            <transition :name="transitionName">

              <div class="slide-section" v-if="step === 4">

                <h2>Finish</h2>
                <h6>Please click the button below to complete the visitor check-in.</h6>

                <div class="spacer s20"></div>

                <div class="buttons centered">
                  <span v-if="!saving" class="button is-primary is-large" @click="saveCheckIn()">Finish Check-in</span>
                  <span v-if="saving && !saved" class="button is-primary is-large"><span
                      class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>&nbsp;
                    Saving...</span>
                  <span v-if="saving && saved" class="button is-primary is-large"><span><font-awesome-icon
                        icon="thumbs-up" /></span>&nbsp; Check-in saved</span>
                </div>

                <div class="spacer s20"></div>

                <div class="clear"></div>

              </div>

            </transition>


          </div> <!-- //step-content -->


          <div class="clear"></div>

        </div> <!-- //step-wrapper -->

        <hr>

        <div v-if="!saving" class="buttons nextPrev">
          <button v-if="step > 1" class="button previous" @click.prevent="prev()"> <span><font-awesome-icon
                icon="chevron-left" /></span> Step {{ step - 1 }}</button>
          <button :disabled="!canProceed" v-if="step < totalSteps" class="button next" @click.prevent="next()">Step {{ step
            + 1 }} <span><font-awesome-icon icon="chevron-right" /></span></button>
        </div>


        <details-modal :title="selectedItem.title" theme="green" @closed="close()" v-if="showDetailsModal">
          <div v-for="(line, index) in selectedItem.details.split('\n')" :key="index">{{ line }}<br></div>
        </details-modal>




        <!-- ============================================================================== -->

      </div>


      <div class="spacer s40"></div>

      <div v-if="!saving" class="buttons centered">
        <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back to Vessel
          Info</span>
      </div>

      <div class="spacer s90"></div>


    </div>
  </div>
</template>

<script>

import { db } from '../main.js';
// import {  collection, doc, onSnapshot, setDoc, getDoc } from "firebase/firestore";
import { collection, addDoc, query, doc, orderBy, onSnapshot, getDocs } from "firebase/firestore";
const DetailsModal = () => import('../reusables/DetailsModal.vue');

export default {

  mounted: function () {
    this.loadChecklist();
    this.loadFleetChecklist();
  },

  components: {
    DetailsModal,
  },

  computed: {
    currentVessel() {
      return this.$store.getters.getCurrentVessel;
    },
    canProceed() {
      return this.step == 1 ? this.info.name != '' : this.step == 2 ? this.checkedItems.length > 0 : this.step == 3 ? this.hasSignature : false;
    },
    checkedItems() {
      // return this.checklist.filter((a) => a.checked).map((b) => b);
      return this.checklist.filter((a) => a.checked).map((b) => {
        return {
          details: b.details,
          title: b.title,
          value: true
        }
      });
    },
  },

  data: function () {
    return {
      step: 1,
      totalSteps: 4,
      transitionName: 'slide-right',
      info: {
        name: '',
        mobile: '',
        address: '',
        roles: [],
        notes: ''
      },
      allRoles: ['Passenger', 'Crew', 'Contractor', 'Police or Emergency Services', 'Other'],
      checklist: [],
      loadingData: false,
      showDetailsModal: false,
      selectedItem: '',
      hasSignature: false,
      signature: '',
      saving: false,
      saved: false,
    }
  },

  methods: {

    toPage(page) {

    },

    goBack() {
      this.$router.go(-1);
    },

    prev() {
      window.scrollTo(0, 0);
      this.transitionName = 'slide-right';
      this.step--;
    },

    next() {
      // this.canProceed();
      if (this.step == 3) {
        this.saveSignature();
      }
      window.scrollTo(0, 0);
      this.transitionName = 'slide-left';
      this.step++;
    },

    close() {
      this.showDetailsModal = false;
      this.selectedItem = '';
    },

    async loadChecklist() {
      let self = this;
      if (this.currentVessel.vesselID) {
        this.loadingData = true;
        const q = query(collection(db, "vessels", this.currentVessel.vesselID, "smsPassengerInductionItems"), orderBy("index", "asc"));
        const data = onSnapshot(q, (querySnapshot) => {
          self.checklist = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            self.checklist.push({
              id: doc.id,
              title: doc.data().title ? doc.data().title : 'No title',
              details: doc.data().details ? doc.data().details : '',
              index: doc.data().index ? doc.data().index : 999,
              link: doc.data().link ? doc.data().link : '',
              checked: false
            });
          });
        },
          (error) => {
            self.loadingData = false;
          });
        setTimeout(() => {
          console.log('loaded');
          self.loadingData = false;
        }, 1500);

      }

    },

    loadFleetChecklist() {
      let self = this;
      if (this.currentVessel.vesselID && this.currentVessel.isCompanyVessel) {

        const q = query(collection(db, "companies", this.currentVessel.vesselOwnerID, "companyPassengerInductionItems"), orderBy("index", "asc"));
        const data = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (self.currentVessel.savedCompanyPassengerInductionItems && self.currentVessel.savedCompanyPassengerInductionItems.includes(doc.id)) {
              self.checklist.push({
                id: doc.id,
                title: doc.data().title ? doc.data().title : 'No title',
                details: doc.data().details ? doc.data().details : '',
                index: doc.data().index ? doc.data().index : 999,
                link: doc.data().link ? doc.data().link : '',
                checked: false
              });
            }
          });
        },
          (error) => {
            console.log('Fleet snap error');
          });

      }
    },

    showDetails(item) {
      // console.log(item);
      this.selectedItem = item;
      if (item.details != '') {
        this.showDetailsModal = true;
      }
    },

    onBegin() {
      // console.log('=== Begin ===');
    },

    onEnd() {
      // console.log('=== End ===');
      this.checkSignature();
    },


    undo() {
      this.$refs.signaturePad.undoSignature();
      this.checkSignature();
    },

    saveSignature() {
      // console.log(this.$refs.signaturePad);
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // console.log(isEmpty);
      // console.log(data);
      this.signature = data;
      // console.log('DATA',this.signature);
    },

    clearSignature: function () {
      this.$refs.signaturePad.clearSignature();
      this.checkSignature();
    },

    checkSignature: function () {
      this.hasSignature = !this.$refs.signaturePad.isEmpty();
    },

    async saveCheckIn() {
      this.saving = true;

      let checkinData = {
        vesselName: this.currentVessel.vesselName,
        vesselID: this.currentVessel.vesselID,
        timestamp: new Date(),
        notes: this.info.notes,
        personName: this.info.name,
        address: this.info.address,
        mobileNumber: this.info.mobile,
        selectedItems: this.checkedItems,
        visitorRoles: this.info.roles.length > 0 ? this.info.roles : ['Other'],
        signature: this.signature,
      };

      // console.log(checkinData);

      const docRef = await addDoc(collection(db, "vessels", this.currentVessel.vesselID, "visitorCheckIns"), checkinData);
      // console.log("Document written with ID: ", docRef.id);

      // console.log('adding complete');

      setTimeout(() => {
        this.saved = true;
      }, 2000);
      setTimeout(() => {
        this.goBack();
      }, 4000);


    }



  },




}

</script>



<style lang="scss" scoped>
.buttons {
  &.nextPrev {
    display: inline-block;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 1.2rem 1rem;
    background: #404664;
    /* opacity: 0.9; */
    z-index: 9;

    .button {
      font-size: 0.9rem;

      &.previous {
        float: left;
        background: #dd7979;
        border-color: #dd7979;
        color: #fff;

        span {
          margin-right: 5px;
        }
      }

      &.next {
        float: right;
        background: #67bf8e;
        border-color: #67bf8e;
        color: #fff;

        span {
          margin-left: 5px;
        }
      }
    }
  }

  &.signatureButtons {
    height: 40px;
    display: block;
  }

  .is-large {
    font-size: 1.2rem;
    padding: 1.5rem;
    font-weight: bold;
  }
}

.slide-section {
  margin: 1rem auto 2rem;

  h2 {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.info-field {
  margin-bottom: 1.2rem;
  text-align: left;

  .checkbox-wrapper {
    background: #fff;
    padding: 1rem;
    border-radius: 3px;
    color: #222;
    display: block;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input[type="text"] {
    padding: 1rem;
    height: auto;
    border: none;
  }

  textarea {
    display: block;
    width: 100%;
    border: none;
    padding: 1rem;
    border-radius: 3px;
    color: #222;
    font-size: 1rem;

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.checklist-wrapper {
  .checklist-item {
    background: #7a9dbf;
    cursor: pointer;

    &.checked {
      background: #67bf8e;
    }

    .check-icon {
      width: 40px;
      // height: 40px;
      display: inline-block;
      font-size: 25px;
      vertical-align: text-top;
    }

    .item-content {
      display: inline-block;
      vertical-align: text-top;
      display: inline-block;
      width: calc(100% - 100px);
      float: none;
    }

    .details-icon {
      width: 30px;
      height: 30px;
      text-align: center;
    }
  }
}

.signatureWrapper {
  background: #fff;
  border: 1px solid red;
  width: 100%;
  height: 180px;
  margin: 1rem 0;

  &.signed {
    border-color: #e9e9e9;
  }
}</style>













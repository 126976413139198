// https://offshoresms.com.au/link?u=y3tdRTE336xc455

<template>
    <div class="site-wrapper">

        <div class="container">

            <h1 v-if="loadingData"><span class="loading-spinner"><font-awesome-icon icon="spinner"
                        class="fa-spin 3x" /></span> Redirecting..</h1>

            <div v-if="errMsg != ''" class="notification is-warning">
                <p>{{ errMsg }}</p>
            </div>

            <!-- <p>{{targetUrl}}</p> -->



        </div>

    </div>
</template>






<script>

import { db } from '../main.js';
import { doc, getDoc } from "firebase/firestore";

export default {

    name: 'Support',
    computed: {

    },
    data: function () {
        return {
            browser: '',
            targetUrl: '',
            shortLink: '',
            errMsg: '',
            loadingData: false,
        }
    },
    mounted() {
        // this.browser = this.$browserDetect.meta.name;
        this.shortLink = this.$route.query.u ? this.$route.query.u : '';
        this.redirect();
    },

    methods: {
        async redirect() {
            let self = this;
            this.loadingData = true;

            const docRef = doc(db, 'shortLinks', this.shortLink);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                self.targetUrl = docSnap.data().targetUrl ? docSnap.data().targetUrl : 'https://offshoresms.com.au';
                window.location.href = self.targetUrl;
                // window.location.replace(self.targetUrl);
                // self.$router.push(self.targetUrl);
                // self.loadingData = false;
            } else {
                self.loadingData = false;
                self.errMsg = 'Something went wrong, sorry. Please contact the vessel owner.';
            }

        }

    },

}





</script>




<style lang="scss" scoped>
.container {
    max-width: 95% !important;
}
</style>
<template>
    <div class="">
        <div class="container">
            <div v-if="checkingDetails || loadingDefectData">
                <div class="spacer s30"></div>
                <h3>
                    <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>&nbsp; {{ status }}
                </h3>
            </div>

            <div class="spacer s30 desktop"></div>

            <div v-if="formLoaded">
                <div v-if="accessAllowed">
                    <div v-if="requiresLogin">
                        <div v-if="userHasAccess">
                            <DefectData />
                        </div>
                        <div v-else>
                            <div class="spacer s30"></div>
                            {{ formMessage }} <br />
                            <div class="spacer s30"></div>
                            <div v-if="!isLoggedIn" class="button is-primary" @click="showLogin()">Login required</div>
                        </div>
                    </div>
                    <div v-else>
                        <DefectData />
                    </div>
                </div>
                <div v-else>
                    <div class="spacer s30"></div>
                    {{ formMessage }}
                </div>
            </div>
            <div v-else>
                <div class="spacer s30"></div>
                {{ errorMessage }}
            </div>

            <div class="buttons floating">
                <span v-if="linkedSuppliers.length > 0" class="button is-medium is-primary" @click="showTimelineModal = true">
                    <font-awesome-icon icon="plus" /> &nbsp; Add to Timeline
                </span>
            </div>

            <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showTimelineModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card is-primary">
                    <header class="modal-card-head is-primary">
                        <p class="modal-card-title">Add to the Defect Timeline</p>
                    </header>
                    <section class="modal-card-body">
                        <div v-if="attempts < 6">
                            <div v-if="pinCorrect">
                                <label class="label">Your name:</label>
                                <input
                                    type="text"
                                    placeholder="Type your name.."
                                    :disabled="savingEntry"
                                    maxlength="100"
                                    class="input padded"
                                    v-model="newName" />
                                <div class="spacer s10"></div>
                                <label class="label">Timeline Details:</label>
                                <textarea
                                    type="text"
                                    placeholder="Type what you have done to resolve this defect.."
                                    maxlength="1000"
                                    class="textarea padded"
                                    :disabled="savingEntry"
                                    v-model="newEntry"></textarea>
                                <div class="spacer s20"></div>
                                <div class="buttons">
                                    <button
                                        :disabled="newName == '' || newEntry == '' || savingEntry"
                                        class="button is-medium is-success"
                                        @click="addEntry()">
                                        <span v-if="!savingEntry"><font-awesome-icon icon="check" />&nbsp; Save Timeline Entry</span>
                                        <span v-else><font-awesome-icon icon="spinner" class="fa-spin 3x" /> Saving entry...</span>
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <label class="label">Enter your supplier PIN Code:</label>
                                <input type="text" placeholder="xxxx" maxlength="4" class="input padded short" v-model="newPin" />
                                <p class="red bold">{{ errorMsg }}</p>
                                <div class="spacer s10"></div>
                                <div class="buttons">
                                    <button :disabled="validating || newPin.length < 4" class="button is-medium is-warning" @click="validatePin()">
                                        <span v-if="!validating"><font-awesome-icon icon="link" />&nbsp; Check Pin Code</span>
                                        <span v-else><font-awesome-icon icon="spinner" class="fa-spin 3x" /> Validating pin...</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p class="red">Too many incorrect attemtps, sorry. Try again later.</p>
                        </div>
                        <hr />
                        <div class="buttons">
                            <a class="button is-info" href="https://docs.offshoresms.com.au/" target="_blank">
                                <font-awesome-icon icon="link" />&nbsp; View User Guides
                            </a>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-primary" @click="close">Close</button>
                    </footer>
                </div>
            </div>

            <div class="spacer s100"></div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, where, doc, onSnapshot, addDoc, getDocs, getDoc, orderBy, limit } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';
import DefectData from './subcomponents/DefectData.vue';
import { confirmPasswordReset } from 'firebase/auth';

const functions = getFunctions();
const cloudFunctionSendMultipleEmails = httpsCallable(functions, 'cloudFunctionSendMultipleEmails');

export default {
    props: {
        sentCustomLog: Object,
        webLink: String
    },

    mounted: function () {
        let hasParam = this.$route.query && this.$route.query.x;
        if (hasParam) {
            this.xID = this.$route.query.x ? this.$route.query.x : '';
        } else {
            this.xID = this.webLink ? this.webLink : '';
        }
        let self = this;
        if (this.xID != '') {
            this.checkCodeExists();
        } else {
            setTimeout(() => {
                self.checkingDetails = false;
                self.status = 'No defect found.';
                self.errorMessage = 'No defect found.';
            }, 1000);
        }
        // this.getIP();
        this.getIPFromAmazon();
    },

    components: {
        DefectData
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userID() {
            return this.userData.userid;
        },
        userFirstName() {
            return this.userData.username.split(' ')[0];
        },
        supplierCodes() {
            return this.linkedSuppliers.map((m) => m.pinCode.toLowerCase());
        }
    },

    data: function () {
        return {
            code: '',
            currentWebLink: '',
            defectResolved: false,
            checkingDetails: true,
            formExists: false,
            loadingDefectData: false,
            ipAddress: '',
            hasData: false,
            status: 'Checking if defect exists..',
            formMessage: '',
            formLoaded: false,
            errorMessage: '',

            formMeta: {},
            formData: {},

            xID: '',
            accessAllowed: false,
            requiresLogin: false,

            userHasAccess: false,
            allVesselCrewIDs: [],

            customFields: [],
            linkedSuppliers: [],
            currentVesselName: '',
            showTimelineModal: false,
            newPin: '',
            attempts: 0,
            validating: false,
            pinCorrect: false,
            newEntry: '',
            newName: '',
            savingEntry: false,
            errorMsg: ''
        };
    },

    methods: {
        close() {
            this.showTimelineModal = false;
            this.newPin = '';
            this.validating = false;
            this.savingEntry = false;
            this.errorMsg = '';
        },

        async getIPFromAmazon() {
            fetch('https://checkip.amazonaws.com/')
                .then((res) => res.text())
                .then((data) => {
                    // console.log('AWS', data);
                    this.ipAddress = data;
                });
        },

        // async text(url) {
        //     const res = await fetch(url);
        //     return await res.text();
        // },

        // async getIP() {
        //     let ip1 = await this.text('https://www.cloudflare.com/cdn-cgi/trace').then((data) => {
        //         let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
        //         let ip = data.match(ipRegex)[0];
        //         console.log(ip);
        //     });
        //     // let ip = fetch('https://www.cloudflare.com/cdn-cgi/trace').then((res) => res.text());
        //     console.log('ip1', ip1);
        // },

        validatePin() {
            // console.log(this.newPin.toLowerCase());
            this.errorMsg = '';
            if (this.attempts > 5) {
                cloudFunctionSendMultipleEmails({
                    senderID: '',
                    senderName: '',
                    recipientEmails: 'help@offshoresms.com.au',
                    msgTitle: 'More than 5 attempts to add to web defect timeline',
                    msgBody:
                        'Someone with an IP address ' +
                        this.ipAddress +
                        ' has tried 5 or more times to add to the defect timeline for the vessel ' +
                        this.formMeta.vesselName +
                        ' (Defect ID: ' +
                        this.formData.id +
                        ')'
                });
            }
            this.attempts++;
            this.validating = true;
            setTimeout(() => {
                if (this.supplierCodes.includes(this.newPin.toLowerCase())) {
                    this.pinCorrect = true;
                    this.validating = false;
                    this.errorMsg = '';
                } else {
                    this.errorMsg = 'Incorrect Supplier Pin Code';
                    this.validating = false;
                }
            }, 1000);
        },

        async addEntry() {
            let self = this;
            this.savingEntry = true;
            let entryData = {
                notes: self.newEntry,
                timestamp: new Date(),
                postedBy: self.newName,
                logTimestamp: new Date(),
                useBackupPhotos: false,
                imageStrings: [],
                imageStringUrls: [],
                newImageUrls: []
            };
            // console.log('entryData', entryData);
            // console.log(this.formMeta.vesselID, this.formData.id);
            const docRef = await addDoc(
                collection(db, 'vessels/' + this.formMeta.vesselID + '/smsDefects/' + this.formData.id + '/timeline'),
                entryData
            );
            setTimeout(() => {
                this.newName = '';
                this.newEntry = '';
                this.close();
            }, 500);
        },

        async checkCodeExists() {
            this.errorMessage = '';
            let self = this;

            const docRef = doc(db, 'defectWebLinkIDs', this.xID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                self.formMeta = docSnap.data();
                if (docSnap.data().vesselID) {
                    let vesselID = docSnap.data().vesselID;
                    self.currentVesselName = docSnap.data().vesselName;
                    self.$store.dispatch('setCurrentVessel', { vesselID });
                }
                let logMeta = self.formMeta;
                self.$store.dispatch('setCurrentDefectMeta', { logMeta });
                setTimeout(() => {
                    self.status = 'Checking status...';
                    self.formExists = true;
                    self.loadingDefectData = true;
                    self.checkingDetails = false;
                    self.loadFormData();
                }, 500);
            } else {
                self.checkingDetails = false;
                self.status = 'This defect could not be loaded.';
                self.errorMessage = 'No defect found.';
            }
        },

        loadFormData() {
            let self = this;
            if (this.formMeta.defectID && this.formMeta.defectID != '') {
                self.hasData = true;
                self.status = 'Loading defect information...';
                setTimeout(() => {
                    self.loadDefect();
                }, 500);
            }
        },

        async loadDefect() {
            let self = this;
            const docRef = doc(db, 'vessels/' + this.formMeta.vesselID + '/smsDefects', this.formMeta.defectID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let data = docSnap.data();
                data.vesselName = self.currentVesselName;
                data.id = docSnap.id;
                self.currentWebLink = docSnap.data().webLink ? docSnap.data().webLink : '';
                self.defectResolved = docSnap.data().resolved ? docSnap.data().resolved : false;
                if (self.defectResolved) {
                    self.loadingDefectData = false;
                    self.errorMessage = 'Defect resolved. Access Closed.';
                    return;
                }
                if (self.xID != self.currentWebLink) {
                    self.loadingDefectData = false;
                    self.errorMessage = 'Defect expired. Access denied.';
                    return;
                }
                self.formData = data;
                let logData = self.formData;
                self.$store.dispatch('setCurrentDefectData', { logData });
                if (docSnap.data().webLinkCrewOnly) {
                    self.loadVesselCrew();
                }
                setTimeout(() => {
                    // self.validateAccess();
                    self.loadLinkedSuppliers();
                    self.accessAllowed = true;
                    self.loadingDefectData = false;
                    self.formLoaded = true;
                }, 500);
            } else {
                self.formMessage = 'This defect is not available, sorry.';
                self.loadingDefectData = false;
            }
        },

        loadLinkedSuppliers() {
            let self = this;
            this.linkedSuppliers = [];
            let fleetID = this.formData.fleetID ? this.formData.fleetID : '';
            let taggedAssets = this.formData.taggedAssets ? this.formData.taggedAssets : [];
            let taggedSuppliers = taggedAssets.map((m) => m.linkedSuppliers).flat();
            // console.log('taggedSuppliers', taggedSuppliers.flat());
            if (fleetID != '' && taggedSuppliers.length > 0) {
                Promise.all(
                    taggedSuppliers.map(async (supplier) => {
                        // console.log(supplier.id);
                        const docRef = doc(db, 'companies/' + fleetID + '/fleetSuppliers', supplier.id);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            // console.log('docSnap', docSnap.data());
                            self.linkedSuppliers.push({
                                id: supplier.id,
                                title: docSnap.data().title,
                                pinCode: docSnap.data().pinCode ? docSnap.data().pinCode : ''
                            });
                        }
                    })
                );
            }
        },

        async loadVesselCrew() {
            let self = this;
            const docRef = doc(db, 'vessels', this.formMeta.vesselID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let data = docSnap.data();
                let ch1 = data.listOfCrewIDs ? data.listOfCrewIDs : [];
                let ch2 = data.listOflevelTwoIDs ? data.listOflevelTwoIDs : [];
                let ch3 = data.listOfMasterIDs ? data.listOfMasterIDs : [];
                self.allVesselCrewIDs = [...ch1, ...ch2, ...ch3];
            }
        },

        validateAccess() {
            let self = this;
            // new supplier pin code validation
            //
            self.accessAllowed = true;
            self.loadingDefectData = false;
            self.formLoaded = true;

            // old custom log validation
            // if (!this.formData.allowWebLink) {
            //     self.loadingDefectData = false;
            //     self.formLoaded = true;
            //     self.formMessage = 'This form is not available, sorry.';
            //     self.accessAllowed = false;
            // } else {
            //     // check if is public or crew restricted
            //     self.accessAllowed = true;
            //     if (self.formData.webLinkCrewOnly) {
            //         self.status = 'Checking user access...';
            //         self.requiresLogin = true;
            //         setTimeout(() => {
            //             self.checkUserAccess();
            //         }, 1000);
            //     } else {
            //         self.loadingDefectData = false;
            //         self.formLoaded = true;
            //     }
            // }
        },

        checkUserAccess() {
            let self = this;
            this.userHasAccess = this.isLoggedIn && this.allVesselCrewIDs.includes(this.userID);
            this.formMessage = this.userHasAccess ? 'Access permitted..' : 'This form is only available to authorised users.';
            self.loadingDefectData = false;
            self.formLoaded = true;
        },

        showLogin() {
            this.$router.push({
                name: 'UserLogin',
                params: {
                    return: true
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 800px !important;
    padding: 1rem 20px !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: pink;
    letter-spacing: 1px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: pink;
    letter-spacing: 1px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: pink;
    letter-spacing: 1px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: pink;
    letter-spacing: 1px;
}

h1 {
    color: #444;
}

.toggle {
    position: fixed;
    top: 65px;
    right: 10px;
    background: cadetblue;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        background: mediumseagreen;
    }
}
input.input.short {
    max-width: 120px;
    font-size: 1.5rem;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 10px;
}

.modal-card-body {
    text-align: left;
}

@media screen and (max-width: 769px) {
    .desktop {
        display: none;
    }
}
</style>

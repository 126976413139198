Vue.config.devtools = true;

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyDCfACjsWTekUlyDrisWs4cktqsD4FS3Dw",
//   authDomain: "dev-offshoresms.firebaseapp.com",
//   //authDomain: "offshoresms.com.au",
//   databaseURL: "https://dev-offshoresms.firebaseio.com",
//   projectId: "dev-offshoresms",
//   storageBucket: "dev-offshoresms.appspot.com",
//   messagingSenderId: "15976963230"
// };

// production firebase instance (app-offshoresms)
const config = {
    apiKey: 'AIzaSyAX5rQ__fBkV2Rd-FvtWB7JPnL7-lMvv4s',
    authDomain: 'app-offshoresms.firebaseapp.com',
    projectId: 'app-offshoresms',
    storageBucket: 'app-offshoresms.appspot.com',
    messagingSenderId: '395258391843',
    appId: '1:395258391843:web:d621d56d614be9f9c8b096',
    measurementId: 'G-30MW3C1M6M'
};

// staging site config (offshoresms-staging):
// const config = {
//     apiKey: 'AIzaSyC-CaC7PZymfKV8uzHrxFwLYpGsoIl6qtA',
//     authDomain: 'offshoresms-staging.firebaseapp.com',
//     projectId: 'offshoresms-staging',
//     storageBucket: 'offshoresms-staging.appspot.com',
//     messagingSenderId: '379176628263',
//     appId: '1:379176628263:web:1b2f0beb5269e43c25de02'
// };

// Initialize Firebase
const firebaseApp = initializeApp(config);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

// export const db = firebase.firestore();
// export const settings = {
//     //timestampsInSnapshots: true, //deprecated
//     // persistence: false,
// };
// db.settings(settings);

import './assets/css/style.scss';

import browserDetect from 'vue-browser-detect-plugin';
Vue.use(browserDetect);

import NProgress from 'vue-nprogress';
Vue.use(NProgress);

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

import VueCountryCode from 'vue-country-code';
Vue.use(VueCountryCode);

import Datetime from 'vue-datetime';
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css';

import vueSmoothScroll from 'vue2-smooth-scroll';
Vue.use(vueSmoothScroll);

Vue.use(Datetime);

// import ImageKit from 'imagekitio-vue';
// Vue.use(ImageKit, {
//     urlEndpoint: 'https://ik.imagekit.io/offshoresms/v2' // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
//     // publicKey: "your_public_api_key", // optional
//     // authenticationEndpoint: "https://www.your-server.com/auth" // optional
//     // transformationPosition: "path" // optional
// });

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCoffee,
    faUserAlt,
    faShip,
    faExclamationTriangle,
    faUser,
    faUsers,
    faFish,
    faFire,
    faHome,
    faBriefcase,
    faUserGraduate,
    faCloud,
    faLifeRing,
    faCheckCircle,
    faMoneyBillAlt,
    faThumbsUp,
    faLink,
    faInfo,
    faInfoCircle,
    faQuestionCircle,
    faCog,
    faList,
    faTimes,
    faCheck,
    faEnvelope,
    faPhone,
    faChevronUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faLock,
    faShoppingCart,
    faWrench,
    faStopwatch,
    faOilCan,
    faStar,
    faVolumeUp,
    faPrint,
    faRegistered,
    faFileSignature,
    faListUl,
    faClipboardList,
    faAnchor,
    faPlay,
    faPen,
    faSearch,
    faCamera,
    faCalendarCheck,
    faTrashAlt,
    faSyncAlt,
    faGasPump,
    faCircle,
    faMinusCircle,
    faSpinner,
    faBook,
    faQuoteRight,
    faMapMarker,
    faCheckSquare,
    faPlus,
    faSort,
    faImage,
    faRedoAlt,
    faGlobe,
    faTachometerAlt,
    faKey,
    faTable,
    faEye,
    faEyeSlash,
    faUserPlus,
    faUserShield,
    faUserSlash
} from '@fortawesome/free-solid-svg-icons';
import {
    // faLinkedin,
    // faTwitter,
    faFacebookF,
    faFacebookSquare,
    faLinkedin,
    faSketch
    //faInstagram,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faCoffee,
    faUserAlt,
    faShip,
    faUsers,
    faUser,
    faFish,
    faExclamationTriangle,
    faFire,
    faHome,
    faBriefcase,
    faUserGraduate,
    faCloud,
    faLifeRing,
    faCheckCircle,
    faMoneyBillAlt,
    faThumbsUp,
    faLink,
    faInfoCircle,
    faInfo,
    faQuestionCircle,
    faCog,
    faList,
    faTimes,
    faCheck,
    faEnvelope,
    faPhone,
    faChevronUp,
    faChevronDown,
    faLock,
    faFacebookF,
    faShoppingCart,
    faWrench,
    faStopwatch,
    faOilCan,
    faStar,
    faChevronLeft,
    faChevronRight,
    faVolumeUp,
    faPrint,
    faFileSignature,
    faListUl,
    faClipboardList,
    faAnchor,
    faPlay,
    faPen,
    faRegistered,
    faSearch,
    faCamera,
    faTrashAlt,
    faCalendarCheck,
    faSyncAlt,
    faGasPump,
    faLinkedin,
    faFacebookSquare,
    faCircle,
    faMinusCircle,
    faSpinner,
    faBook,
    faQuoteRight,
    faSketch,
    faMapMarker,
    faCheckSquare,
    faPlus,
    faSort,
    faImage,
    faRedoAlt,
    faGlobe,
    faTachometerAlt,
    faKey,
    faTable,
    faEye,
    faEyeSlash,
    faUserPlus,
    faUserShield,
    faUserSlash
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

router.beforeEach((to, from, next) => {
    // const currentUser = firebase.auth().currentUser;
    // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // if (requiresAuth && !currentUser) {
    //   next('/sign-in');
    // } else if (requiresAuth && currentUser) {
    //   window.scrollTo(0, 0)
    //   next();
    // } else {
    //   window.scrollTo(0, 0)
    //   next();
    // }

    window.scrollTo(0, 0);
    next();
});

Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    // if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
    // ...
    // } else {
    //   console.log('No user exists in main.');
    // }
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centered"},[_c('div',{staticClass:"hazard-title"},[_vm._v("What is the LIKELIHOOD of this happening?")]),_c('div',{staticClass:"buttons has-addons rating"},[_vm._l((_vm.likelihood),function(val,key){return _c('button',{key:`likelihood-1-${key}`,class:[
                        'button caps',
                        val,
                        { active: _vm.selectedLikelihood.label == val.label, 'not-active': _vm.selectedLikelihood.label !== val.label }
                    ],on:{"click":function($event){return _vm.changeLikelihood(val, key)}}},[_vm._v(" "+_vm._s(val.label)+" ")])}),_c('p',{staticClass:"helper"},[_vm._v(_vm._s(_vm.selectedLikelihood.description))])],2),_c('div',{staticClass:"hazard-title"},[_vm._v("What are the CONSEQUENCES?")]),_c('div',{staticClass:"buttons has-addons rating"},[_vm._l((_vm.consequences),function(val,key){return _c('button',{key:`consequence-1-${key}`,class:[
                        'button caps',
                        val,
                        { active: _vm.selectedConsequence.label == val.label, 'not-active': _vm.selectedConsequence.label !== val.label }
                    ],on:{"click":function($event){return _vm.changeConsequence(val, key)}}},[_vm._v(" "+_vm._s(val.label)+" ")])}),_c('p',{staticClass:"helper"},[_vm._v(_vm._s(_vm.selectedConsequence.description))])],2)]),_c('p',[_vm._v(" ")]),_c('div',{staticClass:"buttons centered"},[_c('span',{staticClass:"button rating",class:_vm.newRatingClass},[_vm._v(" Risk Rating: "),_c('span',{staticClass:"caps"},[_vm._v(" "+_vm._s(_vm.newRatingClass ? _vm.newRatingClass : 'NONE'))])])]),(_vm.viewMatrix)?_c('div',{staticClass:"matrix-outer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"cell empty"}),_vm._l((_vm.likelihood),function(lh,itx){return _c('div',{key:`header-1-${itx}`,staticClass:"cell top",class:{ highlight: _vm.selectedLikelihood.newValue == _vm.likelihood[itx].newValue }},[_vm._v(" "+_vm._s(lh.label)+" ")])})],2),_vm._l((_vm.matrix),function(row,idx){return _c('div',{key:`row-1-${idx}`,staticClass:"row"},[_c('div',{staticClass:"cell left",class:{ highlight: _vm.selectedConsequence.newValue == _vm.consequences[idx].newValue }},[_vm._v(" "+_vm._s(_vm.consequences[idx].label)+" ")]),_vm._l((row),function(cell,icx){return _c('div',{key:`cell-1-${icx}`,staticClass:"cell",class:[_vm.matrixValue[idx][icx], { current: _vm.selectedLikelihood.newValue == icx && _vm.selectedConsequence.newValue == idx }],on:{"click":function($event){return _vm.setRatings(idx, icx)}}},[_c('div',[_vm._v(_vm._s(_vm.matrixValue[idx][icx]))]),(_vm.showScore)?_c('div',{staticClass:"score"},[_vm._v("("+_vm._s(_vm.matrix[idx][icx])+")")]):_vm._e()])})],2)})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="site-wrapper">
        <div class="container">
            <h3>Emergency Procedures</h3>

            <div v-if="currentVessel != '' && currentVessel != undefined">
                <h1>{{ currentVessel.vesselName }}</h1>

                <h1 v-if="loadingData">
                    <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
                </h1>

                <!-- ============================================================================== -->

                <div v-if="!loadingData">
                    <div v-if="checklist.length > 0" class="checklist-wrapper">
                        <div class="checklist-item blue" v-for="item in checklist" :key="item.id" @click="showDetails(item)">
                            <div class="item-content">{{ item.title }}</div>
                            <div v-if="item.details != ''" class="details-icon"><font-awesome-icon icon="info-circle" /></div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="" v-else>No vessel emergency procedures found.</div>
                </div>

                <!-- ============================================================================== -->
            </div>

            <div class="spacer s30"></div>

            <div class="buttons centered">
                <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            </div>

            <div class="spacer s50"></div>

            <details-modal :title="selectedItem.title" theme="blue" @closed="close()" v-if="showDetailsModal">
                <div v-for="(line, index) in selectedItem.details.split('\n')" :key="index">{{ line }}<br /></div>
                <div class="spacer s20"></div>
                <div v-if="selectedItem.link != ''" class="link">
                    <a :href="selectedItem.link" target="_blank" class="simple-link">Link:&nbsp;{{ selectedItem.link }}</a>
                </div>
                <hr />
                <h4 class="heading" v-if="selectedItem.versioned">Last Edit: {{ simpleDate(selectedItem.versionDate) }}</h4>
            </details-modal>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, doc, orderBy, onSnapshot, getDoc, getDocs } from 'firebase/firestore';
const DetailsModal = () => import('../reusables/DetailsModal.vue');

export default {
    mounted: function () {
        this.loadVersionedChecklist();
        // this.loadChecklist();
        // this.loadFleetChecklist();
    },

    components: {
        DetailsModal
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        }
    },

    data: function () {
        return {
            checklist: [],
            loadingData: false,
            showDetailsModal: false,
            selectedItem: ''
        };
    },

    methods: {
        toPage(page) {},

        simpleDate(sentDate) {
            const dateOptions = { timeZone: 'Australia/Sydney', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            return sentDate == '' ? '' : sentDate.toDate().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        close() {
            this.showDetailsModal = false;
            this.selectedItem = '';
        },

        goBack() {
            this.$router.go(-1);
        },

        loadVersionedChecklist() {
            let self = this;
            const q = query(collection(db, 'vessels', this.currentVessel.vesselID, 'smsEOPS'), orderBy('index', 'asc'));
            const data = onSnapshot(
                q,
                (querySnapshot) => {
                    self.checklist = [];
                    querySnapshot.forEach(async (docA) => {
                        let loadVersion = docA.data().currentVersionID ? true : false;
                        let archived = docA.data().archived ? docA.data().archived : false;
                        if (loadVersion && !archived) {
                            const refA = doc(
                                db,
                                'vessels/' + self.currentVessel.vesselID + '/smsEOPS/' + docA.id + '/smsEOPSVersions',
                                docA.data().currentVersionID
                            );
                            const docRef = await getDoc(refA);
                            if (docRef.exists()) {
                                self.checklist.push({
                                    id: docRef.id,
                                    title: docRef.data().title,
                                    details: docRef.data().details ? docRef.data().details : '',
                                    link: docRef.data().link ? docRef.data().link : '',
                                    index: docRef.data().index ? docRef.data().index : 999,
                                    uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                                    versioned: true,
                                    savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                                    versionDate: docRef.data().versionDate ? docRef.data().versionDate : ''
                                });
                            }
                        } else {
                            if (!archived)
                                self.checklist.push({
                                    id: doc.id,
                                    title: doc.data().title,
                                    details: doc.data().details ? doc.data().details : '',
                                    link: doc.data().link ? doc.data().link : '',
                                    index: doc.data().index ? doc.data().index : 999,
                                    uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                                    versioned: false
                                });
                        }
                    });
                },
                (error) => {
                    self.loadingData = false;
                }
            );
            if (this.currentVessel.vesselID && this.currentVessel.isCompanyVessel) this.loadFleetChecklist();
            setTimeout(() => {
                console.log('checklist loaded');
                self.loadingData = false;
            }, 1500);
        },

        loadFleetChecklist() {
            let self = this;
            if (this.currentVessel.vesselID && this.currentVessel.isCompanyVessel) {
                const q = query(collection(db, 'companies', this.currentVessel.vesselOwnerID, 'companyEOPS'), orderBy('index', 'asc'));
                const data = onSnapshot(
                    q,
                    (querySnapshot) => {
                        querySnapshot.forEach(async (docB) => {
                            if (self.currentVessel.savedCompanyEOPS && self.currentVessel.savedCompanyEOPS.includes(docB.id)) {
                                // new
                                let loadVersion = docB.data().currentVersionID ? true : false;
                                let archived = docB.data().archived ? docB.data().archived : false;
                                if (self.currentVessel.savedCompanyEOPS.includes(docB.id) && loadVersion && !archived) {
                                    const refB = doc(
                                        db,
                                        'companies/' + self.currentVessel.vesselOwnerID + '/companyEOPS/' + docB.id + '/companyEOPSVersions',
                                        docB.data().currentVersionID
                                    );
                                    const docRefTwo = await getDoc(refB);
                                    if (docRefTwo.exists()) {
                                        self.checklist.push({
                                            id: docRefTwo.id,
                                            title: docRefTwo.data().title,
                                            details: docRefTwo.data().details ? docRefTwo.data().details : '',
                                            link: docRefTwo.data().link ? docRefTwo.data().link : '',
                                            index: docRefTwo.data().index ? docRefTwo.data().index : 999,
                                            uploadUrls: docRefTwo.data().uploadUrls ? docRefTwo.data().uploadUrls : [],
                                            versioned: true,
                                            savedByName: docRefTwo.data().savedByName ? docRefTwo.data().savedByName : '',
                                            versionDate: docRefTwo.data().versionDate ? docRefTwo.data().versionDate : ''
                                        });
                                    }
                                } else {
                                    if (self.currentVessel.savedCompanyEOPS.includes(docB.id) && !archived)
                                        self.checklist.push({
                                            id: doc.id,
                                            title: doc.data().title,
                                            details: doc.data().details ? doc.data().details : '',
                                            link: doc.data().link ? doc.data().link : '',
                                            index: doc.data().index ? doc.data().index : 999,
                                            uploadUrls: doc.data().uploadUrls ? doc.data().uploadUrls : [],
                                            versioned: false
                                        });
                                }
                            }
                        });
                    },
                    (error) => {
                        console.log('Fleet snap error');
                    }
                );
            }
        },

        showDetails(item) {
            // console.log(item);
            this.selectedItem = item;
            if (item.details != '') {
                this.showDetailsModal = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h4.heading {
    margin: 1rem 0;
}
</style>

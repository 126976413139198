<template>
    <div class="wrapper">
        <div class="hazard-title">What is the LIKELIHOOD of this happening?</div>

        <div class="buttons has-addons rating">
            <button
                v-for="(val, key) in likelihood"
                :key="`n-1-${key}`"
                @click="changeLikelihood(val, key)"
                :class="[
                    'button caps',
                    val,
                    { active: selectedLikelihood.label == val.label, 'not-active': selectedLikelihood.label !== val.label }
                ]">
                {{ val.label }}
            </button>
            <p class="helper">{{ selectedLikelihood.description }}</p>
        </div>

        <p>&nbsp;</p>
        <div class="hazard-title">What are the CONSEQUENCES?</div>

        <div class="buttons has-addons rating">
            <button
                v-for="(val, key) in consequences"
                :key="`n-2-${key}`"
                @click="changeConsequence(val, key)"
                :class="[
                    'button caps',
                    val,
                    { active: selectedConsequence.label == val.label, 'not-active': selectedConsequence.label !== val.label }
                ]">
                {{ val.label }}
            </button>
            <p class="helper">{{ selectedConsequence.description }}</p>
        </div>

        <p>&nbsp;</p>
        <div class="buttons centered">
            <span class="button rating" :class="newRatingClass">
                Risk Rating: <span class="caps"> {{ newRatingClass ? newRatingClass : 'NONE' }}</span>
            </span>
        </div>

        <div v-if="viewMatrix" class="matrix-outer">
            <div class="row">
                <div class="cell empty"></div>
                <div
                    class="cell top"
                    v-for="(lh, itx) in likelihood"
                    :class="{ highlight: selectedLikelihood.newValue == likelihood[itx].newValue }"
                    :key="`n-3-${itx}`">
                    {{ lh.label }}
                </div>
            </div>
            <div v-for="(row, idx) in matrix" class="row" :key="`n-4-${idx}`">
                <div class="cell left" :class="{ highlight: selectedConsequence.newValue == consequences[idx].newValue }">
                    {{ consequences[idx].label }}
                </div>
                <div
                    v-for="(cell, icx) in row"
                    :key="`n-5-${icx}`"
                    class="cell"
                    :class="[matrixValue[idx][icx], { current: selectedLikelihood.newValue == icx && selectedConsequence.newValue == idx }]"
                    @click="setRatings(idx, icx)">
                    {{ matrixValue[idx][icx] }}
                    <span v-if="showScores && selectedScore == 'osms'" class="inline-score">({{ matrix[idx][icx] }})</span>
                    <span v-if="showScores && selectedScore == 'qps'" class="inline-score">({{ qpsMatrix[idx][icx] }})</span>
                </div>
            </div>
        </div>

        <div class="scores" v-if="showScores">
            <span>Matrix Score: {{ newRatingNumber }}</span> &nbsp; - &nbsp;
            <span>QPS Equivalent: {{ qpsRating }}</span>
            <!-- <span v-if="newRatingNumber != qpsRating" class="red"> (Adjusted)</span> -->
        </div>

        <div class="spacer s10"></div>
        <div class="buttons centered" v-if="showScores">
            <span class="button" :class="[selectedScore == 'osms' ? 'is-dark' : 'is-light']" @click="selectedScore = 'osms'"
                >Show OffshoreSMS Score</span
            >
            <span class="button" :class="[selectedScore == 'qps' ? 'is-dark' : 'is-light']" @click="selectedScore = 'qps'">Show QPS Score</span>
        </div>

        <div v-if="!showScores" class="buttons mt-3 centered">
            <span class="button is-light" @click="showScores = true">Show Matrix Scores</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['default', 'savedLikelihood', 'savedConsequence', 'savedRating'],
    computed: {
        newRating() {
            let likelihood = this.selectedLikelihood.value == undefined ? 0 : this.selectedLikelihood.value;
            let consequence = this.selectedConsequence.value == undefined ? 0 : this.selectedConsequence.value;
            return (parseFloat(likelihood) * parseFloat(consequence)).toFixed(2);
        },
        newRatingClass() {
            let likelihood = this.selectedLikelihood.newValue == undefined ? 0 : this.selectedLikelihood.newValue;
            let consequence = this.selectedConsequence.newValue == undefined ? 0 : this.selectedConsequence.newValue;
            return this.matrixValue[consequence][likelihood];
        },
        newRatingNumber() {
            let likelihood = this.selectedLikelihood.newValue == undefined ? 0 : this.selectedLikelihood.newValue;
            let consequence = this.selectedConsequence.newValue == undefined ? 0 : this.selectedConsequence.newValue;
            return this.matrix[consequence][likelihood];
        },
        qpsRating() {
            let likelihood = this.selectedLikelihood.newValue == undefined ? 0 : this.selectedLikelihood.newValue;
            let consequence = this.selectedConsequence.newValue == undefined ? 0 : this.selectedConsequence.newValue;
            return this.qpsMatrix[consequence][likelihood];
        }
    },
    mounted() {
        this.selected = this.default;
        if (this.savedLikelihood) {
            this.selectedLikelihood = this.savedLikelihood;
            this.selectedLikelihood.newValue = this.savedLikelihood.newValue
                ? this.savedLikelihood.newValue
                : this.migrate[this.savedLikelihood.value];
        }
        if (this.savedConsequence) {
            this.selectedConsequence = this.savedConsequence;
            this.selectedConsequence.newValue = this.savedConsequence.newValue
                ? this.savedConsequence.newValue
                : this.migrate[this.savedConsequence.value];
        }
    },
    data() {
        return {
            likelihood: [
                { value: 0.2, newValue: 0, label: 'rare', description: 'May happen only in exceptional circumstances.' },
                { value: 0.4, newValue: 1, label: 'unlikely', description: 'Could happen at some point.' },
                { value: 0.6, newValue: 2, label: 'possible', description: 'Might occur occasionally.' },
                { value: 0.8, newValue: 3, label: 'likely', description: 'Will probably occur in most circumstances.' },
                { value: 1.0, newValue: 4, label: 'certain', description: 'Expected to occur in most circumstances.' }
            ],
            consequences: [
                { value: 0.2, newValue: 0, label: 'insignificant', description: 'Very minor injuries or damage done.' },
                { value: 0.4, newValue: 1, label: 'minor', description: 'Could result in injuries requiring first aid or serious vessel damage.' },
                { value: 0.6, newValue: 2, label: 'moderate', description: 'Moderate personal injury or damage to the vessel.' },
                { value: 0.8, newValue: 3, label: 'serious', description: 'May lead to serious injury or possible abandonment of vessel.' },
                { value: 1.0, newValue: 4, label: 'catastrophic', description: 'Chance of critical injury or loss of vessel.' }
            ],
            selected: '',
            selectedLikelihood: [{ value: 0, label: '', description: '' }],
            selectedConsequence: [{ value: 0, label: '', description: '' }],
            matrix: [
                [1, 4, 6, 7, 16],
                [2, 5, 12, 15, 17],
                [3, 10, 13, 20, 22],
                [8, 11, 14, 21, 24],
                [9, 18, 19, 23, 25]
            ],
            qpsMatrix: [
                [1, 3, 4, 5, 12],
                [2, 4, 8, 11, 13],
                [3, 8, 9, 14, 17],
                [6, 9, 10, 15, 19],
                [7, 14, 16, 18, 20]
            ],
            matrixValue: [
                ['low', 'low', 'low', 'low', 'medium'],
                ['low', 'low', 'medium', 'medium', 'medium'],
                ['low', 'medium', 'medium', 'high', 'high'],
                ['medium', 'medium', 'high', 'high', 'extreme'],
                ['medium', 'high', 'high', 'extreme', 'extreme']
            ],
            viewMatrix: true,
            migrate: {
                0.2: 0,
                0.4: 1,
                0.6: 2,
                0.8: 3,
                1.0: 4
            },
            showScores: false,
            showOsmsScore: false,
            showQpsScore: false,
            selectedScore: 'osms'
        };
    },

    methods: {
        changeSelectVal(val) {
            this.selected = val;
            this.$emit('sendRating', this.selected);
        },

        changeLikelihood(selection, key) {
            this.selectedLikelihood = this.likelihood[key];
            this.emitRatings();
        },

        changeConsequence(selection, key) {
            this.selectedConsequence = this.consequences[key];
            this.emitRatings();
        },

        emitRatings() {
            let self = this;
            let result = [self.selectedLikelihood, self.selectedConsequence, self.newRatingClass];
            if (this.newRating != 0) {
                self.$emit('sendRatingData', result);
            }
        },
        setRatings(c, l) {
            this.selectedLikelihood = this.likelihood[l];
            this.selectedConsequence = this.consequences[c];
            this.emitRatings();
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin: 3rem auto 2rem;
}
.buttons.rating {
    display: block;

    .button {
        border: 1px solid #ccc;
        color: #333;
        font-weight: normal;
        margin-top: 0;
        font-size: 0.9rem;
        margin-right: 7px;
        border-radius: 4px;
        display: inline-block;
        float: none;

        &:last-of-type {
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
        }

        &.active {
            outline: none;
            box-shadow: none;
            background: dodgerblue;
            border-color: dodgerblue;
            color: #fff;
        }

        &.not-active {
            background: #fff;
        }

        &.low.active {
            background: #23d160;
            outline: 0;
            box-shadow: none;
            color: #fff;
        }

        &.medium.active {
            background: #fcff57;
            outline: 0;
            box-shadow: none;
        }

        &.high.active {
            background: #ffaa00;
            outline: 0;
            box-shadow: none;
            color: #fff;
        }

        &.extreme.active {
            background: #ff3860;
            outline: 0;
            box-shadow: none;
            color: #fff;
        }
    }
}

.button {
    &.rating {
        &.low {
            border-color: #23d160;
            background: #23d160;
            color: #fff;
        }

        &.medium {
            border-color: #fcff57;
            background: #fcff57;
            color: #404040;
        }

        &.high {
            border-color: #ffaa00;
            background: #ffaa00;
            color: #fff;
        }

        &.extreme {
            border-color: #ff3860;
            background: #ff3860;
            color: #fff;
        }

        .caps {
            text-transform: uppercase;
            margin-left: 0.5rem;
        }
    }
}

.hazard-title {
    margin-bottom: 10px;
    font-weight: bold;
}

.matrix-outer {
    display: flex;
    width: 100%;
    max-width: 900px;
    margin: 2rem auto 2rem;
    flex-direction: column;
    .row {
        display: flex;
        width: 100%;
        .cell {
            display: flex;
            border-right: 1px solid #999;
            border-bottom: 1px solid #999;
            padding: 0.5rem;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            font-weight: bold;
            text-transform: uppercase;
            color: #333;
            padding: 12px 6px;
            width: calc(100% / 6);
            cursor: pointer;
            height: 50px;
            transition: 0.25s;
            margin: 0;
            &.low {
                // border-color: #23d160;
                background: #23d160;
                color: #fff;
            }

            &.medium {
                // border-color: #fcff57;
                background: #fcff57;
                color: #404040;
            }

            &.high {
                // border-color: #ffaa00;
                background: #ffaa00;
                color: #fff;
            }

            &.extreme {
                // border-color: #ff3860;
                background: #ff3860;
                color: #fff;
            }
            &.current {
                // background-color: dodgerblue;
                // color: #fff;
                font-size: 1.1rem;
                border: 3px solid dodgerblue;
            }
            &.left {
                border-left: 1px solid #999;
            }
            &.top {
                border-top: 1px solid #999;
            }
            &.highlight {
                background-color: dodgerblue;
                color: #fff;
            }
            .inline-score {
                color: purple;
                margin-left: 6px;
            }
        }
    }
}

.helper {
    font-style: italic;
    width: 100%;
}

.notification {
    margin-bottom: 1rem;
    padding: 1.5rem 1rem;

    .hazard-title {
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 5px;
        // border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
    }
}
</style>

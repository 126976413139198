<template>
    <div class="site-wrapper">
        <div class="container">
            <h4>{{ vesselData.vesselName }}</h4>

            <h1>Passenger Manifest</h1>

            <h1 v-if="loadingData">
                <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
            </h1>

            <!-- X: {{ currentManifestID }} / {{ manifestID }} -->
            <!-- {{currentPassengers}} -->
            <!-- {{currentManifestID}} <br> {{currentPassengerID}} -->
            <!-- S: {{sentManifest}}
<br>
{{allManifestData}} -->

            <!-- <ul>{{}}
  <li>Vessel ID: {{vesselID}}</li>
  <li>Screen ID: {{screenID}}</li>
  <li>Document ID: {{manifestID}}</li>
</ul> -->

            <!-- CV: {{currentVessel}}<br>
AD: {{vesselData}} -->

            <div v-if="!loadingData">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="small-heading">Trip Name:</div>
                                <div class="listing">{{ allManifestData.name }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="small-heading">Trip Date:</div>
                                <div class="listing">
                                    {{ formatDate(allManifestData.tripStartDate) }}
                                </div>
                            </td>
                        </tr>
                        <!-- <tr>
            <td>
              <div class="small-heading">Check-in Code:</div>
              <div class="listing">{{checkInCode}}</div>
              <div class="current-code">
                <div v-show="!enterCurrentCode" class="button is-info is-small" @click="showEnterCode()">
                  Already have a check-in code?
                </div>
                <div v-show="enterCurrentCode">
                  <div class="input-box">
                    <span class="prefix">G-</span>
                    <input ref="focusCode" type="tel" placeholder="Enter your code" maxlength="4" @keypress="isNumber($event)" v-model="currentCode" />
                  </div>
                  <div class="code-buttons">
                    <span v-show="canCheckCode" class="button is-success" @click="reloadGroupPassengers()">Check Code</span>
                    <span class="cancel" @click="enterCurrentCode = false">Cancel</span>
                  </div>
                </div>
              </div>
            </td>
          </tr> -->
                        <tr>
                            <td>
                                <div class="small-heading">Find your info:</div>
                                <div class="listing">{{ checkInCode }}</div>
                                <div class="current-code">
                                    <div v-show="!enterCurrentCode" class="button is-info" @click="showEnterCode()">Enter your mobile number</div>
                                    <div v-show="enterCurrentCode">
                                        <div class="input-box">
                                            <!-- <span class="prefix">G-</span> -->
                                            <input
                                                ref="focusCode"
                                                type="tel"
                                                placeholder="Enter your mobile number.."
                                                maxlength="14"
                                                @keypress="isNumber($event)"
                                                v-model="currentCode" />
                                        </div>
                                        <div class="code-buttons">
                                            <span v-show="canCheckCode" class="button is-success" @click="reloadGroupPassengers()"
                                                >Search number</span
                                            >
                                            <span class="cancel" @click="enterCurrentCode = false">Cancel</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr v-if="loadingPassengers">
                            <td>
                                <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp; Checking...</span>
                            </td>
                        </tr>

                        <tr v-for="(passenger, index) in allPassengers" :key="passenger.id">
                            <td v-if="!loadingPassengers">
                                <div class="pass-name"><font-awesome-icon icon="user" />&nbsp; {{ passenger.name }}</div>
                                <div class="spacer s10"></div>
                                <div class="buttons pass">
                                    <span class="button is-primary" @click="loadPassenger(passenger, index)">View Profile</span>
                                    <span class="button is-warning" @click="newSelfCheckin(passenger, index)">Self Check-in</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div v-if="loadingPassengers">
  <div class="spacer s30"></div>
  <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp; Checking...</span>
</div>

<div class="passenger-list" v-else>

  <div class="current-passenger" v-for="(passenger, index) in allPassengers" :key="passenger.id" @click="loadPassenger(passenger, index)">
    <div class="content">
      <div class="pass-name">&nbsp; <font-awesome-icon icon="user" />&nbsp;{{passenger.name}}</div>
      <div class="pass-primary">{{passenger.isPrimaryContact ? 'Primary Contact' : ''}}</div>
      <div class="pass-primary"><font-awesome-icon icon="phone" />&nbsp;{{passenger.phone}}</div>
    </div>
    <div class="info" :class="{'primary': passenger.isPrimaryContact}">
      <span><font-awesome-icon icon="info-circle" /></span>
    </div>
  </div>

</div> -->

            <!-- <div v-if="!loadingPassengers" class="spacer s20"></div> -->

            <!-- <div v-if="!loadingPassengers" class="buttons centered">
        <div class="button is-success is-medium" @click="loadPassenger('new')">
          <font-awesome-icon icon="user" /> &nbsp; Add a New Passenger
        </div>
      </div> -->

            <div v-if="!loadingPassengers" class="centered">
                <div class="button complex is-medium is-skip" @click="loadPassenger('new')">
                    <div class="button-icon green">
                        <span><font-awesome-icon icon="user" /></span>
                    </div>
                    <div class="button-text">
                        <div class="button-title green">Add a New Passenger</div>
                        <div class="button-subtitle">All Safety Information for this vessel</div>
                    </div>
                </div>

                <div v-if="showSafetyButton" class="button complex is-medium is-skip" @click="toSafetyInfo()">
                    <div class="button-icon orange">
                        <span><font-awesome-icon icon="exclamation-triangle" /></span>
                    </div>
                    <div class="button-text">
                        <div class="button-title orange">View Safety Information</div>
                        <div class="button-subtitle">All Safety Information for this vessel</div>
                    </div>
                </div>
            </div>

            <div v-if="loadingCustomLogs">
                <div class="spacer s10"></div>
                <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /> Loading custom logs...</span>
                <div class="spacer s10"></div>
            </div>
            <div v-else>
                <div v-if="customLogs.length > 0">
                    <div v-for="(log, idx) in customLogs" :key="`custom-${idx}`" class="button complex is-medium is-skip" @click="toCustomLog(log)">
                        <div class="button-icon">
                            <span><font-awesome-icon icon="pen" /></span>
                        </div>
                        <div class="button-text">
                            <div class="button-title">{{ log.checklistName }}</div>
                            <div class="button-subtitle">
                                {{ truncate(log.logDescription, 100) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="spacer s40"></div>

            <div v-if="showBack" class="buttons centered">
                <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            </div>

            <div class="spacer s90"></div>

            <!-- <div class="checkin-button" @click="loadPassenger('new')">
  <font-awesome-icon icon="user" />&nbsp; Check-in a Passenger
</div> -->

            <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showModal }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-primary">
                        <p class="modal-card-title">New Passenger Info</p>
                        <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                    </header>

                    <section class="modal-card-body">
                        <!-- <div class="form-field">
          <label>Passenger name:</label>
          <input type="text" class="input" v-model="selectedPassenger.name">
        </div> -->

                        <div class="spacer s30"></div>

                        <div class="group">
                            <input
                                ref="focusMe"
                                autofocus
                                :class="{
                                    filled: selectedPassenger.name != '' && selectedPassenger.name != undefined
                                }"
                                type="text"
                                @input="passengerNameChanged = true"
                                required
                                v-model="selectedPassenger.name" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label><font-awesome-icon icon="user" /> &nbsp; Passenger name:</label>
                        </div>

                        <div class="group">
                            <input
                                :class="{
                                    filled: selectedPassenger.phone != '' && selectedPassenger.phone != undefined
                                }"
                                type="tel"
                                v-model="selectedPassenger.phone" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label><font-awesome-icon icon="phone" /> &nbsp; Phone number:</label>
                        </div>

                        <div class="group">
                            <input
                                :class="{
                                    filled: selectedPassenger.emailAddress != '' && selectedPassenger.emailAddress != undefined
                                }"
                                type="email"
                                v-model="selectedPassenger.emailAddress" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label><font-awesome-icon icon="envelope" /> &nbsp; Email address:</label>
                        </div>

                        <div class="textarea-wrapper">
                            <label
                                :class="{
                                    filled: selectedPassenger.homeAddress != '' && selectedPassenger.homeAddress != undefined
                                }"
                                ><font-awesome-icon icon="home" /> &nbsp; Home address:</label
                            >
                            <textarea autocomplete="off" v-model="selectedPassenger.homeAddress"></textarea>
                        </div>

                        <div class="checkbox-wrapper">
                            <input class="is-checkradio normal" type="checkbox" id="localDifferent" v-model="selectedPassenger.localDifferent" />
                            <label for="localDifferent">Different local address?</label>
                        </div>

                        <div class="spacer s10"></div>

                        <div v-if="selectedPassenger.localDifferent" class="spacer s10"></div>

                        <div v-if="selectedPassenger.localDifferent" class="textarea-wrapper">
                            <label
                                :class="{
                                    filled: selectedPassenger.localAddress != '' && selectedPassenger.localAddress != undefined
                                }"
                                ><font-awesome-icon icon="home" /> &nbsp; Local address:</label
                            >
                            <textarea autocomplete="off" v-model="selectedPassenger.localAddress"></textarea>
                        </div>

                        <div class="group">
                            <input
                                :class="{
                                    filled: selectedPassenger.kinName != '' && selectedPassenger.kinName != undefined
                                }"
                                type="text"
                                required
                                v-model="selectedPassenger.kinName" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label><font-awesome-icon icon="user" /> &nbsp; Next of kin name:</label>
                        </div>

                        <div class="group">
                            <input
                                :class="{
                                    filled: selectedPassenger.kinPhone != '' && selectedPassenger.kinPhone != undefined
                                }"
                                type="text"
                                required
                                v-model="selectedPassenger.kinPhone" />
                            <span class="highlight"></span>
                            <span class="bar"></span>
                            <label><font-awesome-icon icon="phone" /> &nbsp; Next of kin phone number:</label>
                        </div>

                        <div class="spacer s30"></div>

                        <!-- <div v-if="selectedPassenger.name != '' && selectedPassenger.name != undefined">
          <span v-show="!confirmRemove" class="cancel red" @click="confirmRemove = true">Remove this passenger</span>
          <div v-show="confirmRemove" class="button is-danger" @click="removePassenger()">Remove permanently</div>
          <div v-show="confirmRemove" class="spacer s30"></div>
          <div v-show="confirmRemove" class="cancel red" @click="confirmRemove = false">Cancel</div>
        </div> -->

                        <div class="spacer s50"></div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button" @click="close()">Cancel</button>
                        <button
                            :disabled="selectedPassenger.name == '' || selectedPassenger.name == undefined"
                            class="button is-primary"
                            @click="saveInfo()">
                            Save Info
                        </button>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, doc, orderBy, setDoc, getDoc, deleteDoc, query, where, onSnapshot } from 'firebase/firestore';
import NProgress from 'nprogress';

export default {
    props: {
        sentManifest: Object
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
        canCheckCode() {
            // return this.currentCode.toString().length == 4;
            return true;
        },
        currentManifestID() {
            return this.$store.getters.getCurrentManifestID;
        },
        currentPassengerID() {
            return this.$store.getters.getCurrentPassengerID;
        },
        currentPassengers() {
            return this.$store.getters.getCurrentPassengers;
        }
        // showSafetyButton() {
        //   return this.vesselData ? this.vesselData.savedAppInfoSections.includes('Safety Information') : false;
        // }
    },

    mounted: function () {
        this.vesselID = this.$route.query.vesselid ? this.$route.query.vesselid : this.currentVessel.vesselID ? this.currentVessel.vesselID : '';
        this.screenID = this.$route.query.screenid ? this.$route.query.screenid : 'manifest';
        // this.manifestID = this.$route.query.docid ? this.$route.query.docid : this.sentManifest.id ? this.sentManifest.id : '';
        this.manifestID = this.$route.query.docid ? this.$route.query.docid : this.currentManifestID ? this.currentManifestID : '';
        this.showBack = !this.$route.query.vesselid && !this.$route.query.screenid;
        this.loadManifestData();
        this.loadVesselData();
        // this.getRandomCode();
        this.loadGroupPassengers();
        setTimeout(() => {
            this.loadAvailableCustomLogs();
        }, 500);
        // console.log('this.currentVessel',this.currentVessel);
    },

    data: function () {
        return {
            vesselID: '',
            screenID: '',
            manifestID: '',
            loadingData: false,
            allManifestData: {},
            vesselData: {},
            checkInCode: '',
            showModal: false,
            selectedPassenger: '',
            newPassenger: {
                name: '',
                phone: '',
                emailAddress: '',
                homeAddress: '',
                localDifferent: false,
                localAddress: '',
                kinName: '',
                kinPhone: '',
                notes: '',
                id: ''
            },
            passengerNameChanged: false,
            allPassengers: [],
            loadingPassengers: false,
            enterCurrentCode: false,
            currentCode: '',
            confirmRemove: false,
            showSafetyButton: false,
            showBack: false,

            loadingCustomLogs: false,
            linkedCustomLogs: [],
            customLogs: []
        };
    },

    methods: {
        async loadAvailableCustomLogs() {
            // console.log(this.currentVessel.savedAppInfoSections,this.currentManifestID)
            let self = this;

            if (
                this.currentVessel.savedAppInfoSections &&
                this.currentVessel.savedAppInfoSections.includes('Custom Logs') &&
                this.currentManifestID &&
                this.currentManifestID != ''
            ) {
                this.loadingCustomLogs = true;
                const docRef = doc(db, 'vessels/' + this.currentVessel.vesselID + '/passengerManifests', this.currentManifestID);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    self.linkedCustomLogs = docSnap.data().linkedCustomLogs ? docSnap.data().linkedCustomLogs : [];
                } else {
                    self.loadingCustomLogs = false;
                }
                setTimeout(() => {
                    if (self.linkedCustomLogs.length > 0) {
                        self.loadCustomLogs();
                    } else {
                        this.loadingCustomLogs = true;
                    }
                }, 500);
            }
        },

        async loadCustomLogs() {
            let self = this;
            this.loadingCustomLogs = true;
            const q = query(collection(db, 'vessels', this.currentVessel.vesselID, 'customChecklists'), orderBy('index', 'asc'));
            const data = onSnapshot(
                q,
                (querySnapshot) => {
                    self.customLogs = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        let allowWebLink = data.allowWebLink ? data.allowWebLink : false;
                        let showInQR = data.webLinkInQr ? data.webLinkInQr : false;
                        if (allowWebLink && showInQR && self.linkedCustomLogs.includes(doc.id)) self.customLogs.push(data);
                    });
                },
                (error) => {
                    console.log('error', error);
                    self.loadingCustomLogs = false;
                }
            );
            setTimeout(() => {
                console.log('loaded');
                self.loadingCustomLogs = false;
            }, 1500);
        },

        toCustomLog(log) {
            console.log(log);
            let currentCustomLog = log.id;
            let webLink = log.webLink ? log.webLink : '';
            this.$store.dispatch('SETCURRENTCUSTOMLOG', { currentCustomLog });
            let self = this;
            this.$router.push({
                name: 'VesselLogs',
                params: {
                    hasLog: webLink != '',
                    webLink: webLink,
                    sentCustomLog: log
                }
            });
        },

        close() {
            this.showModal = false;
            this.selectedPassenger = '';
            this.newPassenger = {
                name: '',
                phone: '',
                emailAddress: '',
                homeAddress: '',
                localDifferent: false,
                localAddress: '',
                kinName: '',
                kinPhone: '',
                notes: '',
                id: ''
            };
            this.passengerNameChanged = false;
            this.confirmRemove = false;
        },

        truncate(string, limit) {
            // console.log(string,limit);
            if (string == undefined || string == '') return '';
            return string.length > limit ? string.slice(0, limit) + '...' : string;
        },

        goBack() {
            this.$router.go(-1);
        },

        isNumber: function ($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        getRandomCode() {
            // let tempCode = Math.floor(Math.random() * 1000) + 1;
            let tempCode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
            if (tempCode.toString().length < 4) tempCode = tempCode + '1';
            this.checkInCode = 'G-' + tempCode;
        },

        showEnterCode() {
            this.enterCurrentCode = true;
            this.$nextTick(() => this.$refs.focusCode.focus());
        },

        loadManifestData() {
            // console.log('loading data');
            let self = this;
            this.loadingData = true;
            const data = onSnapshot(
                doc(db, 'vessels', this.vesselID, 'passengerManifests', this.manifestID),
                (doc) => {
                    // self.allManifestData = doc.data();
                    self.allManifestData.id = doc.id;
                    self.allManifestData.name = doc.data().name ? doc.data().name : 'No name';
                    self.allManifestData.timestamp = doc.data().timestamp ? doc.data().timestamp.toDate() : '';
                    self.allManifestData.tripStartDate = doc.data().tripStartDate ? doc.data().tripStartDate.toDate() : '';
                    self.allManifestData.updatedDate = doc.data().updatedDate ? doc.data().updatedDate.toDate() : '';
                    self.allManifestData.updatedBy = doc.data().updatedBy ? doc.data().updatedBy : '';
                    self.allManifestData.notes = doc.data().notes ? doc.data().notes : '';
                    self.allManifestData.status = doc.data().status ? doc.data().status : 'hidden';
                },
                (error) => {
                    self.loadingData = false;
                }
            );
            setTimeout(() => {
                console.log('manifest data loaded');
                self.loadingData = false;
            }, 1500);
        },

        loadVesselData() {
            let self = this;
            const data = onSnapshot(
                doc(db, 'vessels', this.vesselID),
                (doc) => {
                    let allData = doc.data();
                    allData['vesselID'] = self.vesselID;
                    self.vesselData = allData;
                    if (self.manifestID != '') {
                        let currentManifestID = self.manifestID;
                        self.$store.dispatch('SETCURRENTMANIFEST', { currentManifestID });
                    }
                    self.showSafetyButton = allData.savedAppInfoSections ? allData.savedAppInfoSections.includes('Safety Information') : false;
                    self.$store.dispatch('UPDATESELECTEDVESSEL', { allData });
                },
                (error) => {
                    self.loadingData = false;
                }
            );
            setTimeout(() => {
                console.log('vessel data loaded');
                self.loadingData = false;
            }, 1500);
        },

        formatDate: function (sentDate, format) {
            // return moment(sentDate).format(format);
            return new Date(sentDate).toDateString();
        },

        toSafetyInfo() {
            // console.log(this.vesselID);
            let self = this;
            this.$router.push({
                name: 'SafetyInfo',
                params: {
                    sentVessel: {
                        vesselID: self.vesselID,
                        manifestID: self.manifestID
                    }
                }
            });
        },

        async loadGroupPassengers() {
            // console.log('Code:',this.checkInCode);
            let self = this;
            if (this.vesselID && this.manifestID) {
                this.loadingPassengers = true;
                // const q = query(collection(db, "vessels", this.vesselID, "passengerManifests", this.manifestID, "passengers"), where("checkInCode", "==", this.checkInCode), orderBy("name", "asc"));
                const q = query(
                    collection(db, 'vessels', this.vesselID, 'passengerManifests', this.manifestID, 'passengers'),
                    where('phone', '==', this.checkInCode),
                    orderBy('name', 'asc')
                );
                // const q = query(collection(db, "vessels", this.vesselID, "passengerManifests", this.manifestID, "passengers"), orderBy("name", "asc"));
                const data = onSnapshot(
                    q,
                    (querySnapshot) => {
                        self.allPassengers = [];
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.data());
                            if (doc.data().phone != '')
                                self.allPassengers.push({
                                    id: doc.id,
                                    name: doc.data().name ? doc.data().name : 'No name',
                                    phone: doc.data().phone ? doc.data().phone : '',
                                    emailAddress: doc.data().emailAddress ? doc.data().emailAddress : '',
                                    homeAddress: doc.data().homeAddress ? doc.data().homeAddress : '',
                                    localAddress: doc.data().localAddress ? doc.data().localAddress : '',
                                    kinName: doc.data().kinName ? doc.data().kinName : '',
                                    kinPhone: doc.data().kinPhone ? doc.data().kinPhone : '',
                                    notes: doc.data().notes ? doc.data().notes : '',
                                    checkInCode: doc.data().checkInCode ? doc.data().checkInCode : '',
                                    isPrimaryContact: doc.data().isPrimaryContact ? doc.data().isPrimaryContact : false
                                });
                        });
                    },
                    (error) => {
                        self.loadingPassengers = false;
                    }
                );
                setTimeout(() => {
                    console.log('passenger groups loaded');
                    self.loadingPassengers = false;
                }, 1500);
            }
        },

        async reloadGroupPassengers() {
            // console.log('Code:',this.currentCode);
            // console.log('this.manifestID',this.manifestID)
            // console.log('this.vesselID',this.vesselID)
            // let fetchCode = 'G-' + this.currentCode;
            let fetchCode = this.currentCode.toString();
            this.checkInCode = fetchCode;
            let self = this;
            this.allPassengers = [];
            if (this.vesselID && this.manifestID) {
                this.loadingPassengers = true;
                // console.log('goo',fetchCode)
                // const q = query(collection(db, "vessels", this.vesselID, "passengerManifests", this.manifestID, "passengers"), where("checkInCode", "==", fetchCode), orderBy("name", "asc"));
                const q = query(
                    collection(db, 'vessels', this.vesselID, 'passengerManifests', this.manifestID, 'passengers'),
                    where('phone', '==', fetchCode),
                    orderBy('name', 'asc')
                );
                const data = onSnapshot(
                    q,
                    (querySnapshot) => {
                        self.allPassengers = [];
                        // console.log('wada')
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.data());
                            if (doc.data().phone != '')
                                self.allPassengers.push({
                                    id: doc.id,
                                    name: doc.data().name ? doc.data().name : 'No name',
                                    phone: doc.data().phone ? doc.data().phone : '',
                                    emailAddress: doc.data().emailAddress ? doc.data().emailAddress : '',
                                    homeAddress: doc.data().homeAddress ? doc.data().homeAddress : '',
                                    localAddress: doc.data().localAddress ? doc.data().localAddress : '',
                                    kinName: doc.data().kinName ? doc.data().kinName : '',
                                    kinPhone: doc.data().kinPhone ? doc.data().kinPhone : '',
                                    notes: doc.data().notes ? doc.data().notes : '',
                                    checkInCode: doc.data().checkInCode ? doc.data().checkInCode : '',
                                    isPrimaryContact: doc.data().isPrimaryContact ? doc.data().isPrimaryContact : false
                                });
                        });
                    },
                    (error) => {
                        self.loadingPassengers = false;
                    }
                );
                setTimeout(() => {
                    // console.log('group data reloaded');
                    self.loadingPassengers = false;
                    self.currentCode = '';
                    self.enterCurrentCode = false;
                }, 500);
            }
        },

        loadPassenger(passenger) {
            this.selectedPassenger = passenger == 'new' ? this.newPassenger : passenger;
            // console.log(this.selectedPassenger);
            this.showModal = true;
            this.$nextTick(() => this.$refs.focusMe.focus());
        },

        async saveInfo() {
            // console.log('saving..');
            NProgress.start();
            let self = this;
            let updateData = {
                name: self.selectedPassenger.name,
                phone: self.selectedPassenger.phone,
                emailAddress: self.selectedPassenger.emailAddress,
                homeAddress: self.selectedPassenger.homeAddress,
                localAddress: self.selectedPassenger.localAddress,
                kinName: self.selectedPassenger.kinName,
                kinPhone: self.selectedPassenger.kinPhone,
                notes: self.selectedPassenger.notes,
                checkInCode: self.checkInCode,
                isPrimaryContact: self.allPassengers.length == 0
            };
            let randomID = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
            let passengerID = this.selectedPassenger.id == undefined || this.selectedPassenger.id == '' ? randomID + '' : this.selectedPassenger.id;

            // console.log(updateData,passengerID);

            let newPassengers = this.currentPassengers;

            this.$store.dispatch('SETCURRENTPASSENGERS', { newPassengers });

            const docRef = await setDoc(
                doc(db, 'vessels', this.vesselID, 'passengerManifests', this.manifestID, 'passengers', passengerID),
                updateData
            );
            // console.log('docRef',docRef);

            this.close();
            NProgress.done();
        },

        async removePassenger() {
            // console.log(this.selectedPassenger);
            let deleteID = this.selectedPassenger.id;
            NProgress.start();
            this.close();
            await deleteDoc(doc(db, 'vessels', this.vesselID, 'passengerManifests', this.manifestID, 'passengers', deleteID));
            setTimeout(() => {
                NProgress.done();
            }, 1500);
        }
    }
};
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
}

table {
    width: 95%;
    max-width: 500px;
    margin: 1rem auto 2rem;

    tr {
        td {
            border: 1px solid #fff;
            padding: 12px;

            .small-heading {
            }

            .listing {
                color: yellow;
                text-transform: uppercase;
                font-size: 1.1rem;
                padding-left: 6px;
            }
        }
    }
}

.checkin-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #24d272;
    color: #fff;
    padding: 10px 15px;
    border-radius: 30px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        background: #5cc65c;
    }
}

.modal {
    z-index: 99999;

    .modal-card {
        width: 90%;
        max-width: 600px;
        overflow: visible;
        max-height: 80%;

        .modal-card-head {
            background: mediumseagreen;

            .modal-card-title {
                color: #fff;
                font-size: 1.1rem;
            }
        }

        .modal-card-body {
            padding: 15px;
            color: #333;
        }

        .modal-card-foot {
            background: #e5e5e5;
        }
    }
}

.form-field {
    text-align: left;

    label {
        color: #333;
        margin-bottom: 5px;
        display: block;
    }

    input[type='text'],
    input[type='email'],
    input[type='tel'] {
        padding: 10px;
        color: #333;
        height: auto;
    }
}

.packer {
    height: 800px;
    color: green;
}

.buttons {
    &.pass {
        margin-bottom: 0px;
    }
}

.button {
    &.complex {
        .button-icon {
            &.green {
                background: mediumseagreen;
            }
            &.orange {
                background: orangered;
            }
        }
        .button-title {
            &.green {
                color: mediumseagreen;
            }
            &.orange {
                color: orangered;
            }
        }
    }
}

/* form starting stylings ------------------------------- */
// .group        {
//   position:relative;
//   margin-bottom: 35px;
// }
// input         {
//   font-size: 1rem;
//   padding:10px 10px 10px 5px;
//   display:block;
//   width:300px;
//   border:none;
//   border-bottom:1px solid #757575;
// }
// input:focus     { outline:none; }

// /* LABEL ======================================= */
// label          {
//   color:#999;
//   font-size: 0.9rem;
//   font-weight:normal;
//   position:absolute;
//   pointer-events:none;
//   left:5px;
//   top:10px;
//   transition:0.2s ease all;
//   -moz-transition:0.2s ease all;
//   -webkit-transition:0.2s ease all;
// }

// /* active state */
// input:focus ~ label {
//   top:-15px;
//   font-size:14px;
//   color:#5264AE;
// }

// input.filled ~ label {
//   top:-15px;
//   font-size:14px;
//   color: green;
// }

// /* BOTTOM BARS ================================= */
// .bar  { position:relative; display:block; width:300px; }
// .bar:before, .bar:after   {
//   content:'';
//   height:2px;
//   width:0;
//   bottom:1px;
//   position:absolute;
//   background:#5264AE;
//   transition:0.2s ease all;
//   -moz-transition:0.2s ease all;
//   -webkit-transition:0.2s ease all;
// }
// .bar:before {
//   left:50%;
// }
// .bar:after {
//   right:50%;
// }

// /* active state */
// input:focus ~ .bar:before, input:focus ~ .bar:after {
//   width:50%;
// }

// /* HIGHLIGHTER ================================== */
// .highlight {
//   position:absolute;
//   height:60%;
//   width:100px;
//   top:25%;
//   left:0;
//   pointer-events:none;
//   opacity:0.5;
// }

.group {
    position: relative;
    margin-bottom: 35px;
}

input:not(.normal) {
    font-size: 1rem;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
}

input:not(.normal):focus {
    outline: none;
}

/* LABEL ======================================= */
label {
    color: #999;
    font-size: 0.9rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

/* active state */
input:not(.normal):focus ~ label {
    top: -15px;
    font-size: 14px;
    color: #5264ae;
}

input.filled ~ label {
    top: -15px;
    font-size: 14px;
    color: green;
}

/* BOTTOM BARS ================================= */
.bar {
    position: relative;
    display: block;
    width: 300px;
}

.bar:before,
.bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264ae;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.bar:before {
    left: 50%;
}

.bar:after {
    right: 50%;
}

/* active state */
input:not(.normal):focus ~ .bar:before,
input:not(.normal):focus ~ .bar:after {
    width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

/* active state */
input:not(.normal):focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from {
        background: #5264ae;
    }

    to {
        width: 0;
        background: transparent;
    }
}

@-moz-keyframes inputHighlighter {
    from {
        background: #5264ae;
    }

    to {
        width: 0;
        background: transparent;
    }
}

@keyframes inputHighlighter {
    from {
        background: #5264ae;
    }

    to {
        width: 0;
        background: transparent;
    }
}

.textarea-wrapper {
    position: relative;
    text-align: left;

    label {
        position: relative;
        left: auto;
        top: auto;
        margin-bottom: 5px;
        display: block;
    }

    textarea {
        width: 100%;
        padding: 12px;
        font-size: 1rem;
        color: green;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        margin-bottom: 10px;
        transition: 0.2s;

        &:focus {
            outline: none;
            border: 2px solid #5264ae;
        }
    }
}

.checkbox-wrapper {
    position: relative;
    text-align: left;
    min-height: 50px;

    input {
        display: block;
        border: 1px solid red;
        width: 100%;
        height: 50px;
    }

    label {
        position: relative;
    }
}

.input-box {
    display: flex;
    align-items: center;
    max-width: 300px;
    background: #fff;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;
    font-family: sans-serif;
}

.input-box .prefix {
    font-weight: 300;
    font-size: 14px;
    color: #999;
}

.input-box input {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
}

.input-box:focus-within {
    border-color: #777;
}

.current-code {
    margin: 12px 0;

    .input-box {
        span {
            font-size: 1.1rem;
            color: dodgerblue;
            font-weight: 400;
            letter-spacing: 2px;
        }

        input {
            font-size: 1.1rem;
            padding: 15px 15px 17px 1px;
            letter-spacing: 1px;
            color: dodgerblue;
        }
    }

    .code-buttons {
        display: flex;
        justify-content: left;
        margin: 16px auto;
        align-items: baseline;

        span {
            &.button {
            }

            &.cancel {
                padding: 0 1rem;
                color: #fe4c4c;
                cursor: pointer;
            }
        }
    }
}

.passenger-list {
    margin: 2rem auto;
    width: 95%;

    .current-passenger {
        border-radius: 3px;
        background: #fff;
        margin-bottom: 12px;
        display: flex;
        padding: 17px;
        justify-content: space-between;
        cursor: pointer;

        .content {
            margin-bottom: 0;

            .pass-name {
                color: royalblue;
                text-transform: uppercase;
                text-align: left;
            }

            .pass-primary {
                color: mediumseagreen;
                font-size: 0.9rem;
            }
        }

        .info {
            width: 30px;
            color: royalblue;

            span {
            }

            &.primary {
            }
        }
    }
}

h4 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
}
</style>

<template>
    <div class="">
        <div class="container">
            <div v-if="vesselData && tripData" class="trip-info">
                <div v-if="tripData.isPublic">
                    <div class="vessel-info">
                        <span class="avatar-wrapper">
                            <span
                                v-if="vesselData.avatarUrl != ''"
                                :style="{ backgroundImage: `url(${vesselData.avatarUrl})` }"
                                class="avatar"></span>
                            <span v-else class="round-button-letters">{{ getAvatar(vesselData.vesselName) }}</span>
                            <div class="asset-details">
                                <span class="asset-name">{{ vesselData.vesselName }}</span>
                                <span class="asset-owner">{{ vesselData.vesselOwnerName }}</span>
                            </div>
                        </span>
                    </div>
                    <table class="table is-fullwidth">
                        <tbody>
                            <tr>
                                <th>Trip Name</th>
                                <td>{{ tripData.tag }}</td>
                            </tr>
                            <tr>
                                <th>Trip Date</th>
                                <td>{{ displayDate(tripData.scheduleDate, true) }}</td>
                            </tr>
                            <tr>
                                <th>Start Location</th>
                                <td>{{ tripData.startLocation ?? 'Not set' }}</td>
                            </tr>
                            <tr>
                                <th>Trip Details</th>
                                <td>
                                    <div>{{ truncate(tripData.tripNotes, 100) }}</div>
                                    <div class="buttons mt-3" v-if="tripData.tripNotes && tripData.tripNotes.length > 100">
                                        <span class="button is-primary is-small" @click="showTripInfo = true">
                                            <font-awesome-icon icon="info-circle" /> &nbsp; Read more
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="tripData && tripData.hasManifest">
                                <th>Passengers</th>
                                <td>{{ tripData.isOpen ? tripData.paxOnBoard + ' on board' : tripData.paxTotal + ' booked' }}</td>
                            </tr>
                            <tr v-if="tripData">
                                <th>Trip Updates</th>
                                <td>
                                    <button
                                        class="button is-small"
                                        :class="[tripData.updates.length > 0 ? 'is-primary' : 'is-light']"
                                        @click="showUpdates = true">
                                        View Updates
                                    </button>
                                </td>
                            </tr>
                            <!-- <tr v-if="phoneVerified">
                                <th>My Info</th>
                                <td>
                                    <span class="button is-info" @click="showMyInfo = true">
                                        <font-awesome-icon icon="user-shield" /> &nbsp;View my information
                                    </span>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>

                    <!-- <p>Total forms: {{ selectedCustomFormIDs.length }}</p>
                    <p>All form IDs: {{ requiredFormIDs }}</p>
                    <p>Completed required form IDs: {{ completedFormIDs }}</p>
                    <p>Total form submissions: {{ submittedForms.length }}</p>
                    <p>Current passenger ID: {{ currentPassengerID }}</p>
                    <p>Form IDs by this passenger: {{ currentPassengerFormSubmissionIDs }}</p> -->

                    <div class="stars">
                        <!-- <span v-for="i in 5" :key="i" class="icon" :class="{ 'has-text-warning': i <= tripData.rating }">
                            <font-awesome-icon icon="star" />
                        </span> -->
                        <span v-for="i in 5" :key="i" class="icon green">
                            <font-awesome-icon icon="star" />
                        </span>
                    </div>

                    <div class="spacer s30"></div>
                    <div v-if="phoneVerified">
                        <table class="table is-fullwidth is-striped is-bordered">
                            <tbody>
                                <tr>
                                    <th>Primary name:</th>
                                    <td>{{ currentPassengerManifestData.name }}</td>
                                    <td class="edit" @click="editField('text', 'name', 'Primary name', currentPassengerManifestData.name, [])">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Phone number:</th>
                                    <td>{{ currentPassengerManifestData.phone }}</td>
                                    <td class="edit">
                                        <font-awesome-icon icon="lock" />
                                    </td>
                                </tr>
                                <tr v-if="phoneVerified">
                                    <th>Completed Forms</th>
                                    <td>
                                        <span
                                            class="button is-small"
                                            :class="[allFormsCompleted ? 'is-success' : 'is-warning']"
                                            @click="showAllForms = true">
                                            <font-awesome-icon :icon="allFormsCompleted ? 'check-square' : 'exclamation-triangle'" /> &nbsp;{{
                                                completedFormIDs.length
                                            }}
                                            of {{ selectedCustomFormIDs.length }}
                                            completed
                                        </span>
                                    </td>
                                    <td class="edit">
                                        <span v-if="allFormsCompleted"><font-awesome-icon icon="check" /></span>
                                        <span v-else class="red"><font-awesome-icon icon="exclamation-triangle" /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email address:</th>
                                    <td>{{ currentPassengerManifestData.emailAddress ?? '' }}</td>
                                    <td
                                        class="edit"
                                        @click="editField('text', 'emailAddress', 'Email address', currentPassengerManifestData.emailAddress, [])">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Dependants:</th>
                                    <td>
                                        <!-- <div v-for="(dep, idx) in currentPassengerManifestData.dependants" :key="`dep-1-${idx}`">{{ dep }}</div> -->
                                        <div v-if="currentPassengerManifestData.dependants">
                                            {{ currentPassengerManifestData.dependants.join(', ') }}
                                        </div>
                                    </td>
                                    <td
                                        class="edit"
                                        @click="editField('list', 'dependants', 'Dependants', '', currentPassengerManifestData.dependants)">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Home address:</th>
                                    <td>{{ currentPassengerManifestData.homeAddress ?? '' }}</td>
                                    <td
                                        class="edit"
                                        @click="editField('textarea', 'homeAddress', 'Home address', currentPassengerManifestData.homeAddress, [])">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Local address:</th>
                                    <td>{{ currentPassengerManifestData.localAddress ?? '' }}</td>
                                    <td
                                        class="edit"
                                        @click="
                                            editField('textarea', 'localAddress', 'Local address', currentPassengerManifestData.localAddress, [])
                                        ">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Next of kin name:</th>
                                    <td>{{ currentPassengerManifestData.kinName ?? '' }}</td>
                                    <td
                                        class="edit"
                                        @click="editField('text', 'kinName', 'Next of kin name', currentPassengerManifestData.kinName, [])">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Next of kin phone:</th>
                                    <td>{{ currentPassengerManifestData.kinPhone ?? '' }}</td>
                                    <td
                                        class="edit"
                                        @click="editField('text', 'kinPhone', 'Next of kin phone', currentPassengerManifestData.kinPhone, [])">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Passenger notes:</th>
                                    <td>{{ currentPassengerManifestData.notes ?? '' }}</td>
                                    <td
                                        class="edit"
                                        @click="editField('textarea', 'notes', 'Passenger notes', currentPassengerManifestData.notes, [])">
                                        <font-awesome-icon icon="pen" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- <span class="green"><font-awesome-icon icon="check" /></span> &nbsp;Root passenger data: {{ currentPassengerData }} -->
                    </div>
                    <div v-else>
                        <div v-if="tripData.hasManifest">
                            <div class="buttons centered">
                                <button :disabled="paxCodeAttempts > 5" class="button is-large is-info" @click="showSearch = true">
                                    <font-awesome-icon icon="search" /> &nbsp;View your booking
                                </button>
                                <!-- <span class="button is-large is-primary" @click="showSearch = true">
                                <font-awesome-icon icon="user-plus" /> &nbsp;Join this trip
                            </span> -->
                            </div>
                        </div>
                    </div>

                    <div class="spacer s30"></div>

                    <div v-if="phoneVerified" class="buttons centered">
                        <span class="button is-light" @click="showRemoveMe = true"
                            ><font-awesome-icon icon="user-slash" /> &nbsp; Remove me from this trip</span
                        >
                    </div>

                    <div class="spacer s100"></div>
                </div>

                <div v-else>
                    <div class="spacer s30"></div>
                    <p>This trip is not publicly accessible, sorry.</p>
                </div>
            </div>

            <div class="spacer s100"></div>

            <!-- <pre>{{ currentPassengerManifestData }}</pre> -->

            <!-- X{{ tripData }}
            <hr />
            {{ currentPassengerManifestData }}
            <hr />
            {{ vesselData }} -->

            <!-- {{ vesselID }}
            {{ screenID }}
            {{ tripID }}
            <hr />
            {{ tripData }}
            <hr />
            <hr />
            {{ vesselData }}
            <hr /> -->
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showSearch }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">{{ currentPassenger.name == '' ? 'Passenger Search' : currentPassenger.name }}</p>
                </header>
                <section class="modal-card-body">
                    <div v-if="lockedOut" class="red">Too many attempts, sorry. Please try again later.</div>
                    <div v-else>
                        <div v-if="codeSent">
                            <div v-if="!checkingCode">
                                <div v-for="(line, ibx) in statusMessage" :key="`msg-${ibx}`" class="red">{{ line }}</div>
                            </div>
                            <div class="spacer s10"></div>
                            <div class="twilio-code">
                                <input
                                    class="input inline"
                                    autocomplete="off"
                                    v-model="otpCode"
                                    placeholder="XXXXXX"
                                    type="tel"
                                    :maxlength="6"
                                    v-on:keypress="isNumber($event)"
                                    :class="{ long: otpCode.length == 6 }"
                                    required />
                            </div>
                            <div class="spacer s20"></div>
                            <div v-for="(line, ibx) in errorMessage" :key="`err-${ibx}`">{{ line }}</div>
                        </div>
                        <div v-else>
                            <div class="field">
                                <label class="label">Mobile phone number</label>
                                <div class="phone-wrapper-x">
                                    <div class="custom-dropdown-wrapper">
                                        <div class="custom-dropdown-button" @click="showOptions = !showOptions">
                                            {{ selectedCountry.flag }} {{ selectedCountry.code }}
                                        </div>
                                        <div class="phone-number">
                                            <input
                                                class="input inline"
                                                autocomplete="off"
                                                v-model="phoneNumber"
                                                placeholder="Enter a mobile number.."
                                                type="tel"
                                                :maxlength="20"
                                                v-on:keypress="isNumber($event), clearErrors(), (showOptions = false)"
                                                required />
                                        </div>
                                        <div class="number-option-wrapper" :class="{ shown: showOptions }">
                                            <div class="custom-dropdown-options" :class="{ shown: showOptions }">
                                                <div
                                                    v-for="(country, idx) in countries"
                                                    :key="`country-${idx}`"
                                                    :value="country"
                                                    class="ind-country"
                                                    @click="
                                                        selectedCountry = country;
                                                        showOptions = false;
                                                    ">
                                                    {{ country.flag }} {{ country.name }} {{ country.code }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!showOptions" class="spacer s20"></div>

                                <div v-if="phoneNumber.length > 0" class="tag is-medium">
                                    <!-- <span class="final-icon"><font-awesome-icon icon="phone" /> &nbsp;</span> -->
                                    <span class="final-number">{{ finalNumber }}</span>
                                    <!-- <span class="red" v-if="hasPhoneError"> &nbsp; <font-awesome-icon icon="exclamation-triangle" /></span> -->
                                    <!-- <span class="green" v-else> &nbsp; <font-awesome-icon icon="check" /></span> -->
                                </div>
                                <div class="spacer s10"></div>
                            </div>
                            <div v-for="(line, ibx) in errorMessage" :key="`err-${ibx}`" class="red">{{ line }}</div>
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button
                        :disabled="phoneNumber.length < 9 || checkingCode"
                        v-if="!codeSent && !lockedOut"
                        class="button is-primary"
                        @click="nextVerifyDetails()">
                        Verify Number
                    </button>
                    <button
                        :disabled="otpCode.length < 6 || checkingCode"
                        v-if="codeSent && !lockedOut"
                        class="button is-info"
                        @click="validateTwilioSmsCode()">
                        Validate Code
                    </button>
                    <button :disabled="checkingCode" class="button is-warning" @click="cancelVerification()">Cancel</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showUpdates }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Trip Updates</p>
                </header>
                <section class="modal-card-body">
                    <div v-if="tripData.updates && tripData.updates.length > 0">
                        <div class="info-details" v-for="(item, ix) in tripData.updates" :key="`update-${ix}`">{{ item }}<br /></div>
                    </div>
                    <div v-else>No current updates.</div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close">Got it</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showTripInfo }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">About this trip</p>
                </header>
                <section class="modal-card-body" v-if="tripData.tripNotes">
                    <div class="info-details" v-for="(line, ix) in tripData.tripNotes.split('\n')" :key="`line-${ix}`">{{ line }}<br /></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" @click="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showMyInfo }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head is-info">
                    <p class="modal-card-title">My Saved Data</p>
                </header>
                <section class="modal-card-body">
                    {{ currentPassengerData }}
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-info" @click="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAllForms }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head" :class="[allFormsCompleted ? 'is-success' : 'is-warning']">
                    <p class="modal-card-title">Required Passenger Forms</p>
                </header>
                <section class="modal-card-body">
                    <!-- {{ selectedCustomForms }} -->
                    <div class="buttons">
                        <div
                            class="button"
                            v-for="(form, idx) in selectedCustomForms"
                            :key="`form-${idx}`"
                            :class="[completedFormIDs.includes(form.id) ? 'is-success' : 'is-warning']"
                            @click="showNewFormLink(form)">
                            <font-awesome-icon :icon="completedFormIDs.includes(form.id) ? 'check-square' : 'exclamation-triangle'" />
                            <span>&nbsp; {{ form.title }}</span>
                        </div>
                    </div>
                    <hr />
                    <h5>My completed forms:</h5>
                    <div v-if="currentPassengerFormSubmissions.length > 0">
                        <div class="past-logs-wrapper">
                            <div v-for="(log, idx) in currentPassengerFormSubmissions" :key="`past-log-${idx}`" class="past-log">
                                <div class="button is-light" @click="showFormSubmission(log)">
                                    <font-awesome-icon icon="pen" />
                                    <span class="log-date">&nbsp; {{ log.checklistName }} ({{ displayDate(log.timestamp, true) }})</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <!-- <pre>{{ currentPassengerFormSubmissions }}</pre> -->
                    </div>
                    <div v-else>Nothing yet</div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" :class="[allFormsCompleted ? 'is-success' : 'is-warning']" @click="close">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showEditField }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head is-skip">
                    <p class="modal-card-title">Edit passenger information</p>
                </header>
                <section class="modal-card-body">
                    <div class="field" v-if="selectedDataField.type == 'text'">
                        <label class="label">{{ selectedDataField.label }}:</label>
                        <input
                            type="text"
                            class="input padded"
                            v-model="selectedDataField.field"
                            maxlength="200"
                            @input="dataChanged = true"
                            placeholder="Enter details.." />
                    </div>
                    <div class="field" v-if="selectedDataField.type == 'textarea'">
                        <label class="label">{{ selectedDataField.label }}:</label>
                        <textarea
                            class="textarea padded"
                            v-model="selectedDataField.field"
                            maxlength="1500"
                            @input="dataChanged = true"
                            placeholder="Enter details.."></textarea>
                    </div>
                    <div class="field" v-if="selectedDataField.type == 'list'">
                        <label class="label">{{ selectedDataField.label }}:</label>
                        <div class="input-wrapper" v-for="(dep, idx) in selectedDataField.list" :key="`deps-${idx}`">
                            <input
                                type="text"
                                class="input padded"
                                v-model="selectedDataField.list[idx]"
                                maxlength="200"
                                placeholder="Enter details.."
                                @input="dataChanged = true" />
                            <span class="button is-delete" @click="removeDependant(idx)"><font-awesome-icon icon="times" /></span>
                        </div>
                        <button class="button is-light" @click="selectedDataField.list.push('')">Add a dependant</button>
                        <div class="spacer s20"></div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-skip" @click="close">Cancel</button>
                    <button
                        class="button is-primary"
                        :disabled="!dataChanged || (selectedDataField.fieldName == 'name' && selectedDataField.field == '')"
                        @click="savePassengerField()">
                        Update Information
                    </button>
                </footer>
            </div>
        </div>

        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showRemoveMe }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card left">
                <header class="modal-card-head is-danger">
                    <p class="modal-card-title">Remove me from this trip</p>
                </header>
                <section class="modal-card-body">
                    <p class="mb-2">
                        This will remove you and any dependants listed from: <span class="bold">{{ tripData.tag }}</span
                        >.
                    </p>
                    <p>Click the button below to leave this trip.</p>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" @click="close">Cancel</button>
                    <button class="button is-danger" @click="removeMe()">Confirm Remove</button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, doc, updateDoc, setDoc, getDoc, deleteDoc, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions();
const checkPassengerExistsByPhone = httpsCallable(functions, 'checkPassengerExistsByPhone');
const sendTwilioVerifySmsCode = httpsCallable(functions, 'sendTwilioVerifySmsCode');
const checkTwilioVerifySmsCode = httpsCallable(functions, 'checkTwilioVerifySmsCode');
const updateTripPassenger = httpsCallable(functions, 'cf_passengers-updateTripPassenger');
const removePassenger = httpsCallable(functions, 'cf_passengers-removeTripPassenger');
const checkPassengerNumberInManifest = httpsCallable(functions, 'cf_passengers-checkPassengerNumberInManifest');

let unsubTrip;
let unsubVessel;

export default {
    props: {
        sentTripID: String,
        sentVesselID: String
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
        currentPassengerData() {
            return this.$store.getters.getCurrentPassengerData;
        },
        currentPassengerManifestData() {
            return this.$store.getters.getCurrentPassengerManifestData;
        },
        currentPassengerID() {
            return this.currentPassengerManifestData.id ?? '';
        },
        paxCodeAttempts() {
            return this.$store.getters.getPaxCodeAttempts;
        },
        phoneError() {
            if (this.phoneNumber.length > 0 && this.phoneNumber.length < 10) {
                return this.phoneNumber.slice(0, 2) != '04' ? 'Australian mobile numbers must start with 04' : 'Enter a full mobile number';
            } else {
                if (this.phoneNumber.length == 10) {
                    return this.phoneNumber.slice(0, 2) != '04' ? 'Australian mobile numbers must start with 04' : '';
                } else {
                    return '';
                }
            }
        },
        hasPhoneError() {
            return this.phoneError.length > 0 || this.finalNumber.length < 8;
        },
        finalNumber() {
            let tempNum = this.phoneNumber.slice(0, 1) == '0' ? this.phoneNumber.slice(1) : this.phoneNumber;
            // return '+' + this.areaCode + tempNum;
            let code = this.selectedCountry.code ?? '+61';
            return code + tempNum;
        },
        phoneVerified() {
            return this.$store.getters.getPhoneVerified;
        },
        lockedOut() {
            return this.paxCodeAttempts > 3;
        },
        selectedCustomForms() {
            return this.tripData && this.tripData.selectedCustomForms ? this.tripData.selectedCustomForms : [];
        },
        selectedCustomFormIDs() {
            return this.tripData && this.tripData.selectedCustomForms ? this.tripData.selectedCustomForms.map((m) => m.id) : [];
        },
        requiredFormIDs() {
            return this.selectedCustomFormIDs;
        },
        completedFormIDs() {
            return this.requiredFormIDs.filter((m) => this.currentPassengerFormSubmissionIDs.includes(m));
        },
        allFormsCompleted() {
            return this.completedFormIDs.length >= this.selectedCustomFormIDs.length;
            // let formIDs = this.selectedCustomFormIDs;
            // return formIDs.every((m) => this.currentPassengerFormSubmissions.includes(m));
            // const includesAll = (arr, values) => values.every((v) => arr.includes(v));
        },
        currentPassengerFormSubmissions() {
            return this.submittedForms.filter((m) => m.passengerID == this.currentPassengerID);
        },
        currentPassengerFormSubmissionIDs() {
            return this.currentPassengerFormSubmissions.map((n) => n.checklistID);
        }
    },

    mounted() {
        NProgress.start();
        this.vesselID = this.$route.query.vesselid ? this.$route.query.vesselid : this.currentVessel.vesselID ? this.currentVessel.vesselID : '';
        // this.screenID = this.$route.query.screenid ? this.$route.query.screenid : 'trip';
        this.tripID = this.$route.query.docid ? this.$route.query.docid : this.currentTripID ? this.currentTripID : '';
        this.loadTripData();
        this.loadVesselData();
        this.loadFormSubmissions();
        setTimeout(() => {
            NProgress.done();
        }, 1000);
    },

    unmounted() {
        if (unsubTrip) unsubTrip();
        if (unsubVessel) unsubVessel();
    },

    data() {
        return {
            vesselID: '',
            screenID: '',
            tripID: '',
            tripData: {},
            vesselData: {},
            vesselOwnerID: '',
            showTripInfo: false,
            showSearch: false,
            //
            showHelpModal: false,
            phoneNumber: '',

            showSendCode: false,
            statusMessage: ["We'll send a one-time security code to your phone."],
            selectedCountry: { name: 'Australia', code: '+61', flag: '🇦🇺' },
            countries: [
                { name: 'Åland Islands', code: '+358', flag: '🇦🇽' },
                { name: 'Albania', code: '+355', flag: '🇦🇱' },
                { name: 'Algeria', code: '+213', flag: '🇩🇿' },
                { name: 'American Samoa', code: '+1684', flag: '🇦🇸' },
                { name: 'Andorra', code: '+376', flag: '🇦🇩' },
                { name: 'Angola', code: '+244', flag: '🇦🇴' },
                { name: 'Anguilla', code: '+1264', flag: '🇦🇮' },
                { name: 'Antigua & Barbuda', code: '+1268', flag: '🇦🇬' },
                { name: 'Argentina', code: '+54', flag: '🇦🇷' },
                { name: 'Armenia', code: '+374', flag: '🇦🇲' },
                { name: 'Aruba', code: '+297', flag: '🇦🇼' },
                { name: 'Australia', code: '+61', flag: '🇦🇺' },
                { name: 'Austria', code: '+43', flag: '🇦🇹' },
                { name: 'Azerbaijan', code: '+994', flag: '🇦🇿' },
                { name: 'Bahamas', code: '+1242', flag: '🇧🇸' },
                { name: 'Bahrain', code: '+973', flag: '🇧🇭' },
                { name: 'Bangladesh', code: '+880', flag: '🇧🇩' },
                { name: 'Barbados', code: '+1246', flag: '🇧🇧' },
                { name: 'Belarus', code: '+375', flag: '🇧🇾' },
                { name: 'Belgium', code: '+32', flag: '🇧🇪' },
                { name: 'Belize', code: '+501', flag: '🇧🇿' },
                { name: 'Benin', code: '+229', flag: '🇧🇯' },
                { name: 'Bermuda', code: '+1441', flag: '🇧🇲' },
                { name: 'Bhutan', code: '+975', flag: '🇧🇹' },
                { name: 'Bolivia', code: '+591', flag: '🇧🇴' },
                { name: 'Bosnia & Herzegovina', code: '+387', flag: '🇧🇦' },
                { name: 'Botswana', code: '+267', flag: '🇧🇼' },
                { name: 'Brazil', code: '+55', flag: '🇧🇷' },
                { name: 'British Indian Ocean Territory', code: '+246', flag: '🇮🇴' },
                { name: 'British Virgin Islands', code: '+1284', flag: '🇻🇬' },
                { name: 'Brunei', code: '+673', flag: '🇧🇳' },
                { name: 'Bulgaria', code: '+359', flag: '🇧🇬' },
                { name: 'Burkina Faso', code: '+226', flag: '🇧🇫' },
                { name: 'Burundi', code: '+257', flag: '🇧🇮' },
                { name: 'Cambodia', code: '+855', flag: '🇰🇭' },
                { name: 'Cameroon', code: '+237', flag: '🇨🇲' },
                { name: 'Canada', code: '+1', flag: '🇨🇦' },
                { name: 'Cape Verde', code: '+238', flag: '🇨🇻' },
                { name: 'Carribbean Netherlands', code: '+599', flag: '🇳🇱' },
                { name: 'Cayman Islands', code: '+1345', flag: '🇰🇾' },
                { name: 'Central African Republic', code: '+236', flag: '🇨🇫' },
                { name: 'Chad', code: '+235', flag: '🇹🇩' },
                { name: 'Chile', code: '+56', flag: '🇨🇱' },
                { name: 'China', code: '+86', flag: '🇨🇳' },
                { name: 'Christmas Islands', code: '+61', flag: '🇨🇽' },
                { name: 'Cocos Islands', code: '+61', flag: '🇨🇨' },
                { name: 'Colombia', code: '+57', flag: '🇨🇴' },
                { name: 'Comoros', code: '+269', flag: '🇰🇲' },
                { name: 'Congo-Kinshasa', code: '+243', flag: '🇨🇩' },
                { name: 'Congo-Brazzaville', code: '+242', flag: '🇨🇬' },
                { name: 'Cook Islands', code: '+682', flag: '🇨🇰' },
                { name: 'Costa Rica', code: '+506', flag: '🇨🇷' },
                { name: 'Croatia', code: '+385', flag: '🇭🇷' },
                { name: 'Cuba', code: '+53', flag: '🇨🇺' },
                { name: 'Curaçao', code: '+599', flag: '🇨🇼' },
                { name: 'Cyprus', code: '+357', flag: '🇨🇾' },
                { name: 'Czechia', code: '+420', flag: '🇨🇿' },
                { name: 'Denmark', code: '+45', flag: '🇩🇰' },
                { name: 'Djibouti', code: '+253', flag: '🇩🇯' },
                { name: 'Dominica', code: '+1767', flag: '🇩🇲' },
                { name: 'Dominican Republic', code: '+1', flag: '🇩🇴' },
                { name: 'Ecuador', code: '+593', flag: '🇪🇨' },
                { name: 'Egypt', code: '+20', flag: '🇪🇬' },
                { name: 'El Salvador', code: '+503', flag: '🇸🇻' },
                { name: 'Equatorial Guinea', code: '+240', flag: '🇬🇶' },
                { name: 'Eritrea', code: '+291', flag: '🇪🇷' },
                { name: 'Estonia', code: '+372', flag: '🇪🇪' },
                { name: 'Ethiopia', code: '+251', flag: '🇪🇹' },
                { name: 'Falkland Islands', code: '+500', flag: '🇫🇰' },
                { name: 'Faroe Islands', code: '+298', flag: '🇫🇴' },
                { name: 'Fiji', code: '+679', flag: '🇫🇯' },
                { name: 'Finland', code: '+358', flag: '🇫🇮' },
                { name: 'France', code: '+33', flag: '🇫🇷' },
                { name: 'French Guiana', code: '+594', flag: '🇬🇫' },
                { name: 'French Polynesia', code: '+689', flag: '🇵🇫' },
                { name: 'Gabon', code: '+241', flag: '🇬🇦' },
                { name: 'Gambia', code: '+220', flag: '🇬🇲' },
                { name: 'Georgia', code: '+995', flag: '🇬🇪' },
                { name: 'Germany', code: '+49', flag: '🇩🇪' },
                { name: 'Ghana', code: '+233', flag: '🇬🇭' },
                { name: 'Gibraltar', code: '+350', flag: '🇬🇮' },
                { name: 'Greece', code: '+30', flag: '🇬🇷' },
                { name: 'Greenland', code: '+299', flag: '🇬🇱' },
                { name: 'Grenada', code: '+1473', flag: '🇬🇩' },
                { name: 'Guadeloupe', code: '+590', flag: '🇬🇵' },
                { name: 'Guam', code: '+1671', flag: '🇬🇺' },
                { name: 'Guatemala', code: '+502', flag: '🇬🇹' },
                { name: 'Guernsey', code: '+44', flag: '🇬🇬' },
                { name: 'Guinea', code: '+224', flag: '🇬🇳' },
                { name: 'Guinea-Bissau', code: '+245', flag: '🇬🇼' },
                { name: 'Guyana', code: '+592', flag: '🇬🇾' },
                { name: 'Haiti', code: '+509', flag: '🇭🇹' },
                { name: 'Honduras', code: '+504', flag: '🇭🇳' },
                { name: 'Hong Kong', code: '+852', flag: '🇭🇰' },
                { name: 'Hungary', code: '+36', flag: '🇭🇺' },
                { name: 'Iceland', code: '+354', flag: '🇮🇸' },
                { name: 'India', code: '+91', flag: '🇮🇳' },
                { name: 'Indonesia', code: '+62', flag: '🇮🇩' },
                { name: 'Iran', code: '+98', flag: '🇮🇷' },
                { name: 'Iraq', code: '+964', flag: '🇮🇶' },
                { name: 'Ireland', code: '+353', flag: '🇮🇪' },
                { name: 'Isle of Man', code: '+44', flag: '🇮🇲' },
                { name: 'Israel', code: '+972', flag: '🇮🇱' },
                { name: 'Italy', code: '+39', flag: '🇮🇹' },
                { name: 'Ivory Coast', code: '+225', flag: '🇨🇮' },
                { name: 'Jamaica', code: '+1', flag: '🇯🇲' },
                { name: 'Japan', code: '+81', flag: '🇯🇵' },
                { name: 'Jersey', code: '+44', flag: '🇯🇪' },
                { name: 'Jordan', code: '+962', flag: '🇯🇴' },
                { name: 'Kazakhstan', code: '+7', flag: '🇰🇿' },
                { name: 'Kenya', code: '+254', flag: '🇰🇪' },
                { name: 'Kiribati', code: '+686', flag: '🇰🇮' },
                { name: 'Kosovo', code: '+383', flag: '🇽🇰' },
                { name: 'Kuwait', code: '+965', flag: '🇰🇼' },
                { name: 'Kyrgyzstan', code: '+996', flag: '🇰🇬' },
                { name: 'Laos', code: '+856', flag: '🇱🇦' },
                { name: 'Latvia', code: '+371', flag: '🇱🇻' },
                { name: 'Lebanon', code: '+961', flag: '🇱🇧' },
                { name: 'Lesotho', code: '+266', flag: '🇱🇸' },
                { name: 'Liberia', code: '+231', flag: '🇱🇷' },
                { name: 'Libya', code: '+218', flag: '🇱🇾' },
                { name: 'Liechtenstein', code: '+423', flag: '🇱🇮' },
                { name: 'Lithuania', code: '+370', flag: '🇱🇹' },
                { name: 'Luxembourg', code: '+352', flag: '🇱🇺' },
                { name: 'Macau', code: '+853', flag: '🇲🇴' },
                { name: 'Madagascar', code: '+261', flag: '🇲🇬' },
                { name: 'Malawi', code: '+265', flag: '🇲🇼' },
                { name: 'Malaysia', code: '+60', flag: '🇲🇾' },
                { name: 'Maldives', code: '+960', flag: '🇲🇻' },
                { name: 'Mali', code: '+223', flag: '🇲🇱' },
                { name: 'Malta', code: '+356', flag: '🇲🇹' },
                { name: 'Marshall Islands', code: '+692', flag: '🇲🇭' },
                { name: 'Martinique', code: '+596', flag: '🇲🇶' },
                { name: 'Mauritania', code: '+222', flag: '🇲🇷' },
                { name: 'Mauritius', code: '+230', flag: '🇲🇺' },
                { name: 'Mayotte', code: '+262', flag: '🇾🇹' },
                { name: 'Mexico', code: '+52', flag: '🇲🇽' },
                { name: 'Micronesia', code: '+691', flag: '🇫🇲' },
                { name: 'Moldova', code: '+373', flag: '🇲🇩' },
                { name: 'Monaco', code: '+377', flag: '🇲🇨' },
                { name: 'Mongolia', code: '+976', flag: '🇲🇳' },
                { name: 'Montenegro', code: '+382', flag: '🇲🇪' },
                { name: 'Montserrat', code: '+1664', flag: '🇲🇸' },
                { name: 'Morocco', code: '+212', flag: '🇲🇦' },
                { name: 'Mozambique', code: '+258', flag: '🇲🇿' },
                { name: 'Myanmar', code: '+95', flag: '🇲🇲' },
                { name: 'Namibia', code: '+264', flag: '🇳🇦' },
                { name: 'Nauru', code: '+674', flag: '🇳🇷' },
                { name: 'Nepal', code: '+977', flag: '🇳🇵' },
                { name: 'Netherlands', code: '+31', flag: '🇳🇱' },
                { name: 'New Caledonia', code: '+687', flag: '🇳🇨' },
                { name: 'New Zealand', code: '+64', flag: '🇳🇿' },
                { name: 'Nicaragua', code: '+505', flag: '🇳🇮' },
                { name: 'Niger', code: '+227', flag: '🇳🇪' },
                { name: 'Nigeria', code: '+234', flag: '🇳🇬' },
                { name: 'Niue', code: '+683', flag: '🇳🇺' },
                { name: 'Norfolk Island', code: '+6723', flag: '🇳🇫' },
                { name: 'North Korea', code: '+850', flag: '🇰🇵' },
                { name: 'North Macedonia', code: '+389', flag: '🇲🇰' },
                { name: 'Northern Mariana Islands', code: '+1670', flag: '🇲🇵' },
                { name: 'Norway', code: '+47', flag: '🇳🇴' },
                { name: 'Oman', code: '+968', flag: '🇴🇲' },
                { name: 'Pakistan', code: '+92', flag: '🇵🇰' },
                { name: 'Palau', code: '+680', flag: '🇵🇼' },
                { name: 'Panama', code: '+507', flag: '🇵🇦' },
                { name: 'Papua New Guinea', code: '+675', flag: '🇵🇬' },
                { name: 'Paraguay', code: '+595', flag: '🇵🇾' },
                { name: 'Peru', code: '+51', flag: '🇵🇪' },
                { name: 'Philippines', code: '+63', flag: '🇵🇭' },
                { name: 'Poland', code: '+48', flag: '🇵🇱' },
                { name: 'Portugal', code: '+351', flag: '🇵🇹' },
                { name: 'Puerto Rico', code: '+1', flag: '🇵🇷' },
                { name: 'Qatar', code: '+974', flag: '🇶🇦' },
                { name: 'Réunion', code: '+262', flag: '🇫🇷' },
                { name: 'Romania', code: '+40', flag: '🇷🇴' },
                { name: 'Russia', code: '+7', flag: '🇷🇺' },
                { name: 'Rwanda', code: '+250', flag: '🇷🇼' },
                { name: 'Saint-Barthélemy', code: '+590', flag: '🇧🇱' },
                { name: 'Saint Helena', code: '+290', flag: '🇸🇭' },
                { name: 'Saint Kitts & Nevis', code: '+1869', flag: '🇰🇳' },
                { name: 'Saint Lucia', code: '+1758', flag: '🇱🇨' },
                { name: 'Saint Martin', code: '+590', flag: '🇫🇷' },
                { name: 'Saint Pierre & Miquelon', code: '+508', flag: '🇵🇲' },
                { name: 'Saint Vincent & Grenadines', code: '+1784', flag: '🇻🇨' },
                { name: 'Samoa', code: '+685', flag: '🇼🇸' },
                { name: 'San Marino', code: '+378', flag: '🇸🇲' },
                { name: 'São Tomé & Príncipe', code: '+239', flag: '🇸🇹' },
                { name: 'Saudi Arabia', code: '+966', flag: '🇸🇦' },
                { name: 'Senegal', code: '+221', flag: '🇸🇳' },
                { name: 'Serbia', code: '+381', flag: '🇷🇸' },
                { name: 'Seychelles', code: '+248', flag: '🇸🇨' },
                { name: 'Sierra Leone', code: '+232', flag: '🇸🇱' },
                { name: 'Singapore', code: '+65', flag: '🇸🇬' },
                { name: 'Sint Maarten', code: '+1721', flag: '🇸🇽' },
                { name: 'Slovakia', code: '+421', flag: '🇸🇰' },
                { name: 'Slovenia', code: '+386', flag: '🇸🇮' },
                { name: 'Solomon Islands', code: '+677', flag: '🇸🇧' },
                { name: 'Somalia', code: '+252', flag: '🇸🇴' },
                { name: 'South Africa', code: '+27', flag: '🇿🇦' },
                { name: 'South Korea', code: '+82', flag: '🇰🇷' },
                { name: 'South Sudan', code: '+211', flag: '🇸🇸' },
                { name: 'Spain', code: '+34', flag: '🇪🇸' },
                { name: 'Sri Lanka', code: '+94', flag: '🇱🇰' },
                { name: 'Sudan', code: '+249', flag: '🇸🇩' },
                { name: 'Suriname', code: '+597', flag: '🇸🇷' },
                { name: 'Svalbard & Jan Mayen', code: '+47', flag: '🇳🇴' },
                { name: 'Swaziland', code: '+268', flag: '🇸🇿' },
                { name: 'Sweden', code: '+46', flag: '🇸🇪' },
                { name: 'Switzerland', code: '+41', flag: '🇨🇭' },
                { name: 'Syria', code: '+963', flag: '🇸🇾' },
                { name: 'Taiwan', code: '+886', flag: '🇹🇼' },
                { name: 'Tajikistan', code: '+992', flag: '🇹🇯' },
                { name: 'Tanzania', code: '+255', flag: '🇹🇿' },
                { name: 'Thailand', code: '+66', flag: '🇹🇭' },
                { name: 'Timor-Leste', code: '+670', flag: '🇹🇱' },
                { name: 'Togo', code: '+228', flag: '🇹🇬' },
                { name: 'Tokelau', code: '+690', flag: '🇹🇰' },
                { name: 'Tonga', code: '+676', flag: '🇹🇴' },
                { name: 'Trinidad & Tobago', code: '+1868', flag: '🇹🇹' },
                { name: 'Tunisia', code: '+216', flag: '🇹🇳' },
                { name: 'Turkey', code: '+90', flag: '🇹🇷' },
                { name: 'Turkmenistan', code: '+993', flag: '🇹🇲' },
                { name: 'Turks & Caicos Islands', code: '+1649', flag: '🇹🇨' },
                { name: 'Tuvalu', code: '+688', flag: '🇹🇻' },
                { name: 'U.S. Virgin Islands', code: '+1340', flag: '🇻🇮' },
                { name: 'Uganda', code: '+256', flag: '🇺🇬' },
                { name: 'Ukraine', code: '+380', flag: '🇺🇦' },
                { name: 'United Arab Emirates', code: '+971', flag: '🇦🇪' },
                { name: 'United Kingdom', code: '+44', flag: '🇬🇧' },
                { name: 'United States', code: '+1', flag: '🇺🇸' },
                { name: 'Uruguay', code: '+598', flag: '🇺🇾' },
                { name: 'Uzbekistan', code: '+998', flag: '🇺🇿' },
                { name: 'Vanuatu', code: '+678', flag: '🇻🇺' },
                { name: 'Vatican City', code: '+39', flag: '🇻🇦' },
                { name: 'Venezuela', code: '+58', flag: '🇻🇪' },
                { name: 'Vietnam', code: '+84', flag: '🇻🇳' },
                { name: 'Wallis & Futuna', code: '+681', flag: '🇼🇫' },
                { name: 'Western Sahara', code: '+212', flag: '🇪🇭' },
                { name: 'Yemen', code: '+967', flag: '🇾🇪' },
                { name: 'Zambia', code: '+260', flag: '🇿🇲' },
                { name: 'Zimbabwe', code: '+263', flag: '🇿🇼' }
            ],
            showOptions: false,
            areaCode: '61',
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            showInputs: true,
            errorMessage: [],
            hasError: false,
            crossChecked: false,
            showOTP: false,
            sendMethod: '',
            codeSent: false,
            otpCode: '',
            validating: false,
            checkingCode: false,
            sendingCode: false,
            codeValid: false,
            creatingToken: false,
            tokenValid: false,
            savedUserID: '',
            userName: '',
            customToken: '',
            //
            currentPassenger: {
                name: '',
                email: '',
                verified: false,
                lastVerification: {
                    method: '',
                    date: '',
                    code: ''
                }
            },
            //
            conflictWarning: ['Checking current user database...'],
            showConflictWarning: true,
            // phoneVerified: false,
            showMyInfo: false,
            showAllForms: false,
            submittedForms: [],
            showEditField: false,
            selectedDataField: {
                type: '',
                fieldName: '',
                label: '',
                field: '',
                list: []
            },
            dataChanged: false,
            showRemoveMe: false,
            showUpdates: false
        };
    },
    methods: {
        close() {
            this.showTripInfo = false;
            this.showSearch = false;
            this.conflictWarning = [];
            this.showConflictWarning = false;
            this.showMyInfo = false;
            this.showAllForms = false;
            this.showEditField = false;
            this.selectedDataField = {
                type: '',
                fieldName: '',
                label: '',
                field: '',
                list: []
            };
            this.dataChanged = false;
            this.showRemoveMe = false;
            this.showUpdates = false;
        },

        loadTripData() {
            unsubTrip = onSnapshot(doc(db, 'vessels', this.vesselID, 'logStartups', this.tripID), (doc) => {
                let data = doc.data();
                data.updates = data.updates ? data.updates : [];
                this.tripData = data;
            });
        },

        loadVesselData() {
            unsubVessel = onSnapshot(doc(db, 'vessels', this.vesselID), (doc) => {
                this.vesselData = doc.data();
                this.vesselOwnerID = doc.data().vesselOwnerID ? doc.data().vesselOwnerID : '';
            });
        },

        loadFormSubmissions() {
            let self = this;
            let q = query(collection(db, 'vessels', this.vesselID, 'logStartups', this.tripID, 'formSubmissions'), orderBy('timestamp', 'asc'));
            onSnapshot(q, (snapshot) => {
                self.submittedForms = [];
                snapshot.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    self.submittedForms.push(data);
                });
            });
        },

        editField(type, fieldName, label, field, list) {
            let tempField = field;
            let tempList = [...list];
            this.selectedDataField = {
                type: type,
                fieldName: fieldName,
                label: label,
                field: tempField,
                list: tempList
            };
            this.showEditField = true;
        },

        removeDependant(idx) {
            this.selectedDataField.list.splice(idx, 1);
        },

        async savePassengerField() {
            NProgress.start();
            // console.log(this.selectedDataField);
            let updateData = {};
            if (this.selectedDataField.type == 'list') {
                this.selectedDataField.list = this.selectedDataField.list.filter((item) => item !== '');
                updateData[this.selectedDataField.fieldName] = this.selectedDataField.list;
            } else {
                updateData[this.selectedDataField.fieldName] = this.selectedDataField.field;
            }
            updateTripPassenger({
                updateData: updateData,
                vesselID: this.vesselID,
                vesselName: this.tripData.vesselName ?? '',
                tripID: this.tripID,
                tripName: this.tripData.tag ?? 'Unnamed trip',
                passengerID: this.currentPassengerManifestData.id,
                passengerName: this.currentPassengerManifestData.name ?? 'Unnamed passenger'
            });
            setTimeout(() => {
                this.close();
                NProgress.done();
            }, 1500);
        },

        removeMe() {
            NProgress.start();
            removePassenger({
                vesselID: this.vesselID,
                vesselName: this.tripData.vesselName ?? '',
                tripID: this.tripID,
                tripName: this.tripData.tag ?? 'Unnamed trip',
                passengerID: this.currentPassengerManifestData.id,
                passengerName: this.currentPassengerManifestData.name ?? 'Unnamed passenger'
            });
            setTimeout(() => {
                this.$store.dispatch('CLEARPASSENGERDATA');
                this.close();
                NProgress.done();
                this.$router.push({
                    name: 'Home'
                });
            }, 1500);
        },

        getAvatar(assetName) {
            if (!assetName) return '';
            let parts = assetName.split(' ');
            let initials = '';
            for (let i = 0; i < 2; i++) {
                if (parts[i] != undefined && parts[i].length > 0 && parts[i] !== '') {
                    initials += parts[i][0];
                }
            }
            return initials;
        },

        displayDate(sentDate, full) {
            if (typeof sentDate == 'string' || typeof sentDate == 'number') return sentDate;
            const dateOptions = full
                ? { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
                : { month: 'short', day: 'numeric', year: 'numeric' };
            if (!sentDate) {
                return 'No date';
            } else {
                try {
                    return sentDate.toDate().toLocaleDateString('en-US', dateOptions);
                } catch (error) {
                    let t = new Date(sentDate.seconds * 1000 + sentDate.nanoseconds / 1000000);
                    // console.log('error', error, t);
                    return t.toLocaleDateString('en-US', dateOptions);
                }
            }
        },

        truncate(string, limit) {
            if (string == undefined || string == '') return '';
            return string.length > limit ? string.slice(0, limit) + '...' : string;
        },

        // updateCode(code) {
        //     // { name, iso2, dialCode }
        //     // console.log(code);
        //     // this.areaCode = dialCode.toString();
        // },

        showFormSubmission(log) {
            // console.log(log);
            let url = 'https://offshoresms.com.au/custom-log-response?vid=BNltco7v26kkdTbhFxGh&lid=0tdQb4uXiqqNsarpC11jEQuMe&f=1';
            let isFleet = log.isFleetLog ? '1' : '0';
            let baseUrl = 'https://offshoresms.com.au/custom-log-response?vid=' + this.vesselID + '&lid=' + log.logID + '&f=' + isFleet;
            window.open(baseUrl, '_blank');
        },

        async showNewFormLink(log) {
            // console.log(log);
            NProgress.start();
            if (this.vesselOwnerID == '') return;
            const collPath = log.isFleet ? 'companies/' + this.vesselOwnerID + '/customChecklists' : 'vessels/' + this.vesselID + '/customChecklists';
            const docRef = doc(db, collPath, log.id);
            // console.log('docRef', docRef);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let data = docSnap.data();
                let webLink = docSnap.data().webLink ?? '';
                if (webLink != '') this.loadCustomLog(log, webLink);
            } else {
                NProgress.done();
            }
        },

        loadCustomLog(log, webLink) {
            // console.log(log, webLink);
            let baseUrl = 'https://go.offshoresms.com.au/vessel-logs?x=' + webLink + '&a=' + this.vesselID;
            // append tripID, passengerID, passengerName
            baseUrl += '&tid=' + this.tripID + '&pid=' + this.currentPassengerManifestData.id + '&pname=' + this.currentPassengerManifestData.name;
            window.open(baseUrl, '_blank', 'noreferrer');
            NProgress.done();
        },

        isNumber(evt) {
            this.errorMessage = [];
            // evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        clearErrors() {
            this.errorMessage = [];
            this.hasError = false;
            this.crossChecked = false;
            this.showOTP = false;
            this.sendMethod = '';
            this.statusMessage = [];
        },

        async nextVerifyDetails() {
            this.hasError = true;
            this.errorMessage = [];
            NProgress.start();
            this.conflictWarning = ['Checking current user database...'];
            this.showConflictWarning = true;
            // this.phoneVerified = false;
            let value = false;
            this.$store.dispatch('SETPHONEVERIFIED', { value });
            // this.emailVerified = false;
            // this.existingPhoneCheck(); not needed at this stage
            const result = await this.checkNumberInManifest();
            const canProceed = result.data.success && result.data.success === true;
            if (canProceed) {
                // console.log('Can proceed', result.data);
                this.smsTwilioCode();
            } else {
                // console.log('Can NOT proceed', result.data);
                this.codeSent = false;
                this.statusMessage = [];
                this.hasError = true;
                this.errorMessage = ["That number doesn't look right", 'Please check it and try again.'];
                this.sendingCode = false;
                this.$store.dispatch('incrementPaxCodeAttempts');
                NProgress.done();
            }
        },

        async checkNumberInManifest() {
            const result = await checkPassengerNumberInManifest({
                vesselID: this.vesselID,
                tripID: this.tripID,
                phoneNumber: this.finalNumber
            }).catch((error) => {
                console.log('Error checking number in manifest', error);
                this.codeSent = false;
                this.statusMessage = [];
                this.hasError = true;
                this.errorMessage = ['Something went wrong, sorry', 'Please try again later or give us a call on 0447 139 382.'];
                this.sendingCode = false;
                this.$store.dispatch('incrementPaxCodeAttempts');
            });
            return result;
        },

        // async existingPhoneCheck() {
        //     let self = this;
        //     await checkPassengerExistsByPhone({
        //         sentPhoneNumber: self.finalNumber
        //     })
        //         .then((result) => {
        //             if (result.data.text != null) {
        //                 let isUser = result.data.text.uid && result.data.text.uid != '';
        //                 if (isUser) {
        //                     self.conflictWarning = [
        //                         'Your email address or phone number has already been used.',
        //                         'Please check again or sign in as an existing user.'
        //                     ];
        //                     NProgress.done();
        //                 } else {
        //                     self.conflictWarning = [];
        //                     self.showConflictWarning = false;
        //                     self.showSendCode = true;
        //                     NProgress.done();
        //                 }
        //             } else {
        //                 self.conflictWarning = [];
        //                 self.showConflictWarning = false;
        //                 self.showSendCode = true;
        //                 NProgress.done();
        //             }
        //         })
        //         .catch((error) => {
        //             console.log('error', error);
        //             self.conflictWarning = [];
        //             self.showConflictWarning = false;
        //             self.showSendCode = true;
        //             NProgress.done();
        //         });
        // },

        async smsTwilioCode() {
            let self = this;
            this.$store.dispatch('incrementPaxCodeAttempts');
            NProgress.start();
            this.sendMethod = 'sms';
            this.sendingCode = true;

            // console.log(this.finalNumber);

            sendTwilioVerifySmsCode({
                recipient: self.finalNumber
            })
                .then((result) => {
                    let successMsg = result.data.message;
                    // console.log(result.data, successMsg);
                    if (successMsg == 'Success') {
                        setTimeout(() => {
                            self.codeSent = true;
                            self.statusMessage = ['A One Time code has been sent to your phone number.', 'When it arrives, enter it below:'];
                            self.sendingCode = false;
                            NProgress.done();
                        }, 300);
                    } else {
                        self.codeSent = false;
                        self.statusMessage = [];
                        self.hasError = true;
                        self.errorMessage = ['There was an error sending the code to your phone number.', 'Please check it and try again.'];
                        self.sendingCode = false;
                        NProgress.done();
                    }
                })
                .then(() => {
                    // userHasEngaged({
                    //     userID: 'NA',
                    //     userName: self.newEmail + ' / ' + self.finalNumber,
                    //     subject: 'MONOREPO: Existing user login attempt (SMS verification)',
                    //     message: 'A user has attempted to get a code sent by SMS to ' + self.finalNumber + ' (Email is: ' + self.newEmail + ')'
                    // });
                })
                .catch((error) => {
                    self.codeSent = false;
                    self.statusMessage = [];
                    self.hasError = true;
                    self.errorMessage = ['There was an error sending the code to your phone number.', 'Please check it and try again.'];
                    self.sendingCode = false;
                    console.log('Error sending auth Code', error.text);
                    NProgress.done();
                });
        },

        validateTwilioSmsCode() {
            let self = this;
            NProgress.start();
            this.checkingCode = true;

            checkTwilioVerifySmsCode({
                recipient: self.finalNumber,
                otpCode: self.otpCode
            })
                .then((result) => {
                    let verification = result.data.text;
                    // console.log('result', result);
                    if (verification.status == 'approved') {
                        // self.creatingToken = true;
                        // self.generateCustomToken(); // not logging in at this stage
                        // save user details to the store / state
                        // const paxData = result.data.text;
                        // self.$store.dispatch('SETCURRENTPASSENGERDATA', paxData);
                        self.statusMessage = ['Code confirmed. Retrieving account info...'];
                        // self.phoneVerified = true;
                        let value = true;
                        this.$store.dispatch('SETPHONEVERIFIED', { value });
                        self.loadRootPassengerData();
                    } else {
                        self.hasError = true;
                        self.errorMessage = ['Your code was incorrect, sorry.', 'Please try again or click Get Help below.'];
                        NProgress.done();
                    }
                    self.checkingCode = false;
                })
                .catch((error) => {
                    console.log('Error sending auth Code', error.text);
                    NProgress.done();
                });
        },

        async loadManifestDataForPassenger(passengerID) {
            const data = onSnapshot(
                doc(db, 'vessels', this.vesselID, 'logStartups', this.tripID, 'passengerManifest', passengerID),
                (doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    let currentPassengerManifestData = data;
                    this.$store.dispatch('SETCURRENTPASSENGERMANIFESTDATA', { currentPassengerManifestData });
                },
                (error) => {
                    self.loadingData = false;
                }
            );
        },

        async loadManifestData() {
            const docRef = doc(db, 'passengerData', this.finalNumber, 'trips', this.tripID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let data = docSnap.data();
                let passengerID = data.passengerID ?? '';
                if (passengerID != '') await this.loadManifestDataForPassenger(passengerID);
            } else {
                this.errorMessage = ['We could not retrieve any passenger information for you, sorry.'];
                this.close();
                NProgress.done();
            }
        },

        async loadRootPassengerData() {
            // console.log('loading passenger data', this.finalNumber);
            const docRef = doc(db, 'passengerData', this.finalNumber);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let data = docSnap.data();
                // console.log('data', data);
                let currentPassengerData = data;
                this.$store.dispatch('SETCURRENTPASSENGERDATA', { currentPassengerData });
                await this.loadManifestData();
                setTimeout(() => {
                    this.close();
                    NProgress.done();
                }, 1000);
            } else {
                this.errorMessage = ['We could not retrieve any passenger information for you, sorry.'];
                this.close();
                NProgress.done();
            }
        },

        cancelVerification() {
            this.phoneNumber = '';
            this.close();
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 600px !important;
}
.avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 24px;
    position: relative;
    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        font-weight: bold;
        display: flex;
        background: #e4e4e4;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        margin-right: 12px;
        background-size: cover;
        border: 4px solid #ab6aab;
    }
    .round-button-letters {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        background: steelblue;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        margin-right: 1rem;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 45px;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .asset-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        .asset-name {
            font-size: 25px;
            font-weight: bold;
        }
        .asset-owner {
            font-size: 15px;
        }
    }
}
.modal-card {
    &.left {
        text-align: left;
    }
}
.buttons {
    &.mt-3 {
        margin: 10px 0 6px;
    }
}
table {
    th {
        width: 30%;
        text-align: right;
        padding-right: 10px;
    }
    td {
        text-align: left;
    }
}
.stars {
    span {
        margin: 0 6px;
    }
}
h5 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 12px;
}
.modal .modal-card {
    max-height: calc(100vh - 150px) !important;
}
.custom-dropdown-wrapper {
    position: relative;
    display: flex;
    .custom-dropdown-button {
        padding: 24px 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 105px;
        height: 30px;
        border: 1px solid #bbb;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 6px;
        font-size: 16px;
    }

    .phone-number {
        flex-grow: 1;
        input[type='tel'] {
            padding: 12px 10px;
            height: auto;
            border-radius: 3px;
            border-color: #bbb;
            box-shadow: none;

            &:focus {
                border-color: dodgerblue;
            }
        }
    }
    .number-option-wrapper {
        min-height: 0px;
        &.shown {
            min-height: 300px;
        }
        .custom-dropdown-options {
            display: none;
            position: absolute;
            top: 70px;
            left: 0;
            border: 1px solid #ddd;
            padding: 1rem;
            height: 200px;
            z-index: 99;
            overflow-y: scroll;
            background: #fff;
            min-height: 0px;
            cursor: pointer;
            &.shown {
                display: block;
                // min-height: 300px;
            }
            .ind-country {
                line-height: 30px;
            }
        }
    }
}
.final-icon {
}
.final-number {
    font-weight: bold;
    color: cadetblue;
    font-size: 1.1rem;
    letter-spacing: 1pt;
}
.twilio-code {
    input[type='tel'] {
        padding: 20px 20px;
        height: auto;
        border-radius: 3px;
        border-color: #bbb;
        box-shadow: none;
        letter-spacing: 3pt;
        font-size: 1.5rem;
        color: red;
        font-weight: bold;
        max-width: 180px;
        &:focus {
            border-color: dodgerblue;
        }
        &.long {
            color: mediumseagreen;
        }
    }
}
.table {
    tbody {
        tr {
            td {
                &.edit {
                    width: 50px;
                    text-align: center;
                    color: #aaa;
                }
            }
        }
    }
}
.input-wrapper {
    margin-bottom: 8px;
    position: relative;
    .button {
        position: absolute;
        top: -6px;
        right: -6px;
        border-radius: 50%;
        background-color: #e4e4e4;
        border: none;
        width: 25px;
        height: 25px;
        color: #666;
        font-size: 12px;
    }
}
.past-logs-wrapper {
    .past-log {
        margin-bottom: 8px;
        .log-date {
            text-transform: uppercase;
            font-size: 0.9rem;
        }
    }
}
</style>

<template>
    <div class="">
        <div class="container">
            <div v-if="checkingForm || loadingFormData">
                <div class="spacer s30"></div>
                <h3>
                    <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>&nbsp; {{ status }}
                </h3>
            </div>

            <div class="spacer s10 desktop"></div>
            <!-- {{ allVesselCrewIDs }} -->
            <!-- {{ formData }} -->

            <div v-if="formLoaded">
                <div v-if="accessAllowed">
                    <div v-if="requiresLogin">
                        <div v-if="userHasAccess">
                            <div v-if="isSinglePage">
                                <CustomLogSinglePage :allVesselCrewIDs="allVesselCrewIDs" />
                            </div>
                            <div v-else>
                                <CustomLogsTabbedPage :allVesselCrewIDs="allVesselCrewIDs" />
                            </div>
                        </div>
                        <div v-else>
                            <div class="spacer s30"></div>
                            {{ formMessage }} <br />
                            <div class="spacer s30"></div>
                            <div v-if="!isLoggedIn" class="button is-primary" @click="showLogin()">Login required</div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="isSinglePage">
                            <CustomLogSinglePage :allVesselCrewIDs="allVesselCrewIDs" />
                        </div>
                        <div v-else>
                            <CustomLogsTabbedPage :allVesselCrewIDs="allVesselCrewIDs" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="spacer s30"></div>
                    {{ formMessage }}
                </div>
            </div>
            <div v-else>
                <div class="spacer s30"></div>
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, where, doc, onSnapshot, setDoc, getDocs, getDoc, orderBy, limit } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';

import CustomLogsTabbedPage from './subcomponents/CustomLogsTabbedPage.vue';
import CustomLogSinglePage from './subcomponents/CustomLogSinglePage.vue';

const functions = getFunctions();
const addRevision = httpsCallable(functions, 'addRevision');

export default {
    props: {
        // sentCustomLog: Object,
        webLink: String
    },

    mounted() {
        let hasParam = this.$route.query && this.$route.query.x;
        if (hasParam) {
            this.xID = this.$route.query.x ? this.$route.query.x : '';
            this.vID = this.$route.query.v ? this.$route.query.v : '';
            this.aID = this.$route.query.a ? this.$route.query.a : '';
            // passenger manifest and trip params:
            this.tID = this.$route.query.tid ? this.$route.query.tid : '';
            this.pID = this.$route.query.pid ? this.$route.query.pid : '';
            this.pName = this.$route.query.pname ? this.$route.query.pname : '';
            this.linksToManifest = this.tID != '' && this.pID != '' ? true : false;
        } else {
            this.xID = this.webLink ? this.webLink : '';
        }

        let self = this;
        if (this.xID != '') {
            this.checkCodeExists();
        } else {
            setTimeout(() => {
                self.checkingForm = false;
                self.status = 'No log found.';
                self.errorMessage = 'No log found.';
            }, 1000);
        }
    },

    components: {
        CustomLogsTabbedPage,
        CustomLogSinglePage
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userID() {
            return this.userData.userid;
        },
        userFirstName() {
            return this.userData.username.split(' ')[0];
        }
    },

    data() {
        return {
            code: '',
            currentWebLink: '',
            checkingForm: true,
            formExists: false,
            loadingFormData: false,
            hasData: false,
            status: 'Checking if log exists..',
            formMessage: '',
            formLoaded: false,
            errorMessage: '',

            formMeta: {},
            formData: {},

            xID: '',
            vID: '',
            aID: '',
            accessAllowed: false,
            requiresLogin: false,

            userHasAccess: false,
            allVesselCrewIDs: [],

            customFields: [],
            isSinglePage: false,
            //
            tID: '',
            pID: '',
            pName: '',
            linksToManifest: false
        };
    },

    methods: {
        async checkCodeExists() {
            this.errorMessage = '';
            let self = this;

            const docRef = doc(db, 'vesselWebLinkIDs', this.xID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                self.formMeta = docSnap.data();
                self.formMeta.vID = self.vID;
                self.formMeta.aID = self.aID;
                if (docSnap.data().vesselID) {
                    let vesselID = docSnap.data().vesselID;
                    self.$store.dispatch('setCurrentVessel', { vesselID });
                }
                let logMeta = self.formMeta;
                self.$store.dispatch('setCurrentLogMeta', { logMeta });
                setTimeout(() => {
                    self.status = 'Loading settings...';
                    self.formExists = true;
                    self.loadingFormData = true;
                    self.checkingForm = false;
                    self.loadFormData();
                }, 500);
            } else {
                self.checkingForm = false;
                self.status = 'This form could not be loaded.';
                self.errorMessage = 'No custom log found.';
            }
        },

        loadFormData() {
            let self = this;
            if (this.formMeta.logType == 'custom-log') {
                self.hasData = true;
                self.status = 'Form located. Updating fields...';
                setTimeout(() => {
                    self.loadCustomLog();
                }, 500);
            }
        },

        async loadCustomLog() {
            // console.log(this.formMeta);
            const coll = this.formMeta.isFleet ? 'companies/' : 'vessels/';

            let self = this;
            const docRef = doc(db, coll + this.formMeta.vesselID + '/customChecklists', this.formMeta.logID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let archived = docSnap.data().archived ? docSnap.data().archived : false;
                if (archived) {
                    self.errorMessage = 'This form is no longer available, sorry.';
                    self.loadingFormData = false;
                } else {
                    let data = docSnap.data();
                    data.id = docSnap.id;
                    self.currentWebLink = docSnap.data().webLink ? docSnap.data().webLink : '';
                    data.linkedTrainingSchedules = docSnap.data().linkedTrainingSchedules ? docSnap.data().linkedTrainingSchedules : [];
                    if (self.xID != self.currentWebLink) {
                        self.loadingFormData = false;
                        self.errorMessage = 'Form expired. Access denied.';
                        return;
                    }
                    self.formData = data;
                    self.isSinglePage = data.webLinkFormIsSinglePage ? data.webLinkFormIsSinglePage : false;
                    // manifest links
                    self.formData.linksToManifest = true;
                    if (self.linksToManifest) {
                        self.formData.tripID = self.tID;
                        self.formData.passengerID = self.pID;
                        self.formData.passengerName = self.pName;
                    }
                    //
                    let logData = self.formData;
                    self.$store.dispatch('setCurrentLogData', { logData });
                    self.loadVesselData();
                    setTimeout(() => {
                        self.validateAccess();
                    }, 1000);
                }
            } else {
                self.formMessage = 'This form is not available, sorry.';
                self.loadingFormData = false;
            }
        },

        async loadVesselData() {
            let self = this;
            // let assetID = this.formData.realAssetID ? this.formData.realAssetID : '';
            let assetID = this.aID != '' ? this.aID : this.formData.realAssetID ? this.formData.realAssetID : this.formMeta.vesselID;
            // const docRef = doc(db, 'vessels', assetID);
            // const docSnap = await getDoc(docRef);
            console.log('assetID', this.aID, this.formData.realAssetID, this.formMeta.vesselID, assetID);

            // if (assetID != '') {
            const unsub = onSnapshot(doc(db, 'vessels', assetID), (doc) => {
                let allData = doc.data();
                let ch1 = allData.listOfCrewIDs ? allData.listOfCrewIDs : [];
                self.allVesselCrewIDs = [...ch1];
                self.$store.dispatch('UPDATESELECTEDVESSEL', { allData });
            });
            // }

            // if (docSnap.exists()) {
            //     // console.log(docSnap.data());
            //     let allData = docSnap.data();
            //     let ch1 = allData.listOfCrewIDs ? allData.listOfCrewIDs : [];
            //     self.allVesselCrewIDs = [...ch1];
            //     self.$store.dispatch('UPDATESELECTEDVESSEL', { allData });
            // }
        },

        validateAccess() {
            let self = this;
            if (!this.formData.allowWebLink) {
                self.loadingFormData = false;
                self.formLoaded = true;
                self.formMessage = 'This form is not available, sorry.';
                self.accessAllowed = false;
            } else {
                // check if is public or crew restricted
                self.accessAllowed = true;
                if (self.formData.webLinkCrewOnly) {
                    self.status = 'Checking user access...';
                    self.requiresLogin = true;
                    setTimeout(() => {
                        self.checkUserAccess();
                    }, 1000);
                } else {
                    self.loadingFormData = false;
                    self.formLoaded = true;
                }
            }
        },

        checkUserAccess() {
            let self = this;
            // console.log(this.isLoggedIn,this.allVesselCrewIDs,this.userID);
            this.userHasAccess = this.isLoggedIn && this.allVesselCrewIDs.includes(this.userID);
            this.formMessage = this.userHasAccess ? 'Access permitted..' : 'This form is only available to authorised users.';
            self.loadingFormData = false;
            self.formLoaded = true;
        },

        showLogin() {
            this.$router.push({
                name: 'UserLogin',
                params: {
                    return: true
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 800px !important;
    padding: 1rem 20px !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: pink;
    letter-spacing: 1px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: pink;
    letter-spacing: 1px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: pink;
    letter-spacing: 1px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: pink;
    letter-spacing: 1px;
}

h1 {
    color: #444;
}

.toggle {
    position: fixed;
    top: 65px;
    right: 10px;
    background: cadetblue;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        background: mediumseagreen;
    }
}

// .teds-box {
//     border: 10px solid orange;
//     background-color: black;
//     color: orange;
//     font-size: 40px;
//     padding: 50px;
//     border-radius: 50px;
// }

@media screen and (max-width: 769px) {
    .desktop {
        display: none;
    }
}
</style>

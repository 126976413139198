<template>

  <div>               

      
      <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{'is-active':showModal}">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card" :class="[theme, fullscreen ? 'fullscreen' : '']">
          
            <header class="modal-card-head">
              <p class="modal-card-title">{{title}}</p>
              <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
            </header>

            <section class="modal-card-body"> 
              
              <slot>Loading content...</slot>               

            </section>
            <footer class="modal-card-foot">
              <button class="button" @click="close">Done</button>
            </footer>        

        </div>
      </div>



  </div>

</template>



<script>

import firebase from 'firebase/app';
import { db } from '../main.js';
import NProgress from 'nprogress';

export default {

  props: {
    theme: String,
    title: String,
    details: String,
    fullscreen: Boolean, 
  },

  data: function() {
    return {
      showModal: true,
    }
  },

  methods: {

    close: function() {
      this.showModal = false;
      let result = {
        answer: 'Modal closed',
        modal: false
      };
      this.$emit('closed', result);
    },

  }


}


</script> 

<style lang="scss" scoped>

  .modal-card-title {
    color: #fff;
    font-size: 1.1rem;
    flex-shrink: initial;
    text-align: left;
  }
  .modal-close, .delete {
    max-height: none;
    max-width: none;
    width: 30px;
    height: 30px;
    text-align: center;
  }
  .modal-card-body {
    text-align: left;
    color: #333;
    min-height: 0;
  }
  .modal-card {
    width: 90%;
    max-width: 600px;
    overflow: visible;
    max-height: 75%;
    margin-top: 20px;
    &.red {
      .modal-card-head {
        background: #dd7979;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #dd7979;
          border-color: #dd7979;
          color: #fff;
        }
      }
    }
    &.green {
      .modal-card-head {
        background: #67bf8e;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #67bf8e;
          border-color: #67bf8e;
          color: #fff;
        }
      }
    }
    &.blue {
      .modal-card-head {
        background: #0cabe1;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #0cabe1;
          border-color: #0cabe1;
          color: #fff;
        }
      }
    }
    &.orange {
      .modal-card-head {
        background: #ffbb3e;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #ffbb3e;
          border-color: #ffbb3e;
          color: #fff;
        }
      }
    }
    &.high {
      .modal-card-head {
        background: #dd7979;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #dd7979;
          border-color: #dd7979;
          color: #fff;
        }
      }
    }
    &.medium {
      .modal-card-head {
        background: #ffbb3e;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #ffbb3e;
          border-color: #ffbb3e;
          color: #fff;
        }
      }
    }
    &.low {
      .modal-card-head {
        background: #67bf8e;
      }
      .modal-card-foot {
        background: #ededed;
        .button {
          background: #67bf8e;
          border-color: #67bf8e;
          color: #fff;
        }
      }
    }
    &.fullscreen {
      max-width: 1200px;
    }
  }
  

</style>

<template>
    <div class="site-wrapper">
        <div class="container">
            <div class="spacer s20"></div>

            <h1>OffshoreSMS Go!</h1>

            <h3>A QR code portal that gives you direct access to important safety information on an OffshoreSMS vessel.</h3>

            <!-- <p>{{ userData }}</p> -->

            <div class="spacer s30"></div>

            <div>
                <!-- ============================================================================== -->

                <a href="http://offshoresms.com.au" class="button is-medium is-primary">Visit OffshoreSMS</a>

                <div class="download centered">
                    <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank"
                        ><img class="partners" src="@/assets/app-store.png"
                    /></a>
                    <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank"
                        ><img class="partners" src="@/assets/play-store.png"
                    /></a>
                </div>

                <!-- ============================================================================== -->
            </div>
            <div>v1.2.1</div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, doc, onSnapshot, setDoc, getDoc } from 'firebase/firestore';

export default {
    mounted: function () {},

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        }
    },

    data: function () {
        return {};
    },

    methods: {
        toPage(page) {},

        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
}

.download {
    margin: 2rem auto;
    max-width: 200px;
}
</style>

<template>
    <div class="">
        <div class="container">
            <div v-if="checkingDetails || loadingSavedData">
                <div class="spacer s30"></div>
                <h3>
                    <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>&nbsp; {{ status }}
                </h3>
            </div>

            <!-- {{ formMeta }} -->
            <!-- <pre v-if="formMeta.sharedContent">{{ formMeta.sharedContent.map((m) => m.label) }}</pre> -->

            <div class="spacer s30 desktop"></div>

            <div v-if="formLoaded" class="columns">
                <div v-if="accessAllowed" class="column">
                    <!-- {{ formMeta }} -->
                    <div v-if="showLogo" class="fleet-logo">
                        <img :src="fleetData.newFleetLogo" alt="" />
                    </div>
                    <h3>Safety Management System for {{ currentVesselName }}</h3>
                    <h5>Timestamp: {{ simpleDate(new Date(), true, true) }}</h5>
                    <div v-if="formMeta.hasExpiryDate">
                        <div v-if="formMeta.isCurrent" class="date green bold">
                            Valid for {{ timeLeft(formMeta.expiryDate.toDate()) }} {{ timeLeft(formMeta.expiryDate.toDate()) == 1 ? 'day' : 'days' }}
                        </div>
                        <div v-else class="red bold">Expired</div>
                    </div>
                    <!-- <hr /> -->
                    <!-- {{ displaySections.map((m) => m.title) }} -->
                    <div class="spacer s30"></div>
                    <div class="sms-content">
                        <!-- <div class="sms-section box" v-for="(section, idx) in displaySections" :key="`section-${idx}`">
                            {{ section.title }}
                        </div> -->
                        <div class="sms-section box" v-for="(section, idx) in sorted(displaySections)" :key="`section-${idx}`">
                            <!-- text/para content ================================= -->
                            <div v-if="section.type == 'para'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <div>
                                    <p v-for="(line, icx) in section.content.split('\n')" :key="`line-1-${icx}`">{{ line }}</p>
                                </div>
                            </div>

                            <!-- photo content ================================= -->
                            <div v-if="section.type == 'photo'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4
                                    style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px"
                                    class="hideForPrint">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <div class="main-image">
                                    <img :src="section.content" />
                                </div>
                            </div>

                            <!-- 2 column table content ================================= -->
                            <div v-if="section.type == 'table'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <table style="border-spacing: 0 !important; table-layout: fixed" class="table is-fullwidth set-col">
                                    <tr
                                        v-for="(row, irx) in section.content"
                                        :key="`row-1-${irx}`"
                                        :class="{ hidden: !row.content || row.content == '' }">
                                        <td
                                            style="
                                                width: 30% !important;
                                                max-width: 280px !important;
                                                border-bottom: 1px solid #e1e1e1 !important;
                                                padding: 10px !important;
                                            ">
                                            {{ row.header }}
                                        </td>
                                        <td style="width: 70% !important; border-bottom: 1px solid #e1e1e1 !important; padding: 10px !important">
                                            <div v-if="row.content">
                                                <div v-if="row.content.length > 0 && row.content.split('\n') != undefined">
                                                    <p v-for="(line, idx) in row.content.split('\n')" :key="`line-2-${idx}`">{{ line }}</p>
                                                </div>
                                                <div v-else>
                                                    {{ row.content }}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <!-- single column table content ================================= -->
                            <div v-if="section.type == 'table-rows'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 12px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <table
                                    style="border-spacing: 0 !important; table-layout: fixed"
                                    v-for="(row, irx) in section.content"
                                    :key="`row-2-${irx}`"
                                    class="table is-fullwidth single-col"
                                    :class="{ hidden: !row.content || row.content == '' }">
                                    <tr class="first" style="background: #f9f9f9 !important; padding: 8px 10px !important">
                                        <td style="padding: 10px !important">{{ row.header }}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 10px !important">
                                            <div
                                                v-if="row.content"
                                                @click="
                                                    row.show = !row.show;
                                                    row.limit = row.show || showAllContent ? 10000 : 200;
                                                "
                                                class="toggle-content">
                                                <p v-for="(line, itx) in truncate(row.content, row.limit).split('\n')" :key="`line-4-${itx}`">
                                                    {{ line }}
                                                </p>
                                                <div class="toggler">Click to {{ !row.show ? 'Show' : 'Hide' }}</div>
                                            </div>
                                            <!-- <p v-for="(line, idx) in row.content.split('\n')" :key="`line-3-${idx}`">{{ line }}</p> -->
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <!-- special roles and statements table content ================================= -->
                            <div v-if="section.type == 'table-statements'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 12px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <div v-for="(row, irx) in section.content" :key="`row-3-${irx}`">
                                    <h5>{{ row.title }}</h5>
                                    <div v-if="row.statements.length > 0">
                                        <table
                                            style="border-spacing: 0 !important; table-layout: fixed"
                                            v-for="(cell, idx) in row.statements"
                                            :key="`cell-${irx}-${idx}`"
                                            class="table is-fullwidth single-col">
                                            <tr class="first" style="background: #f9f9f9 !important; padding: 8px 10px !important">
                                                <td style="padding: 10px !important">{{ cell.title }}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 10px !important">
                                                    <div
                                                        v-if="cell.details"
                                                        @click="
                                                            cell.show = !cell.show;
                                                            cell.limit = cell.show || showAllContent ? 10000 : 200;
                                                        "
                                                        class="toggle-content">
                                                        <p
                                                            v-for="(line, itx) in truncate(cell.details, cell.limit).split('\n')"
                                                            :key="`line-4-${itx}`">
                                                            {{ line }}
                                                        </p>
                                                        <div class="toggler">Click to {{ !cell.show ? 'Show' : 'Hide' }}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-else>No role statement.</div>
                                </div>
                            </div>

                            <!-- plant & equipment content ================================= -->
                            <div v-if="section.type == 'plant'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <div>
                                    <table
                                        style="border-spacing: 0 !important; table-layout: fixed"
                                        v-for="(row, idx) in sorted(section.content)"
                                        :key="`plant-1-${idx}`"
                                        class="table is-bordered is-fullwidth set-col">
                                        <tbody>
                                            <tr>
                                                <td
                                                    class="bold"
                                                    style="
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    P&E Item
                                                </td>
                                                <td
                                                    class="bold"
                                                    style="
                                                        width: 70% !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    {{ row.header }}
                                                </td>
                                            </tr>
                                            <tr v-for="(sched, isx) in row.content.maintenanceSchedules" :key="`plant-2-${isx}`">
                                                <td
                                                    style="
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    {{ sched.title }}
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    <div><font-awesome-icon icon="info-circle" />&nbsp; {{ sched.description }}</div>
                                                    <div v-if="sched.trackTime">
                                                        <font-awesome-icon icon="stopwatch" />&nbsp; Repeats every {{ sched.frequencyNumber }}
                                                        {{ sched.frequencyPeriod }}
                                                    </div>
                                                    <div v-if="sched.trackTime">
                                                        <font-awesome-icon icon="calendar-check" />&nbsp; Next schedule:
                                                        {{ simpleDate(sched.dueDate, false, false) }}
                                                        <!-- {{ sched.dueDate }} -->
                                                    </div>
                                                    <div v-if="sched.trackHours">
                                                        <font-awesome-icon icon="stopwatch" />&nbsp; Repeats every {{ sched.frequency }} Hours
                                                    </div>
                                                    <div v-if="sched.trackHours">
                                                        <font-awesome-icon icon="wrench" />&nbsp; Next schedule: {{ sched.dueHours }} Hours
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- drill content ================================= -->
                            <div v-if="section.type == 'drill'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <div>
                                    <table
                                        style="border-spacing: 0 !important; table-layout: fixed !important"
                                        v-for="(row, idx) in sorted(section.content)"
                                        :key="`drill-1-${idx}`"
                                        class="table is-bordered is-fullwidth set-col">
                                        <tbody>
                                            <tr>
                                                <td
                                                    class="bold"
                                                    style="
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Drill Title
                                                </td>
                                                <td
                                                    class="bold"
                                                    style="
                                                        width: 70% !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    {{ row.header }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Details
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-bottom: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    <div><font-awesome-icon icon="info-circle" />&nbsp; {{ row.content.description }}</div>
                                                    <div class="drill-freq">
                                                        <font-awesome-icon icon="stopwatch" />&nbsp; Repeats every {{ row.content.frequencyNumber }}
                                                        {{ row.content.frequencyPeriod }}
                                                    </div>
                                                    <div v-if="row.content.linkedSmsItems">
                                                        <div><font-awesome-icon icon="link" />&nbsp; Linked SMS Content:</div>
                                                        <div
                                                            class="linked-sms-items"
                                                            v-for="(bit, icx) in row.content.linkedSmsItems"
                                                            :key="`sms-item-${icx}`">
                                                            <div class="lsi-type">{{ bit.label }}</div>
                                                            <div class="lsi-title">{{ bit.title }}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- hazards content ================================= -->
                            <div v-if="section.type == 'hazards'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>

                                <div>
                                    <table
                                        style="border-spacing: 0 !important; table-layout: fixed"
                                        v-for="(hazard, idx) in sorted(section.content)"
                                        :key="`hazards-${idx}`"
                                        class="table is-bordered is-fullwidth set-col">
                                        <tbody>
                                            <tr>
                                                <td
                                                    class="bold"
                                                    style="
                                                        border-collapse: collapse;
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Hazard
                                                </td>
                                                <td
                                                    class="bold"
                                                    style="
                                                        width: 70% !important;
                                                        border-collapse: collapse;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    {{ hazard.title }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
                                                        border-collapse: collapse;
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Risks & Controls
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-collapse: collapse;
                                                        border: 1px solid #e1e1e1 !important;
                                                        border-collapse: collapse !important;
                                                        padding: 10px !important;
                                                    ">
                                                    <div
                                                        v-if="hazard.riskPairs && hazard.riskPairs.length > 0"
                                                        class="toggle-content"
                                                        :class="{ show: hazard.show || showAllContent }"
                                                        @click="
                                                            hazard.show = !hazard.show;
                                                            hazard.limit = hazard.show || showAllContent ? 10000 : 200;
                                                        ">
                                                        <div v-for="(item, idx) in hazard.riskPairs" :key="`riskpair-${idx}`">
                                                            <div
                                                                class="risk-pair"
                                                                v-if="(item.risk && item.risk != '') || (item.control && item.control != '')">
                                                                <div v-if="item.risk">
                                                                    <p class="rp-title red">Risk</p>
                                                                    <p
                                                                        class="rp-details"
                                                                        v-for="(line, irx) in truncate(item.risk, hazard.limit).split('\n')"
                                                                        :key="`line1-${irx}`">
                                                                        {{ line }}<br />
                                                                    </p>
                                                                </div>
                                                                <div v-if="item.control">
                                                                    <p class="rp-title green">Control</p>
                                                                    <p
                                                                        class="rp-details"
                                                                        v-for="(line, irx) in truncate(item.control, hazard.limit).split('\n')"
                                                                        :key="`line1-${irx}`">
                                                                        {{ line }}<br />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="toggler">Click to {{ !hazard.show ? 'Show' : 'Hide' }}</div>
                                                    </div>
                                                    <div
                                                        v-if="hazard.riskPairs && hazard.riskPairs.length == 0"
                                                        class="toggle-content"
                                                        :class="{ show: hazard.show || showAllContent }"
                                                        @click="
                                                            hazard.show = !hazard.show;
                                                            hazard.limit = hazard.show || showAllContent ? 10000 : 200;
                                                        ">
                                                        <div v-if="hazard.risks && hazard.risks != ''" class="legacy-set">
                                                            <p class="bold red">Risks:</p>
                                                            <p
                                                                v-for="(line, itx) in truncate(hazard.risks, hazard.limit).split('\n')"
                                                                :key="`line-5-${itx}`">
                                                                {{ line }}<br />
                                                            </p>
                                                        </div>
                                                        <div v-if="hazard.controls && hazard.controls != ''" class="legacy-set">
                                                            <p class="bold green">Controls:</p>
                                                            <p
                                                                v-for="(line, ivx) in truncate(hazard.controls, hazard.limit).split('\n')"
                                                                :key="`line-6-${ivx}`">
                                                                {{ line }}<br />
                                                            </p>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                hazard.risks &&
                                                                hazard.controls &&
                                                                (hazard.risks.length > hazard.limit || hazard.controls.length > hazard.limit)
                                                            "
                                                            class="toggler">
                                                            Click to {{ !hazard.show ? 'Show' : 'Hide' }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
                                                        border-collapse: collapse;
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Likelihood / Consequence
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-collapse: collapse;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    <span>
                                                        {{ hazard.newLikelihood ? hazard.newLikelihood.label : '' }} /
                                                        {{ hazard.newConsequence ? hazard.newConsequence.label : '' }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
                                                        border-collapse: collapse;
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Residual Risk Rating
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-collapse: collapse;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                        text-transform: uppercase;
                                                    "
                                                    class="risk-rating"
                                                    :style="{
                                                        'background-color':
                                                            hazard.newRating == 'high' ? 'red' : hazard.newRating == 'medium' ? 'orange' : 'green'
                                                    }">
                                                    {{ hazard.newRating }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="
                                                        border-collapse: collapse;
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Responsibility
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-collapse: collapse;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    {{ responsibleList(hazard.personResponsible) }}
                                                </td>
                                            </tr>
                                            <tr v-if="hazard.uploadUrls && hazard.uploadUrls.length > 0">
                                                <td
                                                    style="
                                                        border-collapse: collapse;
                                                        width: 30% !important;
                                                        max-width: 280px !important;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    Pictures
                                                </td>
                                                <td
                                                    style="
                                                        width: 70% !important;
                                                        border-collapse: collapse;
                                                        border: 1px solid #e1e1e1 !important;
                                                        padding: 10px !important;
                                                    ">
                                                    <div class="spacer s30"></div>
                                                    <div v-if="!hideImages" class="image-div">
                                                        <ul class="uploads">
                                                            <li v-for="image in hazard.uploadUrls" :key="image.id">
                                                                <div class="image-wrap" v-if="!hideAllImages">
                                                                    <img :src="image" />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="hazard.versioned">
                                            <td>Last Edit</td>
                                            <td>
                                                <div>{{ simpleDateimpleDate(hazard.versionDate, false, true) }} ({{ hazard.savedByName }})</div>
                                                <div class="spacer s10"></div>
                                            </td>
                                        </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- general collection content ================================= -->
                            <div v-if="section.type == 'collection'">
                                <a :id="'section-' + section.index" class="anchor"></a>
                                <h4 style="color: cadetblue; text-transform: uppercase; font-weight: bold; font-size: 1.1rem; margin-bottom: 8px">
                                    <font-awesome-icon :icon="section.icon" /> &nbsp; {{ section.title }}
                                </h4>
                                <table class="table is-fullwidth set-col" style="border-spacing: 0 !important; table-layout: fixed">
                                    <tr v-for="(row, idx) in sorted(section.content)" :key="`row-4-${idx}`">
                                        <td
                                            style="
                                                width: 30% !important;
                                                max-width: 280px !important;
                                                border-bottom: 1px solid #e1e1e1 !important;
                                                padding: 10px;
                                            ">
                                            {{ row.title }}
                                        </td>
                                        <td style="width: 70% !important; border-bottom: 1px solid #e1e1e1 !important; padding: 10px">
                                            <div
                                                class="toggle-content"
                                                :class="{ show: row.show || showAllContent }"
                                                @click="
                                                    row.show = !row.show;
                                                    row.limit = row.show || showAllContent ? 10000 : 200;
                                                ">
                                                <div v-if="row.details">
                                                    <p v-for="(line, idx) in truncate(row.details, row.limit).split('\n')" :key="`line-4-${idx}`">
                                                        {{ line }}
                                                    </p>
                                                    <!-- <div>{{ truncate(row.details, row.limit) }}</div> -->
                                                </div>
                                                <div v-if="row.details.length > row.limit" class="toggler">
                                                    Click to {{ !row.show ? 'Show' : 'Hide' }}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="buttons hideForPrint">
                        <span class="button is-light" @click="printPDF()">Print PDF</span>
                        <span class="button is-light" @click="savePDFEndpoint()">Print PDF Endpoint</span>
                    </div>
                    <span class="hideForPrint" v-if="generatingPDF">
                        <font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp; Preparing PDF..
                    </span> -->
                    <div class="floaters hideForPrint">
                        <div class="expand-icon" @click="showAllContent = !showAllContent">
                            <font-awesome-icon :icon="showAllContent ? 'chevron-up' : 'chevron-down'" />
                        </div>
                        <div class="print-icon" :class="{ loading: generatingPDF }" @click="savePDFEndpoint()">
                            <span v-if="generatingPDF"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
                            <span v-else><font-awesome-icon icon="print" /></span>
                        </div>
                        <div class="notes-icon" @click="showNotes = true"><font-awesome-icon icon="info-circle" /></div>
                        <div class="contents-icon" @click="showContents = true"><font-awesome-icon icon="list-ul" /></div>
                    </div>
                </div>
                <div v-else class="column">
                    <div class="spacer s30"></div>
                    {{ formMessage }}
                </div>
            </div>
            <div v-else class="columns">
                <div class="spacer s30"></div>
                {{ errorMessage }}
            </div>

            <div v-if="formLoaded && accessAllowed" class="buttons floating hideForPrint">
                <!-- <span class="button is-medium is-primary" @click="printPDF()"> <font-awesome-icon icon="plus" /> &nbsp; Print? </span> -->
            </div>

            <div class="modal modal-fx-fadeInScale hideForPrint" v-bind:class="{ 'is-active': showContents }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-primary">
                        <p class="modal-card-title">Contents</p>
                    </header>
                    <section class="modal-card-body">
                        <ul class="contents">
                            <li v-for="(item, idx) in contents" :key="`li-${idx}`" @click="showContents = false">
                                <a :href="'#section-' + item.index" v-smooth-scroll="{ offset: -100 }" class="">
                                    <font-awesome-icon :icon="item.icon" /> &nbsp; {{ item.title }}
                                </a>
                            </li>
                        </ul>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-primary" @click="close">Close</button>
                    </footer>
                </div>
            </div>

            <div class="modal modal-fx-fadeInScale hideForPrint" v-bind:class="{ 'is-active': showNotes }">
                <div class="modal-background" @click="close"></div>
                <div class="modal-card">
                    <header class="modal-card-head is-primary">
                        <p class="modal-card-title">Important Notes</p>
                    </header>
                    <section class="modal-card-body">
                        {{ formMeta.notes }}
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-primary" @click="close">Close</button>
                    </footer>
                </div>
            </div>

            <div class="spacer s100"></div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, where, doc, onSnapshot, getDocs, getDoc, orderBy, limit } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { confirmPasswordReset } from 'firebase/auth';
import axios from 'axios';
var FileSaver = require('file-saver');

const functions = getFunctions();
const cloudFunctionSendMultipleEmails = httpsCallable(functions, 'cloudFunctionSendMultipleEmails');

export default {
    props: {
        webLink: String
    },

    mounted() {
        let hasParam = this.$route.query && this.$route.query.x;
        if (hasParam) {
            this.tokenID = this.$route.query.x ? this.$route.query.x : '';
        } else {
            this.tokenID = this.webLink ? this.webLink : ''; // ?
        }
        let self = this;
        if (this.tokenID != '') {
            this.checkCodeExists();
        } else {
            setTimeout(() => {
                self.checkingDetails = false;
                self.status = 'No access token found.';
                self.errorMessage = 'No access token found.';
            }, 1000);
        }
        // this.getIP();
        this.getIPFromAmazon();
    },

    components: {},

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userID() {
            return this.userData.userid;
        },
        userFirstName() {
            return this.userData.username.split(' ')[0];
        },
        contents() {
            return this.displaySections.map((m) => {
                return {
                    index: m.index,
                    title: m.title,
                    icon: m.icon
                };
            });
        }
    },

    data: function () {
        return {
            currentWebLink: '',
            checkingDetails: true,
            formExists: false,
            loadingSavedData: false,
            ipAddress: '',
            hasData: false,
            status: 'Checking if content exists..',
            formMessage: '',
            formLoaded: false,
            errorMessage: '',
            formMeta: {},
            formData: {},
            tokenID: '',
            accessAllowed: false,
            currentVesselID: '',
            currentVesselName: '',
            vesselOwnerID: '',
            errorMsg: '',
            displaySections: [],
            vesselData: {},
            fleetData: {},
            hideAllImages: false,
            showNotes: false,
            showContents: false,
            ownerRows: [
                { label: 'Vessel Owner', field: 'vesselOwnerName' },
                { label: 'Owner Contact Number', field: 'vesselOwnerPhone' },
                { label: 'Owner Email Address', field: 'vesselOwnerEmail' }
                // { label: 'Contacts', field: 'companyContacts' }
            ],
            statementRows: [
                { label: 'Owner Responsibility Statement', field: 'ownerStatement' },
                { label: 'Designated Person Responsibility Statement', field: 'designatedStatement' },
                { label: 'General Statement', field: 'customStatement' },
                { label: 'Line of Communication Statement', field: 'lineOfComms' }
            ],
            overviewRows: [
                { label: 'Vessel Name', field: 'vesselName' },
                { label: 'Vessel Identifier', field: 'vesselUVI' },
                { label: 'Description', field: 'vesselDescription' },
                { label: 'Location', field: 'homePort' },
                { label: 'Class Information', field: 'vesselClass' },
                { label: 'Operational Area', field: 'vesselOperationalArea' },
                { label: 'Propulsion', field: 'vesselPropulsion' },
                { label: 'Minimum Crew', field: 'vesselMinCrew' },
                { label: 'Appropriate Crew', field: 'vesselCrew' },
                { label: 'Crewing Explanation', field: 'appCrewExplanation' }
            ],
            assetRoles: [],
            fleetRoles: [],
            collections: {
                hazards: {
                    collection: 'smsHazards',
                    versions: 'smsHazardsVersions',
                    fleetCollection: 'companyHazards',
                    fleetVersions: 'companyHazardsVersions',
                    savedCollection: 'savedCompanyHazards',
                    isVersioned: true
                },
                policies: {
                    collection: 'smsPolicies',
                    versions: 'smsPoliciesVersions',
                    fleetCollection: 'companyPolicies',
                    fleetVersions: 'companyPoliciesVersions',
                    savedCollection: 'savedCompanyPolicies',
                    isVersioned: true
                },
                contacts: {
                    collection: 'smsContacts',
                    versions: 'smsContactsVersions',
                    fleetCollection: 'companyContacts',
                    fleetVersions: 'companyContactsVersions',
                    savedCollection: 'savedCompanyContacts',
                    isVersioned: true
                },
                sops: {
                    collection: 'smsSOPS',
                    versions: 'smsSOPSVersions',
                    fleetCollection: 'companySOPS',
                    fleetVersions: 'companySOPSVersions',
                    savedCollection: 'savedCompanySOPS',
                    isVersioned: true
                },
                eops: {
                    collection: 'smsEOPS',
                    versions: 'smsEOPSVersions',
                    fleetCollection: 'companyEOPS',
                    fleetVersions: 'companyEOPSVersions',
                    savedCollection: 'savedCompanyEOPS',
                    isVersioned: true
                },
                startups: {
                    collection: 'smsStartupItems',
                    versions: 'smsStartupItemsVersions',
                    fleetCollection: 'companyStartupItems',
                    fleetVersions: 'companyStartupItemsVersions',
                    savedCollection: 'savedCompanyStartupItems',
                    isVersioned: false
                },
                shutdowns: {
                    collection: 'smsShutdownItems',
                    versions: 'smsShutdownItemsVersions',
                    fleetCollection: 'companyShutdownItems',
                    fleetVersions: 'companyShutdownItemsVersions',
                    savedCollection: 'savedCompanyShutdownItems',
                    isVersioned: false
                },
                maintenance: {
                    collection: 'smsMaintenanceItems',
                    versions: 'smsMaintenanceItemsVersions',
                    fleetCollection: 'companyMaintenanceItems',
                    fleetVersions: 'companyMaintenanceItemsVersions',
                    savedCollection: 'savedCompanyMaintenanceItems',
                    isVersioned: false
                },
                inductions: {
                    collection: 'smsVesselInductionItems',
                    versions: 'smsVesselInductionItemsVersions',
                    fleetCollection: 'companyInductionItems',
                    fleetVersions: 'companyInductionItemsVersions',
                    savedCollection: 'savedCompanyInductionItems',
                    isVersioned: false
                },
                briefings: {
                    collection: 'smsPassengerInductionItems',
                    versions: 'smsPassengerInductionItemsVersions',
                    fleetCollection: 'companyPassengerInductionItems',
                    fleetVersions: 'companyPassengerInductionItemsVersions',
                    savedCollection: 'savedCompanyPassengerInductionItems',
                    isVersioned: false
                },
                statements: {
                    collection: 'smsAssetStatements',
                    versions: 'smsAssetStatementsVersions',
                    fleetCollection: 'fleetStatements',
                    fleetVersions: 'fleetStatementsVersions',
                    savedCollection: 'savedCompanyAssetStatements',
                    isVersioned: true
                },
                'general-statements': {
                    collection: 'smsAssetStatements',
                    versions: 'smsAssetStatementsVersions',
                    fleetCollection: 'fleetStatements',
                    fleetVersions: 'fleetStatementsVersions',
                    savedCollection: 'savedCompanyAssetStatements',
                    isVersioned: true
                }
            },
            allPlant: [],
            allMaintenanceSchedulesMap: {},
            allDrills: [],
            showLogo: false,
            generatingPDF: false,
            buttonText: 'Generate PDF',
            showAllContent: false,
            hideImages: false,
            vesselIsSimple: false
        };
    },

    methods: {
        close() {
            this.errorMsg = '';
            this.showNotes = false;
            this.showContents = false;
        },

        sorted(arr) {
            // Set slice() to avoid to generate an infinite loop!
            return arr.slice().sort(function (a, b) {
                return a.index - b.index;
            });
        },

        truncate(string, limit) {
            if (string == undefined || string == '') return '';
            return this.showAllContent ? string : string.length > limit ? string.slice(0, limit) + '...' : string;
        },

        responsibleList(array) {
            if (array.length == 0 || array == undefined) return;
            return Array.isArray(array) ? array.join(', ') : array;
        },

        simpleDate(sentDate, isDate, full) {
            if (typeof sentDate == 'string' || typeof sentDate == 'number') return sentDate;
            const longOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            const shortOptions = { month: 'short', day: 'numeric', year: 'numeric' };
            const dateOptions = full ? longOptions : shortOptions;
            if (!sentDate || sentDate == '') return '';
            // let realDate = !isNaN(new Date(sentDate)) ? sentDate : new Date(sentDate.toDate());
            // console.log(realDate);
            // let notDate = isDate ? !isNaN(new Date(sentDate)) : !isNaN(new Date(sentDate.toDate()));
            // console.log('notDate', notDate);
            // return realDate.toLocaleDateString('en-US', dateOptions);
            return isDate ? sentDate.toLocaleDateString('en-US', dateOptions) : sentDate.toDate().toLocaleDateString('en-US', dateOptions);
        },

        timeLeft(expiryDate) {
            let now = new Date().getTime();
            let t = new Date(expiryDate).getTime();
            let diff = Math.round((t - now) / (1000 * 60 * 60 * 24));
            return diff;
        },

        async getIPFromAmazon() {
            fetch('https://checkip.amazonaws.com/')
                .then((res) => res.text())
                .then((data) => {
                    // console.log('AWS', data);
                    this.ipAddress = data;
                });
        },

        async checkCodeExists() {
            this.errorMessage = '';
            let self = this;

            const docRef = doc(db, 'vesselSharedTokens', this.tokenID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let data = docSnap.data();
                data.isCurrent = docSnap.data().hasExpiryDate ? docSnap.data().expiryDate.toDate().getTime() > new Date().getTime() : true;
                data.archived = docSnap.data().archived ? docSnap.data().archived : false;
                self.formMeta = data;
                if (docSnap.data().vesselID) {
                    let vesselID = docSnap.data().vesselID;
                    self.currentVesselName = docSnap.data().vesselName;
                    self.currentVesselID = docSnap.data().vesselID;
                    self.$store.dispatch('setCurrentVessel', { vesselID });
                }
                let logMeta = self.formMeta;
                self.$store.dispatch('setCurrentTokenMeta', { logMeta });
                setTimeout(() => {
                    self.status = 'Checking status...';
                    self.formExists = true;
                    self.loadingSavedData = true;
                    self.checkingDetails = false;
                    self.loadFormData();
                }, 500);
            } else {
                self.checkingDetails = false;
                self.status = 'This content could not be loaded.';
                self.errorMessage = 'No content found.';
            }
        },

        loadFormData() {
            let self = this;
            if (this.formMeta.sharedToken && this.formMeta.sharedToken != '') {
                self.hasData = true;
                self.status = 'Loading shared content...';
                setTimeout(() => {
                    self.loadVesselData();
                }, 500);
            } else {
                this.formMessage = 'Unable to load content, sorry.';
                this.loadingSavedData = false;
                this.formLoaded = true;
            }
        },

        async loadVesselData() {
            let self = this;
            const docRef = doc(db, 'vessels', this.currentVesselID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.vesselData = docSnap.data();
                this.vesselOwnerID = docSnap.data().vesselOwnerID ? docSnap.data().vesselOwnerID : '';
                let aaaPlans = docSnap.data().aaaPlans ? docSnap.data().aaaPlans : [];
                this.vesselIsSimple = aaaPlans.includes('sms') && !aaaPlans.includes('pro');
                setTimeout(() => {
                    self.loadFleetData();
                }, 500);
            } else {
                this.formMessage = 'Unable to load this page.';
                this.loadingSavedData = false;
                this.formLoaded = true;
            }
        },

        async loadFleetData() {
            let self = this;
            const docRef = doc(db, 'companies', this.vesselOwnerID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.fleetData = docSnap.data();
                this.loadSectionData();
            } else {
                this.formMessage = 'Unable to load this page.';
                this.loadingSavedData = false;
                this.formLoaded = true;
            }
        },

        async loadSectionData() {
            let self = this;
            let isCurrent = this.formMeta.hasExpiryDate ? this.formMeta.expiryDate.toDate().getTime() > new Date().getTime() : true;
            let archived = this.formMeta.archived ? this.formMeta.archived : false;
            // console.log(archived);

            if (isCurrent && !archived) {
                await Promise.all(
                    this.formMeta.sharedContent
                        .filter((k) => k.checked)
                        .map(async (item, index) => {
                            // console.log('=====================', item.slug);
                            if (item.slug == 'logo') {
                                self.showLogo = true;
                                // self.displaySections.push({
                                //     type: 'photo',
                                //     content: self.fleetData.newFleetLogo ? self.fleetData.newFleetLogo : '',
                                //     title: '',
                                //     icon: item.icon,
                                //     index: index
                                // });
                            }
                            if (item.slug == 'intro') {
                                self.displaySections.push({
                                    type: 'para',
                                    content: self.vesselData.snapshotIntro ? self.vesselData.snapshotIntro : '',
                                    title: 'About this Safety Management System',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.slug == 'photo') {
                                self.displaySections.push({
                                    type: 'photo',
                                    content: self.vesselData.avatarUrl ? self.vesselData.avatarUrl : '',
                                    title: 'Asset Photo',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.slug == 'owner-info') {
                                let tableRows = await self.getOwnerData();
                                self.displaySections.push({
                                    type: 'table',
                                    content: tableRows,
                                    title: 'Owner Information',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.slug == 'asset-overview') {
                                let tableRows = await self.getOverviewData();
                                self.displaySections.push({
                                    type: 'table',
                                    content: tableRows,
                                    title: 'Asset Overview',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            // new-statements
                            // if (item.slug == 'general-statements') {
                            //     let tableRows = await self.getStatementData();
                            //     self.displaySections.push({
                            //         type: 'table-rows',
                            //         content: tableRows,
                            //         title: 'Responsibility Statements',
                            //         icon: item.icon,
                            //         index: index
                            //     });
                            // }
                            if (item.slug == 'asset-roles') {
                                let tableRows = await self.getRoleData();
                                // console.log('tableRows', tableRows);
                                self.displaySections.push({
                                    type: 'table-statements',
                                    content: tableRows,
                                    title: 'Team Roles',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.type == 'hazards') {
                                let content = await self.getCollectionData('hazards');
                                self.displaySections.push({
                                    type: 'hazards',
                                    content: content,
                                    title: 'Hazards / Risk Assessments',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.type == 'collection') {
                                let content = await self.getCollectionData(item.slug);
                                self.displaySections.push({
                                    type: 'collection',
                                    content: content,
                                    title: item.label,
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.type == 'plant') {
                                let content = await self.getPlantData();
                                self.displaySections.push({
                                    type: 'plant',
                                    content: content,
                                    title: 'Plant & Equipment Register',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                            if (item.type == 'drill') {
                                let content = await self.getDrillData();
                                self.displaySections.push({
                                    type: 'drill',
                                    content: content,
                                    title: 'Drills & Training Schedules',
                                    icon: item.icon,
                                    index: index
                                });
                            }
                        })
                );
                this.loadingSavedData = false;
                this.accessAllowed = true;
                this.formLoaded = true;
            } else {
                // some error from loading the content
                this.formMessage = 'This content has expired, sorry.';
                this.loadingSavedData = false;
                this.formLoaded = true;
            }
        },

        async getOwnerData() {
            let self = this;
            let rows = [];
            await Promise.all(
                this.ownerRows.map(async (row) => {
                    rows.push({
                        header: row.label,
                        content: self.vesselData[row.field] ? self.vesselData[row.field] : ''
                    });
                })
            );
            return rows;
        },

        async getOverviewData() {
            let self = this;
            let rows = [];
            let customFields = this.vesselData.customFields ? this.vesselData.customFields.filter((m) => m.isPublic) : [];
            await Promise.all(
                this.overviewRows.map(async (row) => {
                    rows.push({
                        header: row.label,
                        content: self.vesselData[row.field] ? self.vesselData[row.field] : ''
                    });
                })
            );
            await Promise.all(
                customFields.map(async (field) => {
                    rows.push({
                        header: field.label,
                        content: field.value
                    });
                })
            );
            return rows;
        },

        async getStatementData() {
            let self = this;
            let rows = [];
            await Promise.all(
                this.statementRows.map(async (row) => {
                    rows.push({
                        header: row.label,
                        content: self.vesselData[row.field] ? self.vesselData[row.field] : '',
                        show: false,
                        limit: 200
                    });
                })
            );
            return rows;
        },

        async getAssetRoleStatements(roleID) {
            const stmts = collection(db, 'vessels/' + this.currentVesselID + '/assetRoles/' + roleID + '/roleStatements');
            const q = query(stmts, where('archived', '==', false));
            const snap = await getDocs(q);
            let statements = [];
            await Promise.all(
                snap.docs.map(async (doc, index) => {
                    statements.push({
                        title: doc.data().title,
                        details: doc.data().details,
                        show: false,
                        limit: 200
                    });
                })
            );
            return statements;
        },

        async getFleetRoleStatements(roleID) {
            // console.log('roleID', roleID);
            const stmts = collection(db, 'companies/' + this.vesselOwnerID + '/fleetRoles/' + roleID + '/roleStatements');
            const q = query(stmts, where('archived', '==', false));
            const snap = await getDocs(q);
            let statements = [];
            await Promise.all(
                snap.docs.map(async (doc, index) => {
                    statements.push({
                        title: doc.data().title,
                        details: doc.data().details,
                        show: false,
                        limit: 200
                    });
                })
            );
            return statements;
        },

        async getFleetRole(roleID) {
            let self = this;
            // console.log('roleID', roleID);
            const docRef = doc(db, 'companies/' + this.vesselOwnerID + '/fleetRoles', roleID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                let statements = await self.getFleetRoleStatements(roleID);
                return {
                    id: docSnap.id,
                    title: docSnap.data().title,
                    statements: statements,
                    isFleetRole: true
                };
            } else {
                return '';
            }
        },

        async getRoleData() {
            let self = this;
            let rows = [];

            const aRoles = collection(db, 'vessels/' + this.currentVesselID + '/assetRoles');
            const q1 = query(aRoles, where('archived', '==', false));
            const snapOne = await getDocs(q1);
            await Promise.all(
                snapOne.docs.map(async (doc, index) => {
                    let statements = await self.getAssetRoleStatements(doc.id);
                    self.assetRoles.push({
                        id: doc.id,
                        title: doc.data().title,
                        statements: statements,
                        isFleetRole: false
                    });
                })
            );
            rows.push(...self.assetRoles);

            const linkedRoles = collection(db, 'vessels/' + this.currentVesselID + '/linkedFleetRoles');
            const q2 = query(linkedRoles);
            const snapTwo = await getDocs(q2);
            let linkedFleetRoles = [];
            await Promise.all(
                snapTwo.docs.map(async (doc, index) => {
                    linkedFleetRoles.push(doc.id);
                })
            );
            // console.log('linkedFleetRoles', linkedFleetRoles);

            await Promise.all(
                linkedFleetRoles.map(async (roleID) => {
                    let roleData = await self.getFleetRole(roleID);
                    // console.log('roleData', roleData);
                    if (roleData != '') self.fleetRoles.push(roleData);
                })
            );
            rows.push(...self.fleetRoles);
            return rows;
        },

        async getPlantData() {
            let self = this;
            let rows = [];

            const schedsRef = collection(db, 'vessels/' + this.currentVesselID + '/vesselPlantSchedules');
            const q1 = query(schedsRef, where('archived', '==', false));
            const snapOne = await getDocs(q1);
            // let scheds = [];
            await Promise.all(
                snapOne.docs.map(async (doc, index) => {
                    let data = doc.data();
                    let assetID = doc.data().assetID ? doc.data().assetID : '';
                    data.assetID = assetID;
                    data.id = doc.id;
                    // scheds.push(data);
                    if (assetID != '') {
                        if (self.allMaintenanceSchedulesMap[assetID]) {
                            self.allMaintenanceSchedulesMap[assetID].push(data);
                        } else {
                            self.allMaintenanceSchedulesMap[assetID] = [data];
                        }
                    }
                })
            );

            const plantRef = collection(db, 'vessels/' + this.currentVesselID + '/vesselPlantAndEquipment');
            const q2 = query(plantRef, where('archived', '==', false));
            const snapTwo = await getDocs(q2);
            await Promise.all(
                snapTwo.docs.map(async (doc, index) => {
                    let data = doc.data();
                    data.id = doc.id;
                    data.maintenanceSchedules = self.allMaintenanceSchedulesMap[doc.id];
                    self.allPlant.push({
                        header: data.title ? data.title : 'Miscellaneous plant',
                        content: data
                    });
                })
            );

            rows.push(...self.allPlant);
            return rows;
        },

        async getDrillData() {
            let self = this;
            let rows = [];

            const drillRef = collection(db, 'vessels/' + this.currentVesselID + '/vesselSchedules');
            const q = query(drillRef, where('archived', '==', false));
            const snap = await getDocs(q);
            await Promise.all(
                snap.docs.map(async (doc, index) => {
                    let data = doc.data();
                    data.id = doc.id;
                    self.allDrills.push({
                        header: data.title ? data.title : 'Miscellaneous drill',
                        content: data
                    });
                })
            );

            rows.push(...self.allDrills);
            return rows;
        },

        async getCollectionData(sentColl) {
            console.log('sentColl', sentColl);
            let self = this;
            let rows = [];

            const assetItems = collection(db, 'vessels', this.currentVesselID, this.collections[sentColl].collection);
            // console.log('assetItems', assetItems.path);
            const snap = await getDocs(query(assetItems, orderBy('index', 'asc')));
            await Promise.all(
                snap.docs.map(async (docA, index) => {
                    let loadVersion = docA.data().currentVersionID ? true : false;
                    let archived = docA.data().archived ? docA.data().archived : false;
                    if (loadVersion && !archived) {
                        const docRef = doc(
                            db,
                            'vessels',
                            self.currentVesselID,
                            self.collections[sentColl].collection,
                            docA.id,
                            self.collections[sentColl].versions,
                            docA.data().currentVersionID
                        );
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            let data = docSnap.data();
                            data.id = docSnap.id;
                            if (sentColl == 'hazards') data.riskPairs = docSnap.data().riskPairs ? docSnap.data().riskPairs : [];
                            data.index = docSnap.data().index ? docSnap.data().index : 99;
                            data.show = false;
                            data.limit = 200;
                            rows.push(data);
                        }
                    } else {
                        if (!archived) {
                            let data = docA.data();
                            if (sentColl == 'hazards') data.riskPairs = docA.data().riskPairs ? docA.data().riskPairs : [];
                            data.index = docA.data().index ? docA.data().index : 99;
                            data.show = false;
                            data.limit = 200;
                            rows.push(data);
                        }
                    }
                })
            );

            let savedCollection = this.vesselData[this.collections[sentColl].savedCollection]
                ? this.vesselData[this.collections[sentColl].savedCollection]
                : [];

            await Promise.all(
                savedCollection.map(async (item) => {
                    const docRefTwo = doc(db, 'companies', self.vesselOwnerID, self.collections[sentColl].fleetCollection, item);
                    const docSnap2 = await getDoc(docRefTwo);
                    if (docSnap2.exists()) {
                        let loadVersion = docSnap2.data().currentVersionID ? true : false;
                        let archived = docSnap2.data().archived ? docSnap2.data().archived : false;
                        if (loadVersion && !archived) {
                            const docRef3 = doc(
                                db,
                                'companies',
                                self.vesselOwnerID,
                                self.collections[sentColl].fleetCollection,
                                item,
                                self.collections[sentColl].fleetVersions,
                                docSnap2.data().currentVersionID
                            );
                            const docSnap3 = await getDoc(docRef3);
                            if (docSnap3.exists()) {
                                let data = docSnap3.data();
                                data.id = docSnap3.id;
                                if (sentColl == 'hazards') data.riskPairs = docSnap3.data().riskPairs ? docSnap3.data().riskPairs : [];
                                data.index = docSnap3.data().index ? docSnap3.data().index : 99;
                                data.show = false;
                                data.limit = 200;
                                rows.push(data);
                            }
                        } else {
                            if (!archived) {
                                let data = docSnap2.data();
                                if (sentColl == 'hazards') data.riskPairs = docSnap2.data().riskPairs ? docSnap2.data().riskPairs : [];
                                data.index = docSnap2.data().index ? docSnap2.data().index : 99;
                                data.show = false;
                                data.limit = 200;
                                rows.push(data);
                            }
                        }
                    }
                })
            );
            return rows;
        },

        printPDF() {
            this.showAllContent = true;
            this.hideImages = true;
            this.generatingPDF = true;
            this.buttonText = 'Preparing PDF. Please wait...';

            var html = document.documentElement.outerHTML;
            var self = this;

            var css =
                'body {font-size: 13px !important;}\n\n.hideForPrint {display: none !important;}\n\n.main-fleet-content {padding: 1rem 15px !important; margin: 0 !important; width: 100% !important;}\n\n.image-div {display: none !important;}\n\n.box {box-shadow: none !important; border: none !important;}\n\n.header {display: none !important;}\n\nfooter {display: none !important;}\n\n.floaters {display: none !important;}\n\n.image-wrap img {display: none !important}\n\n.box {text-align: left !important; margin-bottom: 3rem !important; border: none !important; box-shadow: none !important; padding: 0 !important;}\n\n p {font-size: 13px !important; line-height: 1.5rem !important; font-weight: 400 !important; font-family: inherit !important; margin-bottom: 0 !important;}\n\n.toggle-content {max-height: none !important;}\n\nh4 {font-size: 1rem !important;font-family: Helvetica, Sans-Serif; !important;}\n\n.main-image {text-align: center !important; max-width: none !important;}\n\ntd {font-size: 13px !important;}\n\nth {font-size: 13px !important;}\n\n.container {max-width: none !important; padding: 1rem 0 !important;}\n\ndiv {font-family: Helvetica, Sans-Serif; !important; font-size: 13px !important; line-height: 16px !important;}\n\n.table tr.first td {width: 30% !important;}\n\n.table {padding-bottom: 20px !important; margin-bottom: 20px !important; width: 100% !important; table-layout: fixed !important;}\n\n.table td {vertical-align: top !important;}\n\n.sms-content .sms-section table.set-col tr td {width: 30% !important;}\n\n.toggler {display: none !important;}\n\n.bold {font-weight: bold !important;}\n\n.red {color: red !important;}\n\n.green {color: mediumseagreen !important;}';
            var data = {
                html: html,
                license: 'E197E92RdrKd',
                css: css,
                orientation: 'Portrait',
                top: 10,
                bottom: 5,
                left: 2,
                right: 2,
                width: 210,
                height: 297,
                unit: 'mm',
                toc: true
            };
            var serialized = Object.keys(data)
                .map(function (k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
                })
                .join('&');

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here

                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = self.currentVesselName + '.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    console.log('One');
                    this.generatingPDF = false;
                }
            };

            // This is the actual call to our API
            xhttp.open('POST', 'https://pdfmyurl.com/api', true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
        },

        // current PDf generator:
        async savePDFEndpoint() {
            // this.showAllContent = !this.showAllContent;
            // return;
            if (this.generatingPDF) return;
            this.showAllContent = true;
            this.hideImages = true;

            const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
            await sleep(2000);

            this.generatingPDF = true;
            this.buttonText = 'Preparing PDF. Please wait...';
            let html = document.documentElement.outerHTML;
            let self = this;

            try {
                const options = {
                    method: 'POST',
                    url: 'https://api.pdfendpoint.com/v1/convert',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer c870f041af1a45c8bb1444834679eb0354ef9e39e8'
                    },
                    data: {
                        // url: 'https://your_url.com',
                        html: html,
                        orientation: 'vertical',
                        page_size: 'A4',
                        zoom: '0.8',
                        css: 'body {font-size: 13px !important;}\n\n.hideForPrint {display: none !important;}\n\n.main-fleet-content {padding: 1rem 15px !important; margin: 0 !important; width: 100% !important;}\n\n.image-div {display: none !important;}\n\n.box {box-shadow: none !important; border: none !important;}\n\n.header {display: none !important;}\n\nfooter {display: none !important;}\n\n.floaters {display: none !important;}\n\n.image-wrap img {display: none !important}\n\n.box {text-align: left !important; margin-bottom: 3rem !important; border: none !important; box-shadow: none !important; padding: 0 !important;}\n\n p {font-size: 13px !important; line-height: 1.5rem !important; font-weight: 400 !important; font-family: inherit !important; margin-bottom: 0 !important;}\n\n.toggle-content {max-height: none !important;}\n\nh4 {font-size: 1rem !important;font-family: Helvetica, Sans-Serif; !important;}\n\n.main-image {text-align: center !important; max-width: none !important;}\n\ntd {font-size: 13px !important;}\n\nth {font-size: 13px !important;}\n\n.container {max-width: none !important; padding: 1rem 0 !important;}\n\ndiv {font-family: Helvetica, Sans-Serif; !important; font-size: 13px !important; line-height: 16px !important;}\n\n.table tr.first td {width: 30% !important;}\n\n.table {padding-bottom: 20px !important; margin-bottom: 20px !important; width: 100% !important; table-layout: fixed !important;}\n\n.table td {vertical-align: top !important;}\n\n.sms-content .sms-section table.set-col tr td {width: 30% !important;}\n\n.toggler {display: none !important;}\n\n.bold {font-weight: bold !important;}\n\n.red {color: red !important;}\n\n.green {color: mediumseagreen !important;}',
                        use_print_media: true,
                        wait_for_network: true,
                        margin_top: '1cm',
                        margin_bottom: '1cm',
                        margin_right: '5mm',
                        margin_left: '5mm',
                        delivery_mode: 'inline'
                    },
                    responseType: 'arraybuffer'
                };

                let response = await axios(options);
                // console.log('response', response);
                // fs.writeFileSync(`./test.pdf`, response.data);
                let blob = new Blob([response.data], {
                    type: 'application/octet-stream'
                });
                FileSaver.saveAs(blob, self.currentVesselName + '-snapshot.pdf');
                self.generatingPDF = false;
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 1400px !important;
    padding: 1rem 40px !important;
}
h3 {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 1.3rem;
    color: cadetblue;
}
h5 {
    font-weight: bold;
    margin: 1.6rem 0 1rem;
}
p {
    line-height: 1.65rem;
    margin-bottom: 7px;
}

.sms-content {
    .sms-section {
        text-align: left;
        margin-bottom: 2rem;
        h4 {
            color: cadetblue;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.1rem;
            margin-bottom: 8px;
        }
        .main-image {
            width: 100%;
            max-width: 600px;
        }
        table {
            margin-bottom: 2rem;
            &.single-col {
                tr {
                    th,
                    td {
                        width: 100%;
                    }
                    &.first {
                        background: #f9f9f9;
                        th,
                        td {
                            border-bottom: 0;
                        }
                    }
                }
            }
            &.set-col {
                tr {
                    td {
                        &:first-of-type {
                            width: 30%;
                        }
                        .toggle-content {
                            max-height: 100px;
                            overflow: hidden;
                            position: relative;
                            cursor: pointer;
                            .toggler {
                                position: absolute;
                                right: 10px;
                                top: 10px;
                                border-radius: 5px;
                                background: deepskyblue;
                                color: #fff;
                                padding: 3px 8px;
                                opacity: 0;
                                transition: 0.25s;
                                font-size: 12px;
                            }
                            &.show {
                                max-height: none;
                                overflow: visible;
                            }
                            &:hover {
                                .toggler {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            tr {
                &.hidden {
                    display: none;
                }
            }
            &.hidden {
                display: none;
            }
        }
        .drill-freq {
            margin: 10px 0;
        }
        .linked-sms-items {
            margin: 6px 0 10px 30px;
            .lsi-type {
                text-transform: uppercase;
                font-size: 0.8rem;
            }
            .lsi-title {
                font-weight: bold;
            }
        }
    }
}

.toggle-content {
    // max-height: 100px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .toggler {
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 5px;
        background: deepskyblue;
        color: #fff;
        padding: 3px 8px;
        opacity: 0;
        transition: 0.25s;
        font-size: 12px;
    }
    &.show {
        max-height: none;
        overflow: visible;
    }
    &:hover {
        .toggler {
            opacity: 1;
        }
    }
}
.modal .modal-card {
    max-height: 80%;
    max-height: 80vh;
}
.risk-rating {
    color: #fff;
    text-transform: uppercase;
}
.legacy-set {
    margin-bottom: 1.5rem;
}
.risk-pair {
    border-bottom: 1px dashed #e4e4e4;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    .rp-title {
        font-weight: bold;
    }
    .rp-details {
        margin-left: 10px;
        &:last-of-type {
            margin-bottom: 1rem;
        }
    }
}
.notes-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    background-color: cadetblue;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        transform: scale(1.1);
    }
}
.contents-icon {
    position: fixed;
    bottom: 90px;
    right: 20px;
    border-radius: 50%;
    background-color: blueviolet;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        transform: scale(1.1);
    }
}
.print-icon {
    display: none;
    position: fixed;
    bottom: 160px;
    right: 20px;
    border-radius: 50%;
    background-color: #c6c6c6;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.25s;
    max-height: 56px;
    &:hover {
        transform: scale(1.1);
    }
    &.loading {
        background-color: hotpink;
    }
}
.expand-icon {
    position: fixed;
    bottom: 230px;
    right: 20px;
    border-radius: 50%;
    background-color: orange;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        transform: scale(1.1);
    }
}
.contents {
    text-align: left;
    margin: 1rem 1rem;
    li {
        padding: 0.2rem 0;
        a {
            color: steelblue;
            font-size: 1.1rem;
            // transition: 0.25s;
        }
        &:hover {
            a {
                color: orangered;
            }
        }
    }
}
.modal-card-title {
    font-size: 1.2rem;
    margin: 0;
}
.fleet-logo {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
}

@media screen and (max-width: 599px) {
    table {
        &.table {
            tr {
                display: block;
                width: 100%;
                margin-bottom: 1rem;
                border: 1px solid #e4e4e4;
                th,
                td {
                    width: 100% !important;
                    display: block;
                }
            }
        }
    }
    .container {
        padding: 1rem 20px 1rem 15px !important;
    }
}
</style>

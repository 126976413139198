<template>
    <div class="site-wrapper">
        <div class="container">
            <h3>General Safety Briefing</h3>

            <div v-if="currentVessel != '' && currentVessel != undefined">
                <h1>{{ currentVessel.vesselName }}</h1>

                <h1 v-if="loadingData">
                    <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
                </h1>

                <!-- ============================================================================== -->

                <div v-if="!loadingData">
                    <div v-if="checklist.length > 0" class="checklist-wrapper">
                        <div class="checklist-item red" v-for="item in checklist" :key="item.id" @click="showDetails(item)">
                            <div class="item-content">{{ item.title }}</div>
                            <div v-if="item.details != ''" class="details-icon"><font-awesome-icon icon="info-circle" /></div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="" v-else>No safety briefing found.</div>
                </div>

                <!-- ============================================================================== -->
            </div>

            <div class="spacer s20"></div>

            <div class="buttons centered">
                <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            </div>

            <div class="spacer s50"></div>

            <details-modal :title="selectedItem.title" theme="red" @closed="close()" v-if="showDetailsModal">
                <div v-for="(line, idx) in selectedItem.details.split('\n')" :key="`details-${idx}`">{{ line }}<br /></div>
                <div class="spacer s20"></div>
                <div v-if="selectedItem.link != ''" class="link">
                    <a :href="selectedItem.link" target="_blank" class="simple-link">Link:&nbsp;{{ selectedItem.link }}</a>
                </div>
                <div class="spacer s20"></div>
            </details-modal>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, doc, orderBy, onSnapshot, getDocs } from 'firebase/firestore';
const DetailsModal = () => import('../reusables/DetailsModal.vue');

export default {
    mounted: function () {
        this.loadChecklist();
        this.loadFleetChecklist();
    },

    components: {
        DetailsModal
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        }
    },

    data: function () {
        return {
            checklist: [],
            loadingData: false,
            showDetailsModal: false,
            selectedItem: ''
        };
    },

    methods: {
        toPage(page) {},

        close() {
            this.showDetailsModal = false;
            this.selectedItem = '';
        },

        goBack() {
            this.$router.go(-1);
        },

        async loadChecklist() {
            let self = this;
            if (this.currentVessel.vesselID) {
                this.loadingData = true;
                const q = query(collection(db, 'vessels', this.currentVessel.vesselID, 'smsPassengerInductionItems'), orderBy('index', 'asc'));
                const data = onSnapshot(
                    q,
                    (querySnapshot) => {
                        self.checklist = [];
                        querySnapshot.forEach((doc) => {
                            // console.log(doc.data());
                            self.checklist.push({
                                id: doc.id,
                                title: doc.data().title ? doc.data().title : 'No title',
                                details: doc.data().details ? doc.data().details : '',
                                index: doc.data().index ? doc.data().index : 999,
                                link: doc.data().link ? doc.data().link : ''
                            });
                        });
                    },
                    (error) => {
                        console.log('Vessel snap error', error);
                        self.loadingData = false;
                    }
                );
                setTimeout(() => {
                    console.log('loaded');
                    self.loadingData = false;
                }, 1500);
            }
        },

        loadFleetChecklist() {
            let self = this;
            if (this.currentVessel.vesselID && this.currentVessel.isCompanyVessel) {
                const q = query(
                    collection(db, 'companies', this.currentVessel.vesselOwnerID, 'companyPassengerInductionItems'),
                    orderBy('index', 'asc')
                );
                const data = onSnapshot(
                    q,
                    (querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            if (
                                self.currentVessel.savedCompanyPassengerInductionItems &&
                                self.currentVessel.savedCompanyPassengerInductionItems.includes(doc.id)
                            ) {
                                self.checklist.push({
                                    id: doc.id,
                                    title: doc.data().title ? doc.data().title : 'No title',
                                    details: doc.data().details ? doc.data().details : '',
                                    index: doc.data().index ? doc.data().index : 999,
                                    link: doc.data().link ? doc.data().link : ''
                                });
                            }
                        });
                    },
                    (error) => {
                        console.log('Fleet snap error', error);
                    }
                );
            }
        },

        showDetails(item) {
            // console.log(item);
            this.selectedItem = item;
            if (item.details != '') {
                this.showDetailsModal = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>

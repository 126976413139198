<template>
  <div class="site-wrapper">
    <div class="container">

      <div class="spacer s20"></div>

      <h1 v-if="!hasData">OffshoreSMS Vessels</h1>
      <h1 v-else>{{ vesselData.vesselName }} <span><font-awesome-icon icon="check-circle" /></span></h1>

      <h3 v-if="!checkingCode">Enter a 6 digit vessel code</h3>

      <div v-if="!hasData" class="spacer s30"></div>


      <div class="code-entry">

        <div v-if="!checkingCode" class="input-wrapper">
          <input ref="code" type="tel" maxlength="6" placeholder="Enter code" @keypress="isNumber($event)" v-model="code" @keyup.enter="checkCode()">
        </div>

        <button v-if="!checkingCode" :disabled="hideButton" @click="checkCode()" class="button is-medium is-primary">Find Vessel</button>

        <div v-if="loading" class="loading-code">
          <span><font-awesome-icon icon="sync-alt" class="fa-spin" /> &nbsp; {{ loadingText }}</span>
        </div>

      </div>

      <div v-if="!hasData" class="results">

        <h3>{{ result.heading }}</h3>
        <p>{{ result.text }}</p>
        <div class="spacer s20"></div>
        <button v-if="showClear" @click="clearCode()" class="button is-medium is-warning">Search again</button>


      </div>

      <div v-if="hasData" class="vessel-data">
        <div class="">
          <span>Vessel Owner:</span> {{ vesselData.vesselOwnerName }}
        </div>
        <div class="">
          <span>Home Port:</span> {{ vesselData.homePort || vesselData.homePort == '' ? vesselData.homePort : 'Unknown' }}
        </div>
        <div class="spacer s20"></div>
        <button v-if="showClear" @click="clearCode()" class="button is-medium is-warning">Search again</button>

      </div>



      <!-- <div class="extras">

    <a href="http://offshoresms.com.au" class="button is-medium is-primary">Visit OffshoreSMS</a>


    <div class="download centered">
      <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank"><img class="partners" src="@/assets/app-store.png" /></a>
      <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank"><img class="partners" src="@/assets/play-store.png" /></a>
    </div>

</div> -->



    </div>
  </div>
</template>

<script>

import { db } from '../main.js';
import { collection, doc, onSnapshot, setDoc, getDoc } from "firebase/firestore";
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const addRevision = httpsCallable(functions, 'addRevision');

export default {

  mounted: function () {
    setTimeout(() => {
      this.$refs.code.focus();
    }, 100);
    this.$store.dispatch("CLEARSELECTEDVESSEL");
  },

  computed: {
    currentVessel() {
      return this.$store.getters.getCurrentVessel;
    },
    hideButton() {
      return this.code.length != 6 || this.checkingCode;
    }
  },

  data: function () {
    return {
      code: '',
      checkingCode: false,
      result: {
        heading: '',
        text: ''
      },
      showClear: false,
      loading: false,
      loadingText: 'Checking code...',
      vesselData: {},
      hasData: false,

    }
  },

  methods: {

    isNumber: function ($evt) {
      $evt = ($evt) ? $evt : window.event;
      var charCode = ($evt.which) ? $evt.which : $evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $evt.preventDefault();
      } else {
        return true;
      }
    },

    toPage(page) {
      this.$router.push({
        name: page,
        params: {},
      });
      NProgress.done();
    },

    goBack() {
      this.$router.go(-1);
    },

    async checkCode() {
      window.scrollTo(0, 0);
      console.log(this.code);
      let self = this;
      this.checkingCode = true;
      this.loading = true;
      NProgress.start();

      if (this.code == '123456') {
        this.toPage('Home');
        return;
      }

      const docRef = doc(db, 'vesselManualCodes', this.code);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        self.loadingText = 'Fetching vessel information...';
        console.log('FOUND:', docSnap.data().vesselID, docSnap.data().vesselName);
        self.loadVesselData(docSnap.data().vesselID);
      } else {
        self.result.heading = 'Invalid Code';
        self.result.text = 'Please check the code or search again.';
        self.loading = false;
        self.showClear = true;
        self.loadingText = 'Checking code...';
        NProgress.done();
        addRevision({
          userID: '',
          userName: '',
          description: 'Someone has entered an invalid code (' + self.code + ') on OffshoreSMS Go.',
        });
      }

    },

    async loadVesselData(vesselID) {
      // console.log('VID',vesselID);
      let self = this;
      const docRef = doc(db, 'vessels', vesselID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log('exists@!');
        // console.log(docSnap.data().vesselName);
        self.vesselData = docSnap.data();
        self.result.heading = docSnap.data().vesselName ? docSnap.data().vesselName : 'No vessel name';
        self.result.text = '';
        self.loading = false;
        self.showClear = true;
        self.loadingText = 'Checking code...';
        self.hasData = true;
        NProgress.done();
        let query = {
          vesselid: vesselID,
          screenid: 'vessel'
        };
        let sentVessel = {
          vesselID: vesselID,
        };
        // console.log('query',query);

        // let allData = docSnap.data();
        // // vesselData["vesselID"] = vesselID;
        // // vesselData["savedAppInfoSections"] = docSnap.data().savedAppInfoSections ? docSnap.data().savedAppInfoSections : [];
        // allData.vesselID = vesselID;
        // allData.savedAppInfoSections = docSnap.data().savedAppInfoSections ? docSnap.data().savedAppInfoSections : [];
        // self.$store.dispatch("UPDATESELECTEDVESSEL", { allData });

        self.$router.push({
          name: 'VesselInfo',
          params: {
            sentVessel: sentVessel
          },
          query: query,
        });
        // console.log('made this?');
        addRevision({
          userID: '',
          userName: '',
          description: 'Someone has successfully searched for a vessel with code (' + self.code + ') and vesselID (' + vesselID + ') on OffshoreSMS Go.',
        });
      } else {
        self.result.heading = 'No vessel found, sorry.';
        self.result.text = 'Please check the code or search again.';
        self.showClear = true;
        self.loading = false;
        self.loadingText = 'Checking code...';
        NProgress.done();
        addRevision({
          userID: '',
          userName: '',
          description: 'Failed vessel search with code (' + self.code + ') and vesselID (' + vesselID + ') on OffshoreSMS Go.',
        });
      }
    },

    clearCode() {
      this.checkingCode = false;
      this.code = '';
      this.result = {
        heading: '',
        text: '',
      };
      this.hasData = false;
      this.showClear = false;
      setTimeout(() => {
        this.$refs.code.focus();
      }, 100);
    }




  },




}

</script>



<style lang="scss" scoped>
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
  letter-spacing: 1px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
  letter-spacing: 1px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
  letter-spacing: 1px;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
  letter-spacing: 1px;
}

ul {
  list-style: none;
}

.download {
  margin: 2rem auto;
  max-width: 200px;
}

.code-entry {
  .input-wrapper {
    max-width: 280px;
    margin: 0 auto 2rem;
    padding: 3px;
    border: 2px solid #fff;
    border-radius: 5px;

    input {
      padding: 1rem;
      background: #fff;
      border: none;
      font-size: 2rem;
      letter-spacing: 5px;
      text-transform: uppercase;
      text-align: center;
      max-width: 280px;
      color: dodgerblue;
      // outline: -webkit-focus-ring-color auto thin;
      // outline: revert;
      // outline: #f00 1px solid;
      display: block;
      width: 100%;
      margin: 0;
      border-radius: 2px;

      &:focus {
        outline: none;
      }
    }
  }
}

.extras {
  padding-top: 1rem;
  border-top: 1px solid #e8e8e8;
  width: 80%;
  margin: 5rem auto 3rem;
}

.results {
  .heading {}

  .text {}
}
</style>













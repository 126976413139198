<template>
  <div class="site-wrapper">

    <div class="container">

      <h3>About this Vessel</h3>

      <div v-if="currentVessel != '' && currentVessel != undefined">

        <h1>{{ currentVessel.vesselName }}</h1>


        <!-- ============================================================================== -->

        <div class="vessel-photo" v-if="currentVessel.avatarUrl && currentVessel.avatarUrl != ''"
          :style="{ backgroundImage: `url(${currentVessel.avatarUrl})` }"></div>


        <div class="info-section">
          <div class="info-title">Vessel Name:</div>
          <div class="info-details caps">
            {{ currentVessel.vesselName }}
          </div>
        </div>


        <!-- <div class="info-section">
        <div class="info-title">Vessel UVI Number:</div>
        <div class="info-details caps">
          {{currentVessel.vesselUVI}}
        </div>
      </div> -->


        <div v-if="currentVessel.vesselDistMarks" class="info-section">
          <div class="info-title">Vessel Identification:</div>
          <div class="info-details caps">
            {{ currentVessel.vesselDistMarks }}
          </div>
        </div>


        <div class="info-section">
          <div class="info-title">Vessel Owner:</div>
          <div class="info-details">
            {{ currentVessel.vesselOwnerName ? currentVessel.vesselOwnerName : currentVessel.vesselOwner }}
          </div>
        </div>


        <div v-if="currentVessel.vesselDescription" class="info-section">
          <div class="info-title">Vessel Description:</div>
          <div class="info-details">
            {{ currentVessel.vesselDescription }}
          </div>
        </div>


        <!-- <div v-if="currentVessel.vesselPropulsion" class="info-section">
        <div class="info-title">Vessel Propulsion:</div>
        <div class="info-details">
          {{currentVessel.vesselPropulsion}}
        </div>
      </div> -->


        <!-- <div v-if="currentVessel.vesselMinCrew" class="info-section">
        <div class="info-title">Minimum Crew:</div>
        <div class="info-details caps">
          {{currentVessel.vesselMinCrew}}
        </div>
      </div> -->

        <div v-if="currentVessel.homePort" class="info-section">
          <div class="info-title">Home Port:</div>
          <div class="info-details caps">
            {{ currentVessel.homePort }}
          </div>
        </div>


        <div v-for="(field, inx) in publicFields" class="info-section" :key="`field-${inx}`">
          <div class="info-title">{{ field.label }}:</div>
          <!-- <div class="info-details caps">
          {{field.value}}
        </div> -->
          <div class="info-details caps" v-for="(line, ix) in field.value.split('\n')" :key="`line-${ix}`">{{ line }}<br>
          </div>
        </div>


        <!-- <div v-if="currentVessel.vesselStates" class="info-section">
        <div class="info-title">States this vessel operates in:</div>
        <div class="info-details">
          <ul>
            <li v-for="state in currentVessel.vesselStates" :key="state">{{state}}</li>
          </ul>
        </div>
      </div> -->


        <!-- <div class="info-section">
        <div class="info-title">Vessel Takes Passengers?</div>
        <div class="info-details">
          {{currentVessel.takesPassengers ? 'YES' : 'NO'}}
        </div>
      </div> -->

        <div class="clear"></div>



        <!-- ============================================================================== -->

      </div>

      <div class="spacer s20"></div>

      <div class="buttons centered">
        <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
      </div>

      <div class="spacer s100"></div>

      <div class="clear"></div>


    </div>

  </div>
</template>

<script>

import { db } from '../main.js';
import { collection, doc, onSnapshot, setDoc, getDoc } from "firebase/firestore";

export default {

  mounted: function () {

  },

  computed: {
    currentVessel() {
      return this.$store.getters.getCurrentVessel;
    },
    customFields() {
      return this.currentVessel.customFields ? this.currentVessel.customFields : [];
    },
    publicFields() {
      return this.customFields.filter((m) => m.isPublic);
    }
  },

  data: function () {
    return {


    }
  },

  methods: {

    toPage(page) {

    },

    goBack() {
      this.$router.go(-1);
    }


  },




}

</script>





<style lang="scss" scoped>
ul {
  list-style: none;
}

.vessel-photo {
  border-radius: 10px;
  border: none;
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 45px;
  background: cadetblue;
  color: white;
  font-weight: bold;
  letter-spacing: 1pt;
  margin-bottom: 2rem;
  background-position: center center;
  background-size: cover;
}

.info-section {
  width: 100%;
  background: #fff;
  margin-bottom: 1rem;
  border-radius: 6px;
  padding: 12px;

  .info-title {
    color: #333;
    text-align: left;
    font-size: 0.9rem;
  }

  .info-details {
    color: dodgerblue;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;

    &.caps {
      text-transform: uppercase;
    }
  }
}
</style>













<template>
    <div>
        <!-- <h1 v-if="currentDefectData.checklistName">
            {{ currentDefectData.checklistName }}
        </h1> -->
        <!-- <div v-if="currentDefectData.logDescription" class="description">
            <div v-for="(line, idx) in currentDefectData.logDescription.split('\n')" :key="`desc-${idx}`">{{ line }}</div>
        </div> -->

        <div class="spacer s10"></div>

        <!-- {{ currentVessel }} -->
        <!-- {{ currentVesselID }} -->
        <!-- <pre>{{ currentDefectData }}</pre> -->

        <div v-if="!loadingDefect">
            <div v-if="currentDefectData.webLinkCrewOnly">
                <div class="spacer s10"></div>
                <hr />
                <p>Currently logged in as: {{ userData.username }}</p>
            </div>

            <div class="form-section">
                <div class="notification">
                    <h3>Defect Details</h3>
                    <p class="red bold">{{ currentDefectData.defect }}</p>
                </div>
            </div>
            <div class="spacer s10"></div>

            <div class="form-section">
                <div class="notification">
                    <h3>Asset Name</h3>
                    <p class="bold">
                        <span>{{ currentDefectData.vesselName }}</span>
                        <span v-if="currentVessel && currentVessel.vesselOwnerName">&nbsp; (Owner: {{ currentVessel.vesselOwnerName }})</span>
                    </p>
                </div>
            </div>
            <div class="spacer s10"></div>

            <div class="form-section">
                <div class="notification">
                    <h3>Logged By</h3>
                    <div class="">
                        {{ currentDefectData.postedBy }} <span class="italic"> (on {{ simpleDate(currentDefectData.timestamp) }})</span>
                    </div>
                </div>
            </div>
            <div class="spacer s10"></div>

            <div class="form-section">
                <div class="notification">
                    <h3>Tagged Plant & Equipment</h3>
                    <div class="buttons">
                        <div
                            v-for="(item, idx) in currentDefectData.taggedAssets"
                            :key="`pe-${idx}`"
                            class="button is-dark"
                            @click="showItemInfo(item)">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer s10"></div>

            <div class="form-section">
                <div class="notification">
                    <h3>Uploaded Photos</h3>
                    <div v-if="currentDefectData.newImageUrls && currentDefectData.newImageUrls.length > 0" class="image-div">
                        <ul class="uploads">
                            <li
                                :class="{ show: shownImageIndex == index }"
                                @click="showImage(image)"
                                class="image-wrap"
                                v-for="(image, index) in currentDefectData.newImageUrls"
                                :key="`image-1-${index}`">
                                <img :src="image" />
                                <!-- <ik-image :path="kitify(image)" /> -->
                            </li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                    <div v-if="currentDefectData.imageStrings && currentDefectData.imageStrings.length > 0" class="image-div">
                        <ul class="uploads">
                            <li
                                :class="{ show: shownImageIndex == index }"
                                @click="showImage(image)"
                                class="image-wrap"
                                v-for="(image, index) in currentDefectData.imageStrings"
                                :key="`image-2-${index}`">
                                <img :src="image" />
                                <!-- <ik-image :path="kitify(image)" /> -->
                            </li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <div class="notification">
                    <h3>Activity Timeline</h3>
                    <p v-if="loadingTimeline">
                        <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
                        &nbsp;Loading...
                    </p>
                    <div v-else>
                        <!-- {{ defectTimeline }} -->
                        <div v-for="(item, idx) in defectTimeline" :key="`timeline-${idx}`" class="timeline">
                            <div class="tl-date"><font-awesome-icon icon="calendar-check" /> &nbsp; {{ simpleDate(item.timestamp) }}</div>
                            <div class="tl-user"><font-awesome-icon icon="user" /> &nbsp; {{ item.postedBy }}</div>
                            <div class="tl-notes">
                                <span><font-awesome-icon icon="pen" /></span>
                                <div v-for="(line, inx) in item.notes.split('\n')" :key="`line-1-${inx}`" class="tl-note">{{ line }}<br /></div>
                            </div>
                            <div class="tl-pics" v-if="item.newImageUrls.length > 0">
                                <div class="image-wrap" v-for="(image, icx) in item.newImageUrls" :key="`image-2-${icx}`" @click="showImage(image)">
                                    <img :src="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <hr />
            <div class="spacer s20"></div>
            <p class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /> Loading final defect data...</p>
        </div>

        <div class="spacer s100"></div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showReviewModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">
                        {{ formSubmitted ? 'Log Complete' : 'Ready to Submit' }}
                    </p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>

                <section class="modal-card-body">
                    <div v-if="formSubmitted">
                        <p class="green bold">Log submitted at: {{ today }}</p>
                        <div class="spacer s10"></div>
                        <p>You may close this window or reload the page below.</p>
                        <div class="spacer s20"></div>
                        <div class="buttons centered">
                            <button class="button is-primary is-medium" @click="close()">Reload Page</button>
                        </div>
                        <div class="spacer s10"></div>
                    </div>

                    <div v-else>
                        <div v-if="!reviewing">
                            <div v-if="reviewed">
                                <p class="green bold">Timestamp: {{ today }}</p>
                                <div v-if="isLoggedIn">
                                    <p>Name: {{ userData.username }}</p>
                                </div>
                                <div class="spacer s10"></div>
                                <div class="review-form">
                                    <div v-if="!isLoggedIn" class="review-field">
                                        <label class="label">Your name:</label>
                                        <input type="text" class="input" placeholder="Type your full name" v-model="formUserName" />
                                    </div>
                                    <div class="spacer s10"></div>

                                    <div class="review-field">
                                        <input class="is-checkradio normal" type="checkbox" id="localDifferent" v-model="sendEmail" />
                                        <label for="localDifferent">Email me a copy of this log</label>
                                        <div class="spacer s10"></div>
                                        <div v-if="sendEmail">
                                            <label class="label">Your email address:</label>
                                            <input type="text" class="input" placeholder="Type your email" v-model="formUserEmail" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="spacer s10"></div>
                                <p>Please go back and check answers.</p>
                                <div class="spacer s10"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer v-if="!formSubmitted" class="modal-card-foot">
                    <button class="button" @click="close()">Cancel</button>
                    <button :disabled="formUserName == '' || (sendEmail && !emailValid(formUserEmail))" class="button is-primary" @click="saveLog()">
                        Save Log
                    </button>
                </footer>
                <footer v-else class="modal-card-foot">
                    <button class="button is-primary" @click="close()">All Done!</button>
                </footer>
            </div>
        </div>

        <details-modal title="Plant & Equipment Details" theme="blue" @closed="close()" v-if="showItemInfoModal">
            <table class="table is-fullwidth">
                <tr>
                    <th>Title</th>
                    <td>{{ selectedItem.title }}</td>
                </tr>
                <tr>
                    <th>Description</th>
                    <td>{{ selectedItem.description }}</td>
                </tr>
                <tr>
                    <th>Status</th>
                    <td>{{ selectedItem.isOffline ? 'Tagged Out / Offline' : 'Active' }}</td>
                </tr>
                <tr v-if="selectedItem.trackHours">
                    <th>Current Hours</th>
                    <td>{{ selectedItem.currentHours }}</td>
                </tr>
            </table>
            <div class="spacer s10"></div>
        </details-modal>

        <details-modal title="View Photo" theme="blue" :fullscreen="true" @closed="close()" v-if="showImageModal">
            <img :src="selectedImage" />
        </details-modal>
    </div>
</template>

<script>
import { db } from '../../main.js';
import { collection, query, where, doc, onSnapshot, setDoc, getDoc, orderBy, limit, addDoc, updateDoc } from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';
import 'firebase/compat/storage';
import firebase from 'firebase/app';

import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

import DoodlePad from '@/reusables/DoodlePad.vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
let uuid = require('uuid');
import draggable from 'vuedraggable';

const functions = getFunctions();

const DetailsModal = () => import('../../reusables/DetailsModal.vue');

export default {
    mounted() {
        this.loadVesselData();
        this.loadDefectTimeline();
    },

    components: {
        DetailsModal
    },

    computed: {
        currentVesselID() {
            return this.$store.getters.getCurrentVesselID;
        },
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
        currentDefectMeta() {
            return this.$store.getters.getCurrentDefectMeta;
        },
        currentDefectData() {
            return this.$store.getters.getCurrentDefectData;
        },
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        today() {
            const dateOptions = {
                // timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            return new Date().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        }
    },

    data: function () {
        return {
            loadingDefect: false,
            hasSignature: false,
            showSignature: false,
            doodle: '',
            mounted: false,
            tempDate: new Date().toISOString(),
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

            locationAPI: '0fef0f47abb54538b5a6778d9197ebc3',
            gettingLocation: false,
            location: {
                city: '',
                region: '',
                country: '',
                lat: '',
                long: ''
            },

            currentDoodleIndex: 6,
            update: false,
            reviewing: false,
            reviewed: true,
            allIcons: {
                text: 'pen',
                paragraph: 'list-ul',
                checkboxes: 'check-square',
                doodle: 'pen',
                dropdown: 'list-ul',
                pindrop: 'map-marker',
                datetime: 'calendar-check',
                dumbtext: 'info-circle',
                upload: 'camera',
                dumbphoto: 'image'
            },
            formUserName: '',
            sendEmail: false,
            formUserEmail: '',
            showReviewModal: false,
            formSubmitted: false,
            // today: new Date().toISOString(),
            myUploads: [],
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                acceptedFiles: '.jpg, .jpeg, .png',
                dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Click here to add a photo</p><p class="form-text">Allowed Files: .jpg, jpeg, .png (Up to 4MB)</p>`,
                maxFilesize: 4
            },
            selectedField: [],
            selectedItem: {},
            showItemInfoModal: false,
            shownImageIndex: 10,
            selectedImage: '',
            showImageModal: false,
            loadingTimeline: false,
            defectTimeline: []
        };
    },

    methods: {
        emailValid(email) {
            return this.reg.test(email) && email.includes('@');
        },

        kitify(image) {
            let cleanImage = image.replace('dev-offshoresms', 'app-offshoresms');
            // console.log(image);
            // return image.replace("https://firebasestorage.googleapis.com/v0/b/app-offshoresms.appspot.com","https://ik.imagekit.io/offshoresms/v2");
            return cleanImage.replace('https://firebasestorage.googleapis.com/v0/b/app-offshoresms.appspot.com', '');
        },

        zoomInOut(index) {
            this.shownImageIndex = this.shownImageIndex == index ? 10 : index;
        },

        async loadVesselData() {
            let self = this;
            const data = onSnapshot(doc(db, 'vessels', this.currentVesselID), (doc) => {
                let allData = doc.data();
                allData['vesselID'] = self.currentVesselID;
                self.vesselData = allData;
                self.$store.dispatch('UPDATESELECTEDVESSEL', { allData });
            });
        },

        async loadDefectTimeline() {
            let self = this;
            if (this.currentDefectData.id && this.currentDefectData.id != '') {
                this.loadingTimeline = true;

                const q = query(
                    collection(db, 'vessels/' + this.currentVesselID + '/smsDefects/' + this.currentDefectData.id + '/timeline'),
                    orderBy('timestamp', 'asc')
                );
                const data = onSnapshot(q, (querySnapshot) => {
                    self.defectTimeline = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        self.defectTimeline.push(data);
                    });
                });

                setTimeout(() => {
                    this.loadingTimeline = false;
                }, 500);
            }
        },

        close() {
            // console.log('close');
            if (this.formSubmitted) {
                window.location.reload();
            } else {
                this.showReviewModal = false;
                this.reviewed = false;
                this.reviewing = false;
            }
            this.selectedItem = {};
            this.showItemInfoModal = false;
            this.selectedImage = '';
            this.showImageModal = false;
        },

        showItemInfo(item) {
            this.selectedItem = item;
            this.showItemInfoModal = true;
        },

        showImage(image) {
            this.selectedImage = image;
            this.showImageModal = true;
        },

        simpleDate(sentDate) {
            const dateOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            return sentDate == '' ? '' : sentDate.toDate().toLocaleDateString('en-US', dateOptions); // "June 1, 2019"
        },

        async afterComplete(file, field) {
            // console.log(file, field);
            let self = this;
            let imageName = uuid.v1();
            NProgress.start();
            try {
                let storage = getStorage();
                let imageRef = storageRef(storage, `custom-logs/${self.currentVesselID}/${imageName}.png`);
                uploadBytes(imageRef, file).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        if (self.myUploads.length < 1) {
                            self.answers[field].push(downloadURL);
                            self.myUploads.push(downloadURL);
                        }
                        NProgress.done();
                    });
                });
                NProgress.done();
            } catch (error) {
                console.log(error);
                NProgress.done();
            }
            setTimeout(() => {
                NProgress.done();
            }, 1000);
        },

        deleteImage(field, index) {
            // console.log('delete');
            this.imageChanged = true;
            this.myUploads.splice(index, 1);
            field.splice(index, 1);
        },

        orderChanged() {
            this.imageChanged = true;
        },

        async getGeolocationInformation(index) {
            this.location.lat = '';
            this.location.long = '';
            this.gettingLocation = true;
            this.answers[index] = ['', '', ''];
            const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + this.locationAPI;
            const apiResponse = await fetch(API_URL);
            const data = await apiResponse.json();
            const { city, country, region, latitude, longitude } = data;
            // console.log(data);
            setTimeout(() => {
                this.location.city = city;
                this.location.region = region;
                this.location.lat = latitude.toString();
                this.location.long = longitude.toString();
                this.answers[index] = [latitude.toString(), longitude.toString(), ''];
                this.gettingLocation = false;
            }, 500);
        },

        truncate(string, limit) {
            if (string == undefined || string == '') return '';
            return string.length > limit ? string.slice(0, limit) + '...' : string;
        },

        pluralise(length) {
            return length == 1 ? '' : 's';
        },

        saveDoodle(result) {
            this.answers[result.index] = result.doodle;
            this.answers = [...this.answers];
        },

        review() {
            let self = this;
            NProgress.start();
            this.formUserName = this.isLoggedIn ? this.userData.username : this.formUserName;

            this.reviewed = false;
            this.reviewing = true;

            setTimeout(() => {
                this.reviewing = false;
                this.reviewed = true;
                this.showReviewModal = true;
                NProgress.done();
            }, 500);
        }
    }
};
</script>

<style lang="scss" scoped>
h1 {
    color: #444;
}
.container {
    max-width: 800px !important;
    padding: 1rem 20px !important;
}
.header-spacer {
    height: 40px;
}

.form-section {
    margin-bottom: 15px;
    // padding-bottom: 15px;
    // border-bottom: 1px solid #e4e4e4;
    &.last {
        border: none;
    }
    .notification {
        text-align: left;
        background-color: #f9f9f9;
        margin-bottom: 12px;
        h3 {
            color: cadetblue;
            font-family: 'Roboto Slab', serif;
            font-size: 1.1rem;
            margin-bottom: 8px;
            &.red {
                color: red;
            }
        }
        .field-prompt {
            margin-bottom: 12px;
        }
    }
    .padded-hr {
        margin: 2rem 0;
    }
}
.review-form {
    text-align: left;
    .review-field {
        margin-bottom: 12px;
        input {
            padding: 15px 12px;
            height: auto;
        }
    }
}

.text-field {
    display: flex;
    justify-content: space-around;
    .text-input {
        width: calc(100% - 60px);
    }
    .text-remove {
        width: 50px;
        .button {
            padding: 12px 18px;
            height: auto;
        }
    }
    &.first {
        .text-input {
            width: 100%;
            margin-left: 2px;
        }
    }
}

.checkbox-wrapper {
    .field {
        text-indent: -2rem;
        margin-left: 2em;
    }
}

ul.uploads {
    list-style: none;
    // height: 250px;
    height: auto;
    margin-bottom: 2rem;

    .sorting-list {
        display: inline-flex;
        height: 100%;
    }

    li {
        display: inline-flex;
        max-width: 200px;
        min-width: 100px;
        margin: 0 1rem 1rem 0;
        height: 100%;
        float: left;
        // padding: 15px;
        border: 1px solid #ddd;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            align-self: center;
        }

        .cross {
            justify-content: center;
            position: absolute;
            top: -12px;
            right: -12px;
            z-index: 39;
            background: #dedede;
            padding: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            border-radius: 50%;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }
}

.timeline {
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    .tl-date {
        font-size: 0.9rem;
        color: orange;
    }
    .tl-user {
        margin-left: 1rem;
        font-size: 0.9rem;
        color: dodgerblue;
        margin-top: 0.3rem;
    }
    .tl-notes {
        margin-left: 1rem;
        display: flex;
        margin-top: 0.3rem;
        align-items: flex-start;
        font-size: 0.9rem;
        span {
            width: 25px;
        }
        .tl-note {
            flex-grow: 1;
        }
    }
    .tl-pics {
        margin-left: 1rem;
        margin-top: 0.8rem;
        .image-wrap {
            display: inline-flex;
            max-width: 200px;
            min-width: 100px;
            margin: 0 1rem 1rem 0;
            height: 100%;
            // float: left;
            border: 1px solid #ddd;
            position: relative;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 769px) {
    .desktop {
        display: none;
    }
}
</style>

<template>
  <div class="site-wrapper">
    <div class="container">

      <!-- <h3>Passenger Manifests</h3> -->

      <div v-if="currentVessel != '' && currentVessel != undefined">

        <h1>{{ currentVessel.vesselName }}</h1>

        <h1 v-if="loadingData"><span class="loading-spinner"><font-awesome-icon icon="spinner"
              class="fa-spin 3x" /></span></h1>

        <!-- ============================================================================== -->


        <div v-if="!loadingData">

          <div v-if="checklist.length > 0" class="checklist-wrapper">

            <!-- <h4>Choose a trip to join</h4> -->
            <h4>Passenger Manifests</h4>

            <div class="checklist-item" :class="manifest.rating" v-for="manifest in checklist" :key="manifest.id"
              @click="toManifest(manifest)">
              <div class="item-content">
                <div class="item-title">{{ manifest.name }}</div>
                <div class="item-date">{{ formatDate(manifest.tripStartDate, 'dddd D MMMM') }}</div>
              </div>
              <div class="details-icon"><span><font-awesome-icon icon="info-circle" /></span></div>
              <div class="clear"></div>
            </div>

          </div>

          <div class="" v-else>
            No manifests found.
          </div>

        </div>



        <!-- ============================================================================== -->

      </div>

      <div class="spacer s20"></div>

      <div class="buttons centered">
        <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
      </div>

      <div class="spacer s50"></div>




      <details-modal :title="selectedItem.title" :theme="selectedItem.rating" @closed="close()" v-if="showDetailsModal">
        <div class="spacer s10"></div>
        <div class="hazard-item">
          <div class="hazard-title">Hazard</div>
          <div class="hazard-details">{{ selectedItem.title }}</div>
        </div>
        <div class="hazard-item">
          <div class="hazard-title">Risks</div>
          <div class="hazard-details">
            <div v-for="(line, index) in selectedItem.risks.split('\n')" :key="`risk-${index}`">{{ line }}<br></div>
          </div>
        </div>
        <div class="hazard-item">
          <div class="hazard-title">Controls</div>
          <div class="hazard-details">
            <div v-for="(line, index) in selectedItem.controls.split('\n')" :key="`control-${index}`">{{ line }}<br></div>
          </div>
        </div>
        <div class="hazard-item">
          <div class="hazard-title">RISK RATING</div>
          <div class="hazard-details bold" :class="selectedItem.rating"><span
              class="rating caps">{{ selectedItem.rating }}</span></div>
        </div>
        <div class="hazard-item">
          <div class="hazard-title">Who's Responsible?</div>
          <div class="hazard-details">{{ responsibleList(selectedItem.personResponsible) }}</div>
        </div>
        <div class="spacer s10"></div>
      </details-modal>


    </div>
  </div>
</template>

<script>

import { db } from '../main.js';
import { collection, query, doc, orderBy, where, onSnapshot, getDocs } from "firebase/firestore";
const DetailsModal = () => import('../reusables/DetailsModal.vue');

export default {

  mounted: function () {
    this.loadChecklist();
  },

  components: {
    DetailsModal,
  },

  computed: {
    currentVessel() {
      return this.$store.getters.getCurrentVessel;
    },
  },

  data: function () {
    return {
      checklist: [],
      loadingData: false,
      showDetailsModal: false,
      selectedItem: '',

    }
  },

  methods: {

    toPage(page) {

    },

    close() {
      this.showDetailsModal = false;
      this.selectedItem = '';
    },

    goBack() {
      this.$router.go(-1);
    },

    responsibleList(array) {
      var tempList = array ? array.join(', ') : '';
      return tempList;
    },

    async loadChecklist() {
      let self = this;
      if (this.currentVessel.vesselID) {
        this.loadingData = true;
        const q = query(collection(db, "vessels", this.currentVessel.vesselID, "passengerManifests"), orderBy("tripStartDate", "desc"), where("status", "==", "active"));
        const data = onSnapshot(q, (querySnapshot) => {
          self.checklist = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().tripStartDate) {
              self.checklist.push({
                id: doc.id,
                name: doc.data().name ? doc.data().name : 'No name',
                timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : '',
                tripStartDate: doc.data().tripStartDate ? doc.data().tripStartDate.toDate() : '',
                updatedDate: doc.data().updatedDate ? doc.data().updatedDate.toDate() : '',
                updatedBy: doc.data().updatedBy ? doc.data().updatedBy : '',
                notes: doc.data().notes ? doc.data().notes : '',
                status: doc.data().status ? doc.data().status : 'hidden',
              });
            }
          });
        },
          (error) => {
            self.loadingData = false;
          });
        setTimeout(() => {
          console.log('loaded');
          self.loadingData = false;
        }, 1500);

      }

    },

    formatDate: function (sentDate, format) {
      // return moment(sentDate).format(format);
      return new Date(sentDate).toDateString();
    },

    toManifest(manifest) {
      console.log(manifest);
      let currentManifestID = manifest.id;
      this.$store.dispatch("SETCURRENTMANIFEST", { currentManifestID });
      let self = this;
      this.$router.push({
        name: 'CheckIn',
        params: {
          sentManifest: manifest,
        },
      });
    }


  },




}

</script>



<style lang="scss" scoped>
.hazard-item {
  margin-bottom: 1.5rem;

  .hazard-title {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #666;
    margin-bottom: 5px;
  }
}

.checklist-wrapper {
  h4 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .checklist-item {
    background: #ba63ba;

    .item-content {
      .item-title {
        font-weight: bold;
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      .item-date {}
    }
  }
}
</style>













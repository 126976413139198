<template>
    <div id="mobilenavwrapper">
        <div class="nav-icon-wrapper">
            <!-- <a class="nav-icon-link" href="tel:0447-139-382"><font-awesome-icon icon="phone" /></a> -->

            <!-- <router-link class="nav-icon-link" to="check-in"><font-awesome-icon icon="user" /></router-link>

      -->

            <router-link v-show="!onlineStatus" class="nav-icon-link" :class="{ offline: !onlineStatus }" to="/">
                <font-awesome-icon icon="exclamation-triangle" />
                <button v-show="!onlineStatus" class="delete is-small"></button>
            </router-link>

            <router-link v-if="showSearch" class="nav-icon-link" to="vessels"><font-awesome-icon icon="search" /></router-link>

            <div v-if="isLoggedIn" class="nav-icon-link" @click="signOut()"><font-awesome-icon icon="user" /></div>
        </div>

        <!-- <div data-v-step="2" id="nav-icon" ref="nav-icon" v-bind:class="{ active: isActive }" v-on:click="toggle">
      <span></span>
      <span></span>
      <span></span>
    </div> -->

        <div v-if="seen" class="dropmenu" v-closable="{ exclude: ['nav-icon'], handler: 'closeModal' }">
            <ul>
                <li>
                    <router-link to="/"><font-awesome-icon icon="home" /> &nbsp;Home Page</router-link>
                </li>
                <!-- <li>
          <router-link to="/about"><font-awesome-icon icon="info-circle" /> &nbsp;About this site</router-link>
        </li> -->
                <!-- <li>
          <router-link to="vessel-info"><font-awesome-icon icon="ship" /> &nbsp;Vessel Info</router-link>
        </li>
        <li>
          <router-link to="check-in"><font-awesome-icon icon="users" /> &nbsp;Manifests</router-link>
        </li> -->
            </ul>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import { db } from '../main.js';
// import NProgress from 'nprogress';

import { getAuth, signOut } from 'firebase/auth';

export default {
    data() {
        return {
            isActive: false,
            seen: false,
            onlineStatus: false,
            browser: '',
            hiddenPaths: ['/view-trip']
        };
    },

    mounted() {
        navigator.onLine ? (this.onlineStatus = true) : (this.onlineStatus = false);

        // now we listen for network status changes
        window.addEventListener('online', () => {
            this.onlineStatus = true;
        });

        window.addEventListener('offline', () => {
            this.onlineStatus = false;
        });

        this.browser = this.$browserDetect.meta.name;
    },

    computed: {
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userID() {
            return this.userData.userid;
        },
        currentRouteName() {
            return this.$route.path;
        },
        showSearch() {
            return !this.hiddenPaths.includes(this.$route.path);
        }
    },

    watch: {
        $route(to, from) {
            this.isActive = false;
            this.seen = false;
        }
    },

    methods: {
        toggle: function (event) {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        },

        closeModal: function () {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        },

        signOut() {
            const auth = getAuth();
            signOut(auth)
                .then(() => {
                    // Sign-out successful.
                    this.$router.push({
                        name: 'Home'
                    });
                })
                .catch((error) => {
                    // An error happened.
                    console.log('Error signing out', error);
                });
        }
    }
};
</script>

<style lang="scss">
// @import "././node_modules/bulma/sass/utilities/_all.sass";

#mobilenavwrapper {
    margin-right: 0px;
    float: right;
    font-size: 25px;
    // margin-top: 11px;

    .nav-icon-link {
        display: table-cell;
        display: inline-block;
        vertical-align: super;
        padding-right: 20px;
        padding: 8px 20px;
        color: #245e8b;
        cursor: pointer;
        &.text {
            font-size: 1rem;
            display: inline-block;
            margin-top: 5px;
            vertical-align: super;
        }
        img {
            height: 28px;
            margin: 0;
        }
        a {
            display: inline-block;
            padding: 8px 20px;
        }
    }

    .user-status {
        position: absolute;
        right: 118px;
        top: 19px;
        font-size: 16px;
        color: #fbc51b;
        // @include mobile {
        //   display: none;
        // }
    }
}

.menu-fish {
    position: absolute;
    top: 12px;
    right: 120px;
    cursor: pointer;
    font-size: 23px;
    color: #245e8b;
    transition: 0.3s;

    &:hover {
        color: #1c99ff;
    }
}

.menu-user {
    position: absolute;
    top: 12px;
    right: 70px;
    cursor: pointer;
    font-size: 23px;
    color: #245e8b;
    transition: 0.3s;

    &:hover {
        color: #1c99ff;
    }

    .delete {
        position: absolute;
        bottom: -3px;
        right: -8px;
        background: #f95b7a;

        &:hover {
            background-color: #f95b7a;
        }
    }
}

.menu-facebook {
    position: absolute;
    top: 11px;
    right: 115px;
    cursor: pointer;
    font-size: 23px;
    color: #245e8b;
    transition: 0.3s;

    &.noUser {
        right: 80px;
    }

    &:hover {
        color: #1c99ff;
    }
}

.menu-timer-icon {
    position: absolute;
    top: 17px;
    right: 92px;
    cursor: pointer;
    transition: 0.3s;

    img {
        width: 20px;
        height: 20px !important;
        float: none;
        margin: 0;
    }
}

.menu-open-icon {
    position: absolute;
    top: 20px;
    right: 140px;
    cursor: pointer;
    transition: 0.3s;
    color: red;

    &.open {
        color: green;
    }
}

.button.header-btn {
    margin-right: 10px;
    // height: auto;
    height: 36px;
    font-size: 1rem;
    padding: 8px 15px;
    &.last {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 550px) {
    #mobilenavwrapper {
        .hide550 {
            display: none;
        }
    }
}

@media only screen and (max-width: 479px) {
    #mobilenavwrapper {
        // margin-right: 30px;
        // margin-top: 14px;
        // padding-right: 15px;
        .nav-icon-link {
            font-size: 21px;
            padding: 12px 20px;
            img {
                height: 24px;
                margin-top: 3px;
            }
            &:last-of-type {
                // padding-right: 18px;
            }
            &.hide479 {
                display: none;
            }
        }
    }
}
</style>

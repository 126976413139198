<template>
    <div>
        <h1 v-if="currentLogData.checklistName">
            {{ currentLogData.checklistName }}&nbsp;
            <span v-if="currentLogData.logDescription" class="button is-cancel" @click="showDescription = true">
                <font-awesome-icon icon="info-circle" />
            </span>
        </h1>
        <!-- <div v-if="currentLogData.logDescription" class="description">
            <span class="button is-small is-info" @click="showDescription = true">More Information</span>
        </div> -->
        <div v-if="currentLogMeta.vesselName" class="vessel-name">
            <div>
                <span>{{ currentLogMeta.vesselName }}</span>
                <span v-if="currentVessel && this.currentLogMeta.isFleet">,&nbsp;{{ currentVessel.vesselName }}</span>
            </div>
            <div v-if="autoKeyUsed && formError == ''">
                <div class="key-field"><font-awesome-icon icon="key" /> &nbsp;Key: {{ isEditing ? savedAutoKey : newAutoKeyResult }}</div>
            </div>

            <!-- <div v-if="autoKeyUsed" class="bold"><font-awesome-icon icon="key" /> &nbsp;Key: {{ log.keyFieldResult }}</div>
            <div v-else><font-awesome-icon icon="key" /> &nbsp;{{ keyFieldLabel }}: {{ log.keyFieldResult }}</div> -->
        </div>

        <!-- showOpenLogsButton: {{ showOpenLogsButton }} -->

        <!-- realAssetID: {{ realAssetID }} <br />
        realVesselID: {{ realVesselID }} <br /> -->

        <!-- {{ currentLogMeta }}
        <hr />
        {{ currentLogData }} -->
        <!-- <hr />
        isEditing: {{ isEditing }} <br />
        <pre>{{ keyFieldResult }}</pre> -->

        <div v-if="!loadingFields">
            <div class="spacer s20"></div>

            <div class="progress-wrapper">
                <div
                    class="progress-inner"
                    :class="{ complete: step === fieldGroups.length + 1 }"
                    :style="{ transform: `translate3d(-${(1 - percentage) * 100}%, 0, 0)` }"></div>
            </div>

            <!-- {{ currentLogData }} -->

            <div class="step-wrapper" v-if="fieldGroups.length > 0">
                <div class="step-progress">
                    <ul class="steps is-horizontal is-small">
                        <li
                            class="steps-segment"
                            :class="{ 'is-active': step === stx + 1 }"
                            v-for="(fieldGroup, stx) in fieldGroups"
                            :key="`step-${stx}`">
                            <span class="steps-marker">{{ stx + 1 }}</span>
                        </li>

                        <li class="steps-segment" :class="{ 'is-active': step === fieldGroups.length + 1 }">
                            <span class="steps-marker">1</span>
                        </li>
                    </ul>
                </div>

                <div class="steps-content">
                    <transition :name="transitionName" v-for="(fieldGroup, inx) in fieldGroups" :key="`group-${inx}`" class="form-section">
                        <div class="slide-section" v-if="step === inx + 1">
                            <div v-for="(field, index) in fieldGroup.tempList" :key="`group-${index}`">
                                <div v-show="showCustomField(field)" class="notification" @click="reviewing = false">
                                    <h3
                                        :class="{
                                            red:
                                                field.isRequired &&
                                                (answers[field.tempIndex] == '' ||
                                                    answers[field.tempIndex] == null ||
                                                    answers[field.tempIndex].length == 0 ||
                                                    answers[field.tempIndex] == 'Choose an option')
                                        }">
                                        <span><font-awesome-icon :icon="allIcons[field.type]" /></span>&nbsp; {{ field.label }}
                                    </h3>

                                    <!-- {{ field }}
                                    <hr />
                                    {{ step }} || {{ inx }} <br />
                                    FILLED: {{ fieldGroup.filled }} -->
                                    <!-- {{ step }} || {{ inx }}: {{ fieldGroup.filled }} -->

                                    <div v-if="!noPromptFields.includes(field.type) && field.prompt != ''" class="field-prompt">
                                        {{ field.prompt }}
                                    </div>
                                    <div v-else class="spacer s10"></div>

                                    <div class="field-type" v-if="field.type == 'text'">
                                        <div
                                            class="text-field"
                                            :class="{ first: idx == 1 }"
                                            v-for="idx in field.answerCount"
                                            :key="`text-${inx}-${idx}`">
                                            <div class="text-input">
                                                <input value="Text box" type="text" v-model="answers[field.tempIndex][idx - 1]" />
                                            </div>
                                            <div v-if="idx > 1" class="text-remove">
                                                <button class="button is-delete" @click="removeTextField(field, idx)">
                                                    <font-awesome-icon icon="trash-alt" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right-align" v-if="field.canRepeat">
                                        <div class="spacer s10"></div>
                                        <button :disabled="field.answerCount > 9" class="button is-info is-small" @click="addTextField(field)">
                                            <font-awesome-icon icon="plus" />&nbsp;Add another answer
                                        </button>
                                    </div>

                                    <div class="field-type" v-if="field.type == 'paragraph'">
                                        <textarea class="textarea" v-model="answers[field.tempIndex][0]">
                                        Paragraph
                                        </textarea>
                                    </div>

                                    <div class="field-type" v-if="field.type == 'dumbphoto'">
                                        <img :src="field.prompt" :alt="field.label" />
                                    </div>

                                    <div class="field-type" v-if="field.type == 'upload'">
                                        <div v-if="answers[field.tempIndex].length > 0" class="image-div">
                                            <ul class="uploads">
                                                <draggable
                                                    v-model="answers[field.tempIndex]"
                                                    class="sorting-list"
                                                    group="people"
                                                    @start="drag = true"
                                                    @end="orderChanged()">
                                                    <li
                                                        class="image-wrap"
                                                        v-for="(image, index) in answers[field.tempIndex]"
                                                        :key="`image2-${index}`">
                                                        <img :src="image" />
                                                        <span class="cross" @click="deleteImage(answers[field.tempIndex], index)"
                                                            ><font-awesome-icon icon="times"
                                                        /></span>
                                                    </li>
                                                </draggable>
                                            </ul>
                                        </div>
                                        <div v-if="answers[field.tempIndex].length < 1">
                                            <vue-dropzone
                                                ref="imgDropZone"
                                                id="customdropzone"
                                                :options="dropzoneOptions"
                                                @vdropzone-file-added="selectedField = answers[field.tempIndex]"
                                                @vdropzone-complete="(response) => afterComplete(response, field.tempIndex)"></vue-dropzone>
                                        </div>
                                    </div>

                                    <div class="field-type trimmed" v-if="field.type == 'dumbtext'">
                                        <div v-for="(line, idx) in field.prompt.split('\n')" :key="`line1-${idx}`">{{ line }}<br /></div>
                                    </div>

                                    <div v-if="field.type == 'checkboxes' && field.options.length > 0" class="field-type checkbox-wrapper">
                                        <div class="field" v-for="(option, ix) in field.options" :key="`check-${ix}`">
                                            <input
                                                class="is-checkradio"
                                                type="checkbox"
                                                :id="option"
                                                :value="option"
                                                v-model="answers[field.tempIndex]"
                                                @change="setResponse(field.id, field.tempIndex)" />
                                            <label :for="option">{{ option }}</label>
                                        </div>
                                    </div>

                                    <div v-if="field.type == 'crew'" class="field-type crew-picker">
                                        <div class="hidden">{{ fieldGroups[inx].tempList[index].answer }}</div>
                                        <!-- {{ fieldGroups[inx].tempList[index].answer }}
                                        <hr />
                                        {{ answers[field.tempIndex] }} -->
                                        <div class="crew-member" v-for="(crew, ix) in fieldGroups[inx].tempList[index].answer" :key="`check-${ix}`">
                                            <span class="avatar-wrapper">
                                                <span
                                                    v-if="crew.avatarUrl != ''"
                                                    :style="{ backgroundImage: `url(${crew.avatarUrl})` }"
                                                    class="avatar"></span>
                                                <span v-else class="avatar">{{ getAvatar(crew.name) }}</span>
                                            </span>
                                            <span class="crew-name">{{ crew.name }}</span>
                                            <span class="delete" @click="removeCrew(inx, index, field.tempIndex, crew.id)">Remove</span>
                                        </div>
                                        <div class="spacer s10"></div>
                                        <button
                                            class="button is-info is-small"
                                            @click="
                                                selectedField = fieldGroups[inx].tempList[index];
                                                showAddCrew = true;
                                            ">
                                            <font-awesome-icon icon="plus" />&nbsp;Add Crew
                                        </button>
                                    </div>

                                    <div v-if="field.type == 'dropdown' && field.options.length > 0" class="field-type form-group-reg">
                                        <div class="select">
                                            <select v-model="answers[field.tempIndex]" @change="setResponse(field.id, field.tempIndex)">
                                                <option value="Choose an option">Choose an option</option>
                                                <option v-for="(option, ix) in field.options" :value="option" :key="`option-${ix}`">
                                                    {{ option }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="spacer s10"></div>
                                    </div>

                                    <div class="field-type" v-if="field.type == 'doodle'">
                                        <div v-if="!fieldGroup.filled">
                                            <DoodlePad
                                                :index="field.tempIndex"
                                                :refName="`doodle-${field.tempIndex}`"
                                                :dataUrl="answers[field.tempIndex]"
                                                v-on:saveDoodle="saveDoodle"></DoodlePad>
                                        </div>
                                        <div v-else class="saved-doodle">
                                            <div class="doodle">
                                                <img :src="answers[field.tempIndex]" alt="" />
                                            </div>
                                            <div class="buttons">
                                                <span
                                                    class="button is-warning"
                                                    @click="
                                                        fieldGroup.filled = false;
                                                        answers[field.tempIndex] = '';
                                                    ">
                                                    <font-awesome-icon icon="redo-alt" /> &nbsp;Draw again
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field-type" v-if="field.type == 'datetime'">
                                        <datetime type="datetime" auto v-model="answers[field.tempIndex]"></datetime>
                                    </div>

                                    <div class="field-type" v-if="field.type == 'riskmatrix'">
                                        <!-- {{ answers[field.tempIndex] }} // {{ field.answer }} // {{ field.tempIndex }} -->
                                        <div class="notification rating">
                                            <!-- <toggle-rating
                                        v-on:sendRatingData="getRatingData"
                                        :threshold="field.numVal ? field.numVal : 0"
                                        :savedRating="answers[field.tempIndex]"></toggle-rating> -->
                                            <toggle-rating
                                                v-on:sendRatingData="getRatingData($event, field)"
                                                :savedRating="answers[field.tempIndex]"></toggle-rating>
                                        </div>
                                    </div>

                                    <div class="field-type" v-if="field.type == 'pindrop'">
                                        <p>LAT: {{ location.lat }}</p>
                                        <p>LONG: {{ location.long }}</p>
                                        <div class="spacer s10"></div>
                                        <div class="buttons">
                                            <div class="button is-primary" @click="getGeolocationInformation(field.tempIndex)">
                                                <span v-if="!gettingLocation">Get Device Location</span>
                                                <span v-else class="loading-spinner">
                                                    <font-awesome-icon icon="spinner" class="fa-spin 3x" /> Locating...
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <transition :name="transitionName">
                        <div class="slide-section" v-if="step === fieldGroups.length + 1">
                            <div v-if="currentLogData.logCanStartTrip" class="box">option to start a trip</div>

                            <button
                                :disabled="reviewing"
                                class="button is-warning is-medium"
                                @click="
                                    finalSave = true;
                                    review();
                                ">
                                <font-awesome-icon icon="check-circle" /> &nbsp;Review and Submit
                            </button>
                            <div class="spacer s40"></div>
                        </div>
                    </transition>
                </div>
            </div>

            <hr />

            <div v-if="currentVesselID" class="buttons nextPrev">
                <button v-if="step > 1" class="button is-danger previous" @click.prevent="prev()">
                    <span><font-awesome-icon icon="chevron-left" /></span>&nbsp; Previous
                </button>
                <button v-if="step < totalSteps" class="button is-success next" @click.prevent="next()">
                    Next &nbsp; <span><font-awesome-icon icon="chevron-right" /></span>
                </button>
            </div>
        </div>

        <div v-else>
            <hr />
            <div class="spacer s20"></div>
            <p v-if="formError != ''">{{ formError }}</p>
            <p v-else class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /> Creating a beautiful form...</p>
        </div>

        <!-- <pre>{{ savedAnswersMap }}</pre> -->

        <!-- {{ currentLogMeta.aID }} -->

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showAddCrew }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Select Asset Crew</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>

                <section class="modal-card-body left">
                    <div v-if="allVesselCrewIDs.length > 0">
                        <label>Add from the list of asset crew:</label>
                        <div class="spacer s10"></div>
                        <div class="checkbox-wrapper">
                            <div class="field" v-for="(crew, idx) in fullCrewList" :key="`crew-${idx}`">
                                <input class="is-checkradio" type="checkbox" :id="`crew-${idx}`" :value="crew.id" v-model="selectedCrewIDs" />
                                <label :for="`crew-${idx}`">{{ crew.name }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p>No crew have been found for this asset.</p>
                    </div>
                    <div class="spacer s10"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" @click="close()">Cancel</button>
                    <button :disabled="selectedCrewIDs.length == 0" class="button is-primary" @click="addCrew()">Add Crew</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showDescription }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">Log Information</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>
                <section class="modal-card-body description">
                    <div v-if="currentLogData.logDescription">
                        <div v-for="(line, idx) in currentLogData.logDescription.split('\n')" :key="`desc-${idx}`">{{ line }}</div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" @click="close()">Done</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showReviewModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">
                        {{
                            formSubmitted
                                ? 'Log Complete'
                                : incompleteFields.length > 0 && finalSave
                                ? 'Errors found'
                                : finalSave
                                ? 'Ready to Submit'
                                : 'Save for Later'
                        }}
                    </p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>

                <section class="modal-card-body">
                    <!-- <div v-if="!isEditing" class="box">
                        <p class="green bold">You can now link this log to a trip. Choose from one of the options below:</p>
                        <hr />
                        <div class="buttons has-addons centered">
                            <button class="button" :class="[option == 'none' ? 'is-dark' : '']" @click="option = 'none'">Don't Link</button>
                            <button class="button" :class="[option == 'active' ? 'is-success' : '']" @click="option = 'active'">Active Trip</button>
                            <button class="button" :class="[option == 'scheds' ? 'is-info' : '']" @click="option = 'scheds'">Scheduled Trip</button>
                            <button class="button" :class="[option == 'new' ? 'is-primary' : '']" @click="option = 'new'">Open a Trip</button>
                        </div>
                        <div v-if="option == 'none'" class="trip-info">
                            <p>This log won't be linked to any trip.</p>
                        </div>
                        <div v-if="option == 'active'" class="trip-info">
                            <p>If there is an active trip on this marine asset, it will be linked to that trip.</p>
                        </div>
                        <div v-if="option == 'scheds'" class="trip-info">
                            <p>Select a scheduled trip:</p>
                            <div class="scheduled-trips">
                                <div class="trip" v-for="(trip, idx) in scheduledTrips" :key="`trip-${idx}`" @click="selectedTrip = trip.id">
                                    <div class="trip-date heading">{{ trip.scheduleDate }}</div>
                                    <div class="trip-tag">{{ trip.tag }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="option == 'new'" class="trip-info">
                            <p>If there is no current active trip, a new trip will be created.</p>
                        </div>
                    </div> -->
                    <div v-if="formSubmitted">
                        <p class="green bold">Log submitted at: {{ today }}</p>
                        <div class="spacer s10"></div>
                        <p>You may close this window or reload the page below.</p>
                        <div class="spacer s20"></div>
                        <div class="buttons centered">
                            <button class="button is-primary is-medium" @click="close()">Reload Page</button>
                        </div>
                        <div class="spacer s10"></div>
                    </div>

                    <div v-else>
                        <div v-if="!reviewing">
                            <div v-if="reviewed">
                                <p class="green bold">Timestamp: {{ today }}</p>
                                <div v-if="isLoggedIn">
                                    <p>Name: {{ userData.username }}</p>
                                </div>
                                <div class="spacer s10"></div>
                                <div class="review-form">
                                    <div v-if="!isLoggedIn" class="review-field">
                                        <label class="label">Your name:</label>
                                        <input type="text" class="input" placeholder="Type your full name" v-model="formUserName" />
                                    </div>
                                    <div class="spacer s10"></div>

                                    <!-- <div class="review-field">
                                        <input class="is-checkradio normal" type="checkbox" id="localDifferent" v-model="sendEmail" />
                                        <label for="localDifferent">Email me a copy of this log</label>
                                        <div class="spacer s10"></div>
                                        <div v-if="sendEmail">
                                            <label class="label">Your email address:</label>
                                            <input type="text" class="input" placeholder="Type your email" v-model="formUserEmail" />
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div v-else>
                                <div class="spacer s10"></div>
                                <p>{{ incompleteFields.length }} incomplete fields found. Please go back and check answers.</p>
                                <div class="spacer s10"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer v-if="!formSubmitted" class="modal-card-foot">
                    <button class="button" @click="close()">Cancel</button>
                    <button
                        :disabled="(finalSave && incompleteFields.length > 0) || formUserName == '' || (sendEmail && !emailValid(formUserEmail))"
                        class="button is-primary"
                        @click="saveLog()">
                        Save Log
                    </button>
                </footer>
                <footer v-else class="modal-card-foot">
                    <button class="button is-primary" @click="close()">All Done!</button>
                </footer>
            </div>
        </div>

        <div class="modal modal-full-screen modal-fx-fadeInScale" v-bind:class="{ 'is-active': showOpenLogs }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head is-primary">
                    <p class="modal-card-title">View Current Open Logs</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>
                <section class="modal-card-body description">
                    <div v-for="(log, idx) in filteredOpenLogs" :key="`log-1-${idx}`" class="notification left" @click="toOpenLog(log)">
                        <div>
                            <div>
                                <font-awesome-icon icon="calendar-check" /> &nbsp;Started: {{ simpleDate(log.timestamp, false, true) }} ({{
                                    daysAgo(log.timestamp.toDate())
                                }})
                            </div>
                            <div><font-awesome-icon icon="user" /> &nbsp;Started By: {{ log.loggedBy }}</div>
                            <div v-if="log.keyFieldResult">
                                <div v-if="autoKeyUsed" class="bold"><font-awesome-icon icon="key" /> &nbsp;Key: {{ log.keyFieldResult }}</div>
                                <div v-else><font-awesome-icon icon="key" /> &nbsp;{{ keyFieldLabel }}: {{ log.keyFieldResult }}</div>
                            </div>
                        </div>
                        <div class="spacer s10"></div>
                    </div>
                    <div v-if="filteredOpenLogs.length == 0" class="spacer s10"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" @click="close()">Done</button>
                </footer>
            </div>
        </div>

        <div v-if="step == 1 && showOpenLogsButton" class="save-for-later open-logs" @click="showOpenLogs = true">
            <span class="save-icon"><font-awesome-icon icon="link" /></span>
            <span class="save-text">{{ currentOpenLogs.length }} {{ currentOpenLogs.length == 1 ? 'Open Log' : 'Open Logs' }}</span>
        </div>

        <div
            v-if="formError == '' && !currentLogData.preventSaveForLater"
            class="save-for-later"
            @click="
                finalSave = false;
                review();
            ">
            <span class="save-icon"><font-awesome-icon icon="check" /></span>
            <span class="save-text">Save for Later</span>
        </div>

        <div class="spacer s100"></div>
        <div class="spacer s100"></div>
    </div>
</template>

<script>
import { db } from '../../main.js';
import {
    collection,
    query,
    increment,
    doc,
    onSnapshot,
    arrayUnion,
    getDoc,
    orderBy,
    setDoc,
    addDoc,
    updateDoc,
    where,
    limit
} from 'firebase/firestore';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

import DoodlePad from '@/reusables/DoodlePad.vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
let uuid = require('uuid');
import draggable from 'vuedraggable';

const functions = getFunctions();
const customLogNotification = httpsCallable(functions, 'customLogNotification');
const userHasEngaged = httpsCallable(functions, 'userHasEngaged');
const loadAdminCrewData = httpsCallable(functions, 'loadAdminCrewData');
const customLogTrainingSchedule = httpsCallable(functions, 'customLogTrainingSchedule');
const updateManifestFromCustomWebForm = httpsCallable(functions, 'cf_passengers-updateManifestFromCustomWebForm');

import ToggleRating from '@/reusables/ToggleRating.vue';

export default {
    mounted() {
        setTimeout(() => {
            this.loadListener();
            this.loadSavedLog();
            this.loadScheduledTrips();
        }, 500);
    },

    props: {
        allVesselCrewIDs: Array
    },

    components: {
        DoodlePad,
        draggable,
        vueDropzone: vue2Dropzone,
        ToggleRating
    },

    computed: {
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
        currentVesselID() {
            return this.$store.getters.getCurrentVesselID;
        },
        currentLogMeta() {
            return this.$store.getters.getCurrentLogMeta;
        },
        currentLogData() {
            return this.$store.getters.getCurrentLogData;
        },
        linkedTrainingSchedules() {
            return this.currentLogData.linkedTrainingSchedules ? this.currentLogData.linkedTrainingSchedules : [];
        },
        incompleteFields() {
            return this.customFields
                .filter(
                    (m) =>
                        m.isRequired &&
                        (this.answers[m.tempIndex] == '' ||
                            this.answers[m.tempIndex] == null ||
                            this.answers[m.tempIndex].length == 0 ||
                            this.answers[m.tempIndex] == 'Choose an option')
                )
                .map((n) => n.label);
        },
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        totalSteps() {
            return this.fieldGroups.length + 1;
        },
        percentage() {
            return this.step / this.totalSteps;
        },
        today() {
            const dateOptions = {
                // timeZone: 'Australia/Sydney',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            return new Date().toLocaleDateString('en-US', dateOptions);
        },
        isEditing() {
            return this.currentLogMeta.vID && this.currentLogMeta.vID != '' ? true : false;
        },
        currentOpenLogs() {
            return this.currentLogData.openLogs ? this.currentLogData.openLogs : [];
        },
        showOpenLogsButton() {
            return this.filteredOpenLogs.length > 0 && this.currentLogMeta.vID == '';
        },
        keyFieldLabel() {
            let t = this.customFields ? this.customFields.filter((m) => m.id == this.currentLogData.keyFieldID)[0] : {};
            return t ? t.label : 'Key Field';
        },
        realAssetID() {
            return !this.isEditing ? this.currentLogMeta.aID : this.realVesselID;
        },
        filteredOpenLogs() {
            return this.currentOpenLogs;
            // return this.currentOpenLogs.filter((m) => m.realAssetID == this.realAssetID);
        },
        autoKeyUsed() {
            return this.currentLogData.autoKeyUsed && this.currentLogData.autoKeyPrefix;
        },
        newAutoKeyResult() {
            return this.autoKeyUsed ? this.currentLogData.autoKeyPrefix + this.liveAutoKeyValue : '';
        },
        firstCrewPickerIDs() {
            let t = this.customFields.filter((m) => m.type == 'crew')[0];
            return t ? t.answer.map((n) => n.id) : [];
        }
    },

    data: function () {
        return {
            customFields: [],
            fieldGroups: [],
            loadingFields: true,
            noPromptFields: ['pindrop', 'datetime', 'dumbphoto', 'dumbtext'],
            hasSignature: false,
            showSignature: false,
            doodle: '',
            mounted: false,
            tempDate: new Date().toISOString(),
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

            locationAPI: '0fef0f47abb54538b5a6778d9197ebc3',
            gettingLocation: false,
            location: {
                city: '',
                region: '',
                country: '',
                lat: '',
                long: ''
            },

            answers: [],
            currentDoodleIndex: 6,
            update: false,
            reviewing: false,
            reviewed: true,
            finalAnswers: [],
            conditionalResponses: {},
            allIcons: {
                text: 'pen',
                paragraph: 'list-ul',
                checkboxes: 'check-square',
                doodle: 'pen',
                dropdown: 'list-ul',
                pindrop: 'map-marker',
                datetime: 'calendar-check',
                dumbtext: 'info-circle',
                upload: 'camera',
                dumbphoto: 'image',
                crew: 'users',
                riskmatrix: 'table'
            },
            keyFieldResult: '',
            formUserName: '',
            sendEmail: false,
            formUserEmail: '',
            showReviewModal: false,
            formSubmitted: false,
            // today: new Date().toISOString(),
            myUploads: [],
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                acceptedFiles: '.jpg, .jpeg, .png',
                dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Click here to add a photo</p><p class="form-text">Allowed Files: .jpg, jpeg, .png (Up to 4MB)</p>`,
                maxFilesize: 4
            },
            selectedField: [],

            step: 1,
            transitionName: 'slide-right',
            savedAnswers: [],
            savedAnswersMap: {},
            crewData: {},
            fullCrewList: [],
            showAddCrew: false,
            selectedCrewIDs: [],
            finalSave: false,
            formError: '',
            showDescription: false,
            showOpenLogs: false,
            errorMessage: '',
            hasError: false,
            realVesselID: '',
            savedAutoKey: '',
            liveAutoKeyValue: 0,

            option: 'none',
            scheduledTrips: [],
            selectedTrip: ''
        };
    },

    methods: {
        onBegin() {
            // console.log('=== Begin ===',);
        },

        onEnd() {
            // this.checkSignature();
        },

        simpleDate(sentDate, isDate, full) {
            const longOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            const shortOptions = { month: 'short', day: 'numeric', year: 'numeric' };
            const dateOptions = full ? longOptions : shortOptions;
            if (!sentDate || sentDate == '') return '';
            return isDate ? sentDate.toLocaleDateString('en-US', dateOptions) : sentDate.toDate().toLocaleDateString('en-US', dateOptions);
        },

        daysAgo(date1) {
            let diff = new Date().getTime() - date1.getTime();
            let days = Math.round(diff / (1000 * 3600 * 24));
            return days == 1 ? days + ' day ago' : days == 0 ? 'today' : days + ' days ago';
        },

        toOpenLog(log) {
            let assetID = log.realAssetID && log.realAssetID != '' ? log.realAssetID : this.currentVesselID;
            let targetUrl = 'https://go.offshoresms.com.au/vessel-logs?x=' + this.currentLogData.webLink + '&a=' + assetID + '&v=' + log.id;
            // https://go.offshoresms.com.au/vessel-logs?x=Ln7NShjkAn&a=BNltco7v26kkdTbhFxGh&v=ArYTjIVLcTB3L2NqKuxg
            // console.log(targetUrl);
            // window.location.href = targetUrl;
            window.open(targetUrl, '_blank');
        },

        async loadListener() {
            const coll = this.currentLogMeta.isFleet ? 'companies/' : 'vessels/';
            const unsub = onSnapshot(doc(db, coll, this.currentLogMeta.vesselID, 'customChecklists', this.currentLogData.id), (doc) => {
                this.liveAutoKeyValue = doc.data().autoKeyValue ? doc.data().autoKeyValue : 0;
            });
        },

        async loadSavedLog() {
            // console.log('meta 1', this.currentLogMeta);
            if (this.currentLogMeta.vID == '') {
                this.loadCustomFields();
            } else {
                let self = this;
                const coll = this.currentLogMeta.isFleet ? 'companies/' : 'vessels/';
                const docRef = doc(db, coll + this.currentLogMeta.vesselID + '/customLogs', this.currentLogMeta.vID);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    let data = docSnap.data();
                    self.savedAnswers = data.customFieldAnswers ? data.customFieldAnswers : [];
                    self.realVesselID = data.realVesselID ? data.realVesselID : '';
                    self.savedAutoKey = data.keyFieldResult ? data.keyFieldResult : '';
                    self.savedAnswersMap = self.savedAnswers.reduce((acc, a) => ({ ...acc, [a.id]: a.answer }), {});
                    if (data.status && data.status == 'active') {
                        // proceed if saved log still active
                        self.loadCustomFields();
                    } else {
                        self.formError = 'This log has already been completed and is no longer editable.';
                    }
                } else {
                    self.formError = 'This log does not exist.';
                }
            }
            this.loadCrewData();
        },

        async loadScheduledTrips() {
            console.log(this.currentLogMeta.vesselID);
            const q = query(
                collection(db, 'vessels/' + this.currentLogMeta.vesselID + '/logStartups'),
                orderBy('scheduleDate', 'desc'),
                where('scheduled', '==', true),
                limit(5)
            );
            const unsubscribe = onSnapshot(q, async (querySnapshot) => {
                this.scheduledTrips = [];
                await Promise.all(
                    querySnapshot.docs.map(async (doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        data.tag = doc.data().tag ? doc.data().tag : 'Unnamed trip';
                        data.scheduleDate = doc.data().scheduleDate ? doc.data().scheduleDate.toDate() : new Date();
                        this.scheduledTrips.push(data);
                    })
                );
            });
        },

        loadCustomFields() {
            // console.log('log meta', this.currentLogMeta);
            let self = this;
            const coll = this.currentLogMeta.isFleet ? 'companies/' : 'vessels/';
            const q = query(
                collection(db, coll + this.currentLogMeta.vesselID + '/customChecklists/' + this.currentLogMeta.logID + '/customFields'),
                orderBy('index')
            );
            const result = onSnapshot(q, (querySnapshot) => {
                self.customFields = [];
                self.fieldGroups = [];
                self.answers = [];
                let tempList = [];
                self.conditionalResponses = {};

                querySnapshot.docs.forEach((doc, idx) => {
                    let data = doc.data();
                    data.id = doc.id;
                    data.tempIndex = idx;
                    data.answerCount = 1;

                    // let answer =
                    //     self.savedAnswers.length > 0
                    //         ? self.savedAnswersMap[doc.id]
                    //         : doc.data().type == 'dropdown'
                    //         ? 'Choose an option'
                    //         : doc.data().type == 'datetime'
                    //         ? new Date().toISOString()
                    //         : doc.data().type == 'doodle'
                    //         ? ''
                    //         : doc.data().type == 'pindrop'
                    //         ? ['', '', '']
                    //         : [];
                    //

                    let answer =
                        self.savedAnswers.length > 0
                            ? doc.data().type == 'dropdown'
                                ? self.savedAnswersMap[doc.id]
                                : doc.data().type == 'datetime'
                                ? self.savedAnswersMap[doc.id].toDate().toISOString()
                                : doc.data().type == 'doodle'
                                ? self.savedAnswersMap[doc.id]
                                : doc.data().type == 'pindrop'
                                ? self.savedAnswersMap[doc.id]
                                : doc.data().type == 'text'
                                ? self.setSavedTextFields(self.savedAnswersMap[doc.id])
                                : doc.data().type == 'crew'
                                ? self.savedAnswersMap[doc.id]
                                : self.savedAnswersMap[doc.id]
                            : doc.data().type == 'dropdown'
                            ? 'Choose an option'
                            : doc.data().type == 'datetime'
                            ? new Date().toISOString()
                            : doc.data().type == 'doodle'
                            ? ''
                            : doc.data().type == 'pindrop'
                            ? ['', '', '']
                            : [];

                    data.answer = answer;
                    data.fieldConditions = doc.data().fieldConditions ? doc.data().fieldConditions : [];
                    data.isRequired = doc.data().isRequired ? doc.data().isRequired : false;

                    self.answers.push(answer);
                    data.filled = false;
                    tempList.push(data);
                    self.customFields.push(data);
                    self.conditionalResponses[doc.id] = [];

                    if (idx + 1 == querySnapshot.size) {
                        self.fieldGroups.push({
                            tempList: tempList,
                            filled: false
                        });
                    } else {
                        if (!querySnapshot.docs[idx + 1].data()['isAChild']) {
                            self.fieldGroups.push({
                                tempList: tempList,
                                filled: false
                            });
                            tempList = [];
                        }
                    }
                });
            });

            setTimeout(() => {
                self.loadingFields = false;
                self.mounted = true;
            }, 500);
        },

        emailValid(email) {
            return this.reg.test(email) && email.includes('@');
        },

        close() {
            if (this.formSubmitted) {
                window.location.reload();
            } else {
                this.showReviewModal = false;
                this.reviewed = false;
                this.reviewing = false;
            }
            this.showAddCrew = false;
            this.selectedCrewIDs = [];
            this.showDescription = false;
            this.showOpenLogs = false;
            this.selectedTrip = '';
        },

        async afterComplete(file, field) {
            let self = this;
            let imageName = uuid.v1();
            NProgress.start();
            try {
                let storage = getStorage();
                let imageRef = storageRef(storage, `custom-logs/${self.currentVesselID}/${imageName}.png`);
                uploadBytes(imageRef, file).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        if (self.myUploads.length < 1) {
                            self.answers[field].push(downloadURL);
                            self.myUploads.push(downloadURL);
                        }
                        NProgress.done();
                    });
                });
                NProgress.done();
            } catch (error) {
                console.log(error);
                NProgress.done();
            }
            setTimeout(() => {
                NProgress.done();
            }, 1000);
        },

        deleteImage(field, index) {
            // console.log('delete');
            this.imageChanged = true;
            this.myUploads.splice(index, 1);
            field.splice(index, 1);
        },

        orderChanged() {
            // drag = false;
            this.imageChanged = true;
        },

        setResponse(fieldID, tempIndex) {
            // console.log(fieldID,this.answers[tempIndex]);
            this.conditionalResponses[fieldID] = this.answers[tempIndex];
        },

        showCustomField(field) {
            let self = this;
            if (field.fieldConditions.length == 0) return true;
            if (field.fieldConditions.length > 0) {
                // conditionType decides if an AND or an OR query
                if (field.conditionType == 'ANY') {
                    let val = false;
                    field.fieldConditions.forEach((condition) => {
                        if (
                            self.conditionalResponses[condition.sourceFieldID] &&
                            self.conditionalResponses[condition.sourceFieldID].includes(condition.sourceOptionValue)
                        )
                            val = true;
                    });
                    return val;
                } else {
                    var tally = 0;
                    field.fieldConditions.forEach((condition) => {
                        if (
                            self.conditionalResponses[condition.sourceFieldID] &&
                            self.conditionalResponses[condition.sourceFieldID].includes(condition.sourceOptionValue)
                        )
                            tally++;
                    });
                    return tally == field.fieldConditions.length;
                }
            }
        },

        async getGeolocationInformation(index) {
            this.location.lat = '';
            this.location.long = '';
            this.gettingLocation = true;
            this.answers[index] = ['', '', ''];
            const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + this.locationAPI;
            const apiResponse = await fetch(API_URL);
            const data = await apiResponse.json();
            const { city, country, region, latitude, longitude } = data;
            // console.log(data);
            setTimeout(() => {
                this.location.city = city;
                this.location.region = region;
                this.location.lat = latitude.toString();
                this.location.long = longitude.toString();
                this.answers[index] = [latitude.toString(), longitude.toString(), ''];
                this.gettingLocation = false;
            }, 500);
        },

        pluralise(length) {
            return length == 1 ? '' : 's';
        },

        sortedByName(arr) {
            function compare(a, b) {
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            }
            return arr.sort(compare);
        },

        async loadCrewData() {
            let self = this;

            await loadAdminCrewData({
                allVesselCrewIDs: this.allVesselCrewIDs
            })
                .then((result) => {
                    let success = result.data.message == 'Success';
                    // console.log('success', success);
                    if (result.data.crewArray && result.data.crewMap) {
                        // console.log('result.data', result.data);
                        self.crewData = result.data.crewMap;
                        self.fullCrewList = self.sortedByName(result.data.crewArray);
                    } else {
                        console.log('error');
                        self.hasError = true;
                        self.errorMessage = 'There was an error validating your code, sorry. Please click Get Help below.';
                    }
                })
                .catch((error) => {
                    console.log('Error loading crew', error.text);
                });

            // self.fullCrewList = self.sortedByName(self.fullCrewList);
        },

        getAvatar(crewName) {
            // console.log(crewName);
            let parts = crewName.length > 0 ? crewName.split(' ') : [crewName];
            let initials = '';
            for (let i = 0; i < parts.length; i++) {
                if (parts[i].length > 0 && parts[i] !== '') {
                    initials += parts[i][0];
                }
            }
            return initials;
        },

        async addCrew() {
            let self = this;
            NProgress.start();
            let currentIDs = this.selectedField.answer.map((m) => m.id);
            await Promise.all(
                this.selectedCrewIDs.map((id) => {
                    if (!currentIDs.includes(id)) {
                        self.selectedField.answer.push(self.crewData[id]);
                    }
                })
            );
            this.showAddCrew = false;
            this.close();
            NProgress.done();
        },

        removeCrew(inx, index, tempIndex, crewID) {
            this.answers[tempIndex] = this.answers[tempIndex].filter((m) => m.id != crewID);
            // console.log('X', this.answers[tempIndex]);
            // console.log(inx, index, this.fieldGroups[inx].tempList[index].answer);
            let answers = this.fieldGroups[inx].tempList[index].answer.filter((m) => m.id != crewID);
            // console.log('answers', answers);
            this.fieldGroups[inx].tempList[index].answer = answers;
        },

        getRatingData(result, field) {
            // this.setResponse(field.id, field.tempIndex);
            // console.log('Ratings Data:', result, field);
            this.answers[field.tempIndex] = result;
            field.answer = [result];
            // this.conditionalResponses[field.id] = [result];
            this.conditionalResponses[field.id] = this.answers[field.tempIndex];
            // console.log(this.conditionalResponses);

            // this.conditionalResponses[field.id] = result; !!
            // this.newLikelihood = result[0];
            // this.newConsequence = result[1];
            // this.newRating = result[2];
        },

        setSavedTextFields(answer) {
            // console.log(answer);
            if (answer.length > 0) {
                return answer;
            } else {
                return answer;
            }
        },

        addTextField(field) {
            if (field.answerCount > 9) return;
            if (field.answerCount == 1 && (this.answers[field.tempIndex][0] == null || this.answers[field.tempIndex][0] == undefined))
                this.answers[field.tempIndex][0] = '';
            this.answers[field.tempIndex].push('');
            field.answerCount++;
        },

        removeTextField(field, idx) {
            this.answers[field.tempIndex].splice(idx - 1, 1);
            field.answerCount--;
        },

        saveDoodle(result) {
            // console.log('result', result);
            this.answers[result.index] = result.doodle;
            this.answers = [...this.answers];
        },

        getKeyFieldAnswer() {
            let self = this;
            // let keyAnswer = this.autoKeyUsed ? this.newAutoKeyResult : this.finalAnswers.filter((m) => m.id == this.currentLogData.keyFieldID)[0];
            // if (
            //     !this.autoKeyUsed &&
            //     (!this.currentLogData.keyFieldID || this.currentLogData.keyFieldID == '' || this.currentLogData.keyFieldID == undefined)
            // ) {
            //     return '';
            // } else {
            //     return typeof keyAnswer.answer === 'string'
            //         ? self.truncate(keyAnswer.answer ? keyAnswer.answer : '', 30)
            //         : self.truncate(keyAnswer.answer[0], 30);
            // }
            //
            // let keyAnswer = this.finalAnswers.filter((m) => m.id == this.currentLogData.keyFieldID)[0];
            // if (!this.currentLogData.keyFieldID || this.currentLogData.keyFieldID == '' || this.currentLogData.keyFieldID == undefined) {
            //     return '';
            // } else {
            //     return typeof keyAnswer.answer === 'string'
            //         ? self.truncate(keyAnswer.answer ? keyAnswer.answer : '', 30)
            //         : self.truncate(keyAnswer.answer[0], 30);
            // }

            if (this.autoKeyUsed) {
                return this.isEditing ? this.savedAutoKey : this.newAutoKeyResult;
            } else {
                if (!this.currentLogData.keyFieldID || this.currentLogData.keyFieldID == '' || this.currentLogData.keyFieldID == undefined) {
                    return '';
                } else {
                    let keyAnswer = this.finalAnswers.filter((m) => m.id == this.currentLogData.keyFieldID)[0];
                    return keyAnswer && typeof keyAnswer.answer === 'string'
                        ? self.truncate(keyAnswer.answer ? keyAnswer.answer : '', 30)
                        : self.truncate(keyAnswer.answer[0], 30);
                }
            }
        },

        async review() {
            // console.log(this.finalSave);
            let self = this;
            NProgress.start();
            this.formUserName = this.isLoggedIn ? this.userData.username : this.formUserName;
            this.finalAnswers = [];
            this.reviewed = false;
            this.reviewing = this.finalSave;

            this.customFields.forEach((answer, index) => {
                answer.answer = self.answers[index];
                self.finalAnswers.push(answer);
            });

            this.keyFieldResult = this.getKeyFieldAnswer();
            // console.log('XX', this.keyFieldResult);
            setTimeout(() => {
                this.reviewing = false;
                this.reviewed = !this.finalSave ? true : this.incompleteFields.length == 0;
                this.showReviewModal = true;
                NProgress.done();
            }, 500);
        },

        async cleanAnswers() {
            this.finalAnswers.forEach((answer) => {
                if (answer.type == 'datetime') answer.answer = new Date(answer.answer);
            });
        },

        async updateOpenLogs(finalLogID) {
            let self = this;
            let temp = [...this.currentLogData.openLogs];
            await Promise.all(
                temp.map(async (log) => {
                    if (log.id == finalLogID) {
                        log.keyFieldResult = self.keyFieldResult;
                    }
                })
            );
            return temp;
        },

        async saveLog() {
            console.log(this.option);
            console.log(this.realAssetID);
            console.log(this.selectedTrip);
            console.log(this.currentVessel.isActive);

            this.formUserName = this.isLoggedIn ? this.userData.username : this.formUserName;
            let self = this;
            let checklistName = self.currentLogData.checklistName ? self.currentLogData.checklistName : '';
            let notifyEmails = self.currentLogData.notifyEmails ? self.currentLogData.notifyEmails : [];
            if (this.sendEmail) notifyEmails.push(this.formUserEmail);

            await this.cleanAnswers();

            let finalLogID = self.currentLogMeta.vID ? self.currentLogMeta.vID : '';
            let isFleetLog = this.currentLogMeta['isFleet'] ? this.currentLogMeta['isFleet'] : false;
            let realVesselID = this.realVesselID != '' ? this.realVesselID : this.realAssetID;

            var newLogData = {
                isFleet: isFleetLog,
                vesselName: this.currentLogMeta['vesselName'],
                realVesselName: this.currentLogMeta['vesselName'],
                realVesselID: realVesselID,
                vesselID: this.currentLogMeta['vesselID'],
                selectedItems: [],
                notes: '',
                location: 'Not set',
                lastUpdateBy: this.formUserName,
                lastUpdateTimestamp: new Date(),
                customChecklistID: this.currentLogMeta['logID'],
                customChecklistName: checklistName,
                customFieldAnswers: this.finalAnswers,
                lastLogStatus: 'complete',
                lastLogComplete: true,
                keyFieldResult: this.keyFieldResult,
                status: this.finalSave ? 'complete' : 'active',
                isFleetCopy: isFleetLog,
                linksToManifest: self.currentLogData.linksToManifest && self.currentLogData.linksToManifest == true
            };

            if (this.isEditing) {
                const coll = self.currentLogMeta.isFleet ? 'companies' : 'vessels';
                const updateRef = doc(db, coll + '/' + self.currentLogMeta['vesselID'] + '/customLogs', self.currentLogMeta.vID);
                await updateDoc(updateRef, newLogData);

                // if isFleetLog, save a copy to the vessel as well, flagging it as "isFleetCopy"
                if (isFleetLog && realVesselID != '' && self.finalSave) {
                    newLogData.timestamp = new Date();
                    newLogData.logTimestamp = new Date();
                    newLogData.postedBy = self.formUserName;
                    await setDoc(doc(db, 'vessels/' + realVesselID + '/customLogs', self.currentLogMeta.vID), newLogData);
                }

                // get openLogs and if finalSave then remove current log or if not finalSave then update current log keyFieldResult:
                let updatedOpenLogs = self.finalSave
                    ? self.currentLogData.openLogs.filter((m) => m.id != finalLogID)
                    : await self.updateOpenLogs(finalLogID);
                const updateRefTwo = doc(db, coll + '/' + self.currentLogMeta['vesselID'] + '/customChecklists', self.currentLogMeta['logID']);
                await updateDoc(updateRefTwo, {
                    openLogs: updatedOpenLogs
                });
            } else {
                newLogData.timestamp = new Date();
                newLogData.logTimestamp = new Date();
                newLogData.postedBy = self.formUserName;

                const coll = self.currentLogMeta.isFleet ? 'companies' : 'vessels';
                const docRef = await addDoc(collection(db, coll, self.currentLogMeta['vesselID'], 'customLogs'), newLogData);
                finalLogID = docRef.id;

                // if isFleetLog, save a copy to the vessel as well, flagging it as "isFleetCopy"
                if (isFleetLog && realVesselID != '' && self.finalSave) {
                    await setDoc(doc(db, 'vessels/' + realVesselID + '/customLogs', finalLogID), newLogData);
                }

                if (!this.finalSave) {
                    let logData = {
                        id: docRef.id,
                        timestamp: new Date(),
                        loggedBy: self.formUserName,
                        keyFieldResult: self.keyFieldResult,
                        realAssetID: realVesselID
                    };
                    const updateRef = doc(db, coll + '/' + self.currentLogMeta['vesselID'] + '/customChecklists', self.currentLogMeta['logID']);
                    await updateDoc(updateRef, {
                        openLogs: arrayUnion(logData)
                    });
                }

                // save trip manifest formSubmission
                if (this.currentLogData.linksToManifest && this.currentLogData.linksToManifest == true) {
                    // console.log('vessels', self.realAssetID, 'logStartups', self.currentLogData.tripID, 'formSubmissions');
                    updateManifestFromCustomWebForm({
                        assetID: self.realAssetID,
                        tripID: self.currentLogData.tripID,
                        archived: false,
                        isFleetLog: self.currentLogMeta.isFleet ?? false,
                        fleetID: self.currentLogMeta['vesselID'] ?? '',
                        logID: finalLogID ?? '',
                        passengerID: self.currentLogData.passengerID ?? '',
                        passengerName: self.currentLogData.passengerName ?? '',
                        checklistName: checklistName ?? '',
                        checklistID: self.currentLogMeta['logID'] ?? '',
                        timestamp: new Date(),
                        appUserName: self.formUserName ?? '',
                        appUserID: ''
                    });
                }

                const updateValue = doc(db, coll + '/' + self.currentLogMeta['vesselID'] + '/customChecklists', self.currentLogMeta['logID']);
                await updateDoc(updateValue, {
                    autoKeyValue: increment(1)
                });
            }

            if (this.finalSave && this.linkedTrainingSchedules.length > 0) {
                if (this.firstCrewPickerIDs && this.firstCrewPickerIDs.length > 0) {
                    customLogTrainingSchedule({
                        linkedTrainingSchedules: self.linkedTrainingSchedules,
                        assetID: realVesselID,
                        assetName: self.currentLogMeta['vesselName'],
                        userName: self.formUserName,
                        crewIDs: self.firstCrewPickerIDs,
                        customLogName: checklistName
                    });
                }
            }

            let data = {
                isFleet: isFleetLog,
                vesselID: this.currentLogMeta['vesselID'],
                vesselName: this.currentLogMeta['vesselName'],
                realVesselName: this.currentLogMeta['vesselName'],
                logID: finalLogID,
                checklistID: this.currentLogMeta['logID'],
                logName: checklistName,
                emailList: notifyEmails,
                formUserEmail: this.formUserEmail,
                postedBy: this.formUserName,
                keyField: this.keyFieldResult,
                webLink: this.currentLogData.webLink
            };
            customLogNotification(data).catch((error) => {
                console.log('Error calling function', error);
                NProgress.done();
            });

            setTimeout(() => {
                self.formSubmitted = true;
                NProgress.done();
            }, 500);
        },

        truncate(string, limit) {
            if (string == undefined || string == '') return '';
            return string.length > limit ? string.slice(0, limit) + '...' : string;
        },

        prev() {
            if (this.step < this.totalSteps) {
                this.fieldGroups[this.step - 1].filled = true;
            }
            window.scrollTo(0, 0);
            this.transitionName = 'slide-right';
            this.step--;
        },

        next() {
            if (this.step < this.totalSteps) {
                this.fieldGroups[this.step - 1].filled = true;
            }
            window.scrollTo(0, 0);
            this.transitionName = 'slide-left';
            this.step++;
        }
    }
};
</script>

<style lang="scss" scoped>
h1 {
    color: #444;
    margin-bottom: 5px;
}
.container {
    max-width: 800px !important;
    padding: 1rem 20px !important;
}
.header-spacer {
    height: 40px;
}
.modal {
    .modal-card {
        max-height: 80%;
        max-height: 80vh;
    }
    .modal-card-title {
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.checkbox-wrapper {
    text-align: left;
    .field {
        text-indent: -2rem;
        margin-left: 2em;
    }
}
.modal-card-body {
    &.left {
        text-align: left;
    }
}

.trip-info {
    margin-top: 10px;
    color: orange;
    font-weight: bold;
}

.scheduled-trips {
    text-align: left;
    margin-top: 1rem;
    .trip {
        border-bottom: 1px dashed #bbb;
        padding-bottom: 6px;
        margin-bottom: 12px;
        color: #333;
        font-weight: normal;
        cursor: pointer;
        transition: 0.25s;
        &:hover {
            color: mediumseagreen;
        }
        .trip-date {
        }
        .trip-tag {
        }
    }
}

ul.uploads {
    list-style: none;
    // height: 250px;
    height: auto;
    margin-bottom: 2rem;

    .sorting-list {
        display: inline-flex;
        height: 100%;
    }

    li {
        display: inline-flex;
        max-width: 200px;
        min-width: 100px;
        margin: 0 1rem 1rem 0;
        height: 100%;
        float: left;
        // padding: 15px;
        border: 1px solid #ddd;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            align-self: center;
        }

        .cross {
            justify-content: center;
            position: absolute;
            top: -12px;
            right: -12px;
            z-index: 39;
            background: #dedede;
            padding: 10px;
            width: 35px;
            height: 35px;
            display: flex;
            border-radius: 50%;
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }
}
.hidden {
    display: none;
}
span {
    &.avatar {
        border-radius: 50%;
        border: none;
        width: 45px;
        height: 45px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 45px;
        background: cadetblue;
        color: white;
        font-weight: bold;
        letter-spacing: 1pt;
        margin-right: 0.6rem;
        background-position: center center;
        background-size: cover;
        transition: 0.3s;
    }
}

.save-for-later {
    position: fixed;
    bottom: 30px;
    right: 20px;
    border-radius: 50%;
    background-color: cadetblue;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.25s;
    width: 100px;
    height: 100px;
    &:hover {
        transform: scale(1.1);
    }
    .save-icon {
        font-size: 1.3rem;
        line-height: 1.8rem;
    }
    .save-text {
        line-height: 1.1rem;
        font-size: 1rem;
        font-weight: bold;
        width: 75px;
    }
    &.open-logs {
        background: orange;
        bottom: 150px;
    }
}

.buttons {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 100%;
    position: relative;

    &.nextPrev {
        margin-bottom: 5rem;
        text-align: left;

        .button {
            position: absolute;
            top: 0;

            &.next {
                right: 0;

                span {
                    margin-left: 5px;
                }
            }

            &.previous {
                left: 0;

                span {
                    margin-right: 5px;
                }
            }
        }

        &.center {
            text-align: center;
        }
    }

    .button {
        &.is-medium {
            padding: 16px 25px;
            height: auto;
        }
    }
}
.description {
    text-align: center;
}
.vessel-name {
    text-align: center;
    margin: 5px auto 10px;
}

.saved-doodle {
    .doodle {
        width: 100%;
        border: 1px dashed #444;
        max-width: 400px;
        height: 500px;
        margin-bottom: 1rem;
    }
    .buttons {
        text-align: left;
    }
}
.step-wrapper {
    margin-top: 2rem;

    .step-progress {
        display: none;
        margin: 1rem 0;
        padding-bottom: 0.5rem;

        .steps-segment {
            cursor: pointer;
        }
    }

    .steps-content {
    }
}
.progress-wrapper {
    position: relative;
    height: 6px;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 3px;
    .progress-inner {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
        background: #eef0ff;
        &.complete {
            background: lightgreen;
        }
    }
}

.slide-section {
    .notification {
        text-align: left;
        background-color: #f9f9f9;
        margin-bottom: 12px;
        h3 {
            color: cadetblue;
            font-family: 'Roboto Slab', serif;
            font-size: 1.1rem;
            margin-bottom: 8px;
            &.red {
                color: red;
            }
        }
        .field-prompt {
            margin-bottom: 12px;
        }
    }
}

.key-field {
    margin-top: 7px;
    font-weight: bold;
}

// .form-section {
//     margin-bottom: 15px;
//     &.last {
//         border: none;
//     }
//     .notification {
//         text-align: left;
//         background-color: #f9f9f9;
//         margin-bottom: 12px;
//         h3 {
//             color: cadetblue;
//             font-family: 'Roboto Slab', serif;
//             font-size: 1.1rem;
//             margin-bottom: 8px;
//             &.red {
//                 color: red;
//             }
//         }
//         .field-prompt {
//             margin-bottom: 12px;
//         }
//     }
//     .padded-hr {
//         margin: 2rem 0;
//     }
// }
.review-form {
    text-align: left;
    .review-field {
        margin-bottom: 12px;
        input {
            padding: 15px 12px;
            height: auto;
        }
    }
}
.crew-member {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    border: 1px solid #f0f0f0;
    margin-bottom: 12px;
    position: relative;
    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        // background: #f0f0f0;
        // color: #444;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        margin-right: 12px;
    }
    .crew-name {
        // font-weight: bold;
    }
    .delete {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}
.text-field {
    display: flex;
    justify-content: space-around;
    .text-input {
        width: calc(100% - 60px);
    }
    .text-remove {
        width: 50px;
        .button {
            padding: 12px 18px;
            height: auto;
        }
    }
    &.first {
        .text-input {
            width: 100%;
            margin-left: 2px;
        }
    }
}

.notification {
    cursor: pointer;
    margin-bottom: 12px;
    transition: 0.25s;
    // &.flex {
    //     display: flex;
    //     align-items: flex-start;
    //     max-width: 400px;
    //     margin-bottom: 16px;
    // }
    &.left {
        text-align: left;
    }
    .log-icon {
        margin-right: 10px;
        transition: 0.25s;
        width: 18px;
    }
    .log-name {
        width: calc(100% - 30px);
    }
    &:hover {
        color: cadetblue;
        .log-icon {
            transform: scale(1.2);
        }
    }
    .locked {
        position: absolute;
        top: 5px;
        right: 9px;
        font-size: 13px;
        color: red;
    }
}

@media screen and (max-width: 769px) {
    .desktop {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    .save-for-later {
        width: 85px;
        height: 85px;
        bottom: 20px;
        .save-icon {
            font-size: 1rem;
            line-height: 1.2rem;
        }
        .save-text {
            line-height: 1rem;
            font-size: 0.9rem;
        }
        &.open-logs {
            bottom: 120px;
        }
    }
}
</style>

<template>
    <div class="site-wrapper">
        <div class="container">
            <h3>Vessel Information</h3>

            <h1 v-if="loadingData">
                <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
            </h1>

            <!-- {{currentVessel.vesselName}} -->
            <!-- <p>isDemo: {{currentVessel.isDemo}}</p>
      <p>isPrivate: {{currentVessel.isPrivate}} / {{isPrivate}}</p>
      <p>isExpired: {{currentVessel.isExpired}} / {{isExpired}}</p>
      <p>Vessel Owner Name: {{currentVessel.vesselOwnerName}}</p>
      <p>Vessel Owner ID: {{currentVessel.vesselOwnerID}}</p>
      <p>Expiry date has past: {{expiryDatePast}} / {{expiryDatePast}}</p> -->

            <div v-if="currentVessel != '' && currentVessel != undefined">
                <h1>{{ currentVessel.vesselName }}</h1>

                <!-- {{ currentVessel.savedAppInfoSections }} -->

                <div v-if="expiryDatePast || isExpired || isPrivate">
                    <div class="notification" v-if="expiryDatePast || isExpired">
                        <span class="red">This vessel is not currently active on OffshoreSMS.</span>
                    </div>

                    <div v-else>
                        <div class="notification" v-if="isPrivate"><span class="black">Privacy mode is enabled for this vessel.</span></div>
                    </div>
                </div>

                <div v-else>
                    <div v-if="currentVessel.savedAppInfoSections != undefined && currentVessel.savedAppInfoSections.length > 0">
                        <span
                            v-if="currentVessel.savedAppInfoSections.includes('Vessel Information')"
                            class="button complex is-medium is-blue"
                            @click="toPage('AboutThisVessel')">
                            <div class="button-icon">
                                <span><font-awesome-icon icon="info" /></span>
                            </div>
                            <div class="button-text">
                                <div class="button-title">About this Vessel</div>
                                <div class="button-subtitle">General info about this vessel</div>
                            </div>
                        </span>

                        <span v-if="currentVessel.isFreebie" class="button complex is-medium is-red" @click="toPage('SafetyBriefing')">
                            <div class="button-icon">
                                <span><font-awesome-icon icon="info" /></span>
                            </div>
                            <div class="button-text">
                                <div class="button-title">General Safety Briefing</div>
                                <div class="button-subtitle">A safety checklist for this vessel</div>
                            </div>
                        </span>

                        <span v-else>
                            <span
                                v-if="currentVessel.savedAppInfoSections.includes('Safety Information')"
                                class="button complex is-medium is-red"
                                @click="toPage('SafetyInfo')">
                                <div class="button-icon">
                                    <span><font-awesome-icon icon="exclamation-triangle" /></span>
                                </div>
                                <div class="button-text">
                                    <div class="button-title">Safety Information</div>
                                    <div class="button-subtitle">Important safety information</div>
                                </div>
                            </span>
                        </span>

                        <span
                            v-if="currentVessel.savedAppInfoSections.includes('Visitor Check-in')"
                            class="button complex is-medium is-green"
                            @click="toPage('VisitorCheckIn')">
                            <div class="button-icon">
                                <span><font-awesome-icon icon="users" /></span>
                            </div>
                            <div class="button-text">
                                <div class="button-title">Visitor Check-in</div>
                                <div class="button-subtitle">A 2 minute safety check-in</div>
                            </div>
                        </span>

                        <span
                            v-if="currentVessel.savedAppInfoSections.includes('Passenger Manifests') && vesselIsPro"
                            class="button complex is-medium is-purple"
                            @click="toPage('PassengerManifests')">
                            <div class="button-icon">
                                <span><font-awesome-icon icon="info" /></span>
                            </div>
                            <div class="button-text">
                                <div class="button-title">Passenger Manifests</div>
                                <div class="button-subtitle">Sign-in to a passenger trip</div>
                            </div>
                        </span>

                        <span
                            v-if="currentVessel.savedAppInfoSections.includes('Custom Logs')"
                            class="button complex is-medium is-skip"
                            @click="toPage('CustomLogs')">
                            <div class="button-icon">
                                <span><font-awesome-icon icon="pen" /></span>
                            </div>
                            <div class="button-text">
                                <div class="button-title">Custom Logs</div>
                                <div class="button-subtitle">Complete any available custom logs</div>
                            </div>
                        </span>
                    </div>

                    <div class="notification" v-else>
                        <span class="green">The vessel owner has not enabled any content for this vessel yet.</span>
                    </div>
                </div>
            </div>

            <div v-else><span v-if="!loadingData">Loading vessel information..</span></div>

            <div class="spacer s30"></div>

            <div v-if="isTeamVessel" class="buttons centered">
                <span @click="showActivateModal = true" class="button is-info">Is this your vessel?</span>
            </div>

            <div class="buttons centered">
                <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            </div>

            <div class="spacer s50"></div>

            <details-modal title="Is this your vessel?" :fullscreen="fullscreen" theme="blue" @closed="close()" v-if="showActivateModal">
                <div class="centered">
                    <!-- <h5>If this is your vessel and you would like to activate it in your name on OffshoreSMS, please give us a call on the number below:</h5>
    <div class="spacer s20"></div>
    <div><a class="button is-primary" href="tel:+61-447-139-382">Call +61 447 139 382</a></div>
    <div class="spacer s20"></div> -->
                    <h5>
                        If you'd like to activate your fully paperless <strong>safety management system</strong> plus
                        <strong>digital vessel logs</strong>, simply download the app from your App Store and create a free profile, then give us a
                        call on the number below:
                    </h5>
                    <div class="spacer s20"></div>
                    <div class="download">
                        <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank" rel="noreferrer">
                            <img :src="require(`../assets/app-store.png`)" alt="OffshoreSMS app on the Apple App Store" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp" target="_blank" rel="noreferrer">
                            <img :src="require(`../assets/play-store.png`)" alt="OffshoreSMS app on the Google Play Store" />
                        </a>
                    </div>
                    <div class="spacer s20"></div>
                    <div><a class="button is-primary" href="tel:+61-447-139-382">Call +61 447 139 382</a></div>
                    <div class="spacer s20"></div>
                </div>
            </details-modal>
        </div>
    </div>
</template>

<!-- https://go.offshoresms.com.au/vessel-info/?vesselid=BNltco7v26kkdTbhFxGh&screenid=vessel -->
<!-- BIG STICK 2: http://localhost:8080/vessel-info/?vesselid=BNltco7v26kkdTbhFxGh&screenid=vessel -->
<!-- AA BIG SHOT: http://localhost:8080/vessel-info/?vesselid=ITjbyx6p3qj1rPFDHU9L&screenid=vessel -->
<!-- KNOT SO FAST: http://localhost:8080/vessel-info/?vesselid=dfvFbE94Rz0WDl4cRHMr&screenid=vessel -->
<!-- KNOT SO FAST: http://localhost:8080/vessel-info/?vesselid=9N2L9ppOv5uSoGFvfID4&screenid=vessel -->

<script>
import { db } from '../main.js';
import { collection, doc, onSnapshot, setDoc, getDoc } from 'firebase/firestore';
const DetailsModal = () => import('../reusables/DetailsModal.vue');
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();
const addRevision = httpsCallable(functions, 'addRevision');

export default {
    props: {
        sentVessel: Object
    },

    mounted: function () {
        // this.vesselID = this.$route.query.vesselid ? this.$route.query.vesselid : '';
        console.log(this.$route);
        this.vesselID = this.$route.query.vesselid ? this.$route.query.vesselid : this.sentVessel ? this.sentVessel.vesselID : '';
        this.screenID = this.$route.query.screenid ? this.$route.query.screenid : 'vessel';
        this.docID = this.$route.query.docid ? this.$route.query.docid : '';
        console.log('INFO:', this.vesselID, this.screenID);
        this.loadVesselData();
    },

    components: {
        DetailsModal
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
        expiryDatePast() {
            console.log('Expiry date changed');
            if (
                !this.currentVessel ||
                this.currentVessel.nextChargeDate == undefined ||
                typeof this.currentVessel.nextChargeDate === 'string' ||
                typeof this.currentVessel.nextChargeDate === 'number'
            )
                return false;
            let ncd = this.currentVessel.nextChargeDate.toDate();
            let now = new Date();
            return ncd.getTime() < now.getTime();
        },
        isExpired() {
            console.log('isExpired changed');
            return !this.currentVessel ? false : this.currentVessel.isExpired ? this.currentVessel.isExpired : false;
        },
        isPrivate() {
            console.log('isPrivate changed');
            return !this.currentVessel ? false : this.currentVessel.isPrivate ? this.currentVessel.isPrivate : false;
        },
        isTeamVessel() {
            return !this.currentVessel ? false : this.currentVessel.vesselOwnerID == 'rWlzr3viZohnyspfellhe5XPQ4J3';
        },
        vesselIsPro() {
            return this.currentVessel.aaaPlans.includes('pro');
        }
    },

    data: function () {
        return {
            vesselID: '',
            screenID: '',
            docID: '',
            loadingData: false,
            vesselName: '',
            showActivateModal: false,
            fullscreen: true
        };
    },

    methods: {
        toPage(page) {
            this.$router.push({
                name: page,
                params: {}
            });
        },

        close() {
            this.showActivateModal = false;
        },

        goBack() {
            this.$router.go(-1);
        },

        loadVesselData() {
            let self = this;
            if (this.vesselID != '' && this.currentVessel == '') {
                this.loadingData = true;
                const data = onSnapshot(
                    doc(db, 'vessels', this.vesselID),
                    (doc) => {
                        let allData = doc.data();
                        // allData["vesselID"] = self.vesselID;
                        // allData["savedAppInfoSections"] = doc.data().savedAppInfoSections ? doc.data().savedAppInfoSections : [];
                        allData.vesselID = self.vesselID;
                        allData.savedAppInfoSections = doc.data().savedAppInfoSections ? doc.data().savedAppInfoSections : [];
                        self.$store.dispatch('UPDATESELECTEDVESSEL', { allData });
                        addRevision({
                            userID: '',
                            userName: '',
                            description: 'QR code information page on OffshoreSMS Go successfully loaded for the vessel ' + doc.data().vesselName
                        });
                    },
                    (error) => {
                        self.loadingData = false;
                    }
                );
                setTimeout(() => {
                    console.log('loaded');
                    self.loadingData = false;
                }, 500);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 1rem;
}

.notification {
    font-size: 1.15rem;
    max-width: 90%;
    margin: 0 auto 1rem;

    span {
        &.black {
            color: black;
        }

        &.green {
            color: green;
        }
    }
}

h5 {
    font-size: 1.2rem;
    max-width: 600px;
    margin: 1rem auto 0;
}

.download {
    a {
        display: inline-block;
        max-width: 150px;
        margin: 0 4px;
    }
}

@media only screen and (max-width: 479px) {
    .download {
        a {
            max-width: 130px;
        }
    }
}
</style>

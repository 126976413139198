<template>
    <div class="" style="color: #4a4a4a; font-size: 1em; font-weight: 400; line-height: 1.5;">
        <div class="container">

            
            <div class="spacer s20 desktop hideForPrint"></div>

            <div v-if="!loadingData">

                <div v-if="vesselID != ''">

                    <!-- <div class="img-wrapper"><img class="logo" src="@/assets/offshoresms-new-logo.png" alt="Offshore SMS"></div> -->

                    <div class="img-wrapper" style="width: 200px; margin: 0 auto;"><img style="max-width: 100%;" class="logo" src="https://ik.imagekit.io/offshoresms/offshore-sms-good-res-logo.jpg?updatedAt=1683103736289" alt="Offshore SMS"></div>

                    <!-- <div class="img-wrapper"><img class="logo" src="https://ik.imagekit.io/offshoresms/square-link-logo.jpg?updatedAt=1683103558134" alt="Offshore SMS"></div> -->
                    
                    <h1 style="font-family: Verdana, sans-serif; font-size: 1.3rem; text-align: center; color: #444; margin: 0 auto 6px;">Vessel Specifications</h1>
                    <h4 style="font-family: Verdana, sans-serif; color: #444; font-size: 1.1rem; text-align: center; font-weight: bold; margin: 0 auto 1.6rem;">{{ vesselName }}</h4>

                    <!-- {{ specFields }} -->

                    <div v-if="specFields.length > 0">

                        <div class="spec-item-wrapper" v-for="(item, index) in specFields" :key="item.id">
                            <div class="spec-item is-heading" v-if="item.isHeading || index == 0" style="display: flex; position: relative; transition: 0.2s; border-bottom: 1px solid #e4e4e4; background: #f2f2f2; padding: 7px; margin-top: 2rem; border-radius: 2px; display: block; text-align: left;">
                                <div class="spec-item-label" style="font-family: Verdana, sans-serif; font-size: 1rem; padding: 10px; font-weight: bold; flex: 0 0 30%; color: #444; text-align: left;">{{ item.label }}</div>
                                <!-- <div v-if="!item.isHeading && !index == 0" class="spec-item-content" style="padding: 10px; text-align: left;">
                                    <div v-for="(line, idx) in item.content.split('\n')" :key="`line-${idx}`">{{line}}<br></div>
                                </div> -->
                            </div>
                            <div class="spec-item" v-else style="display: flex; position: relative; transition: 0.2s; border-bottom: 1px solid #e4e4e4;">
                                <div class="spec-item-label" style="font-family: Avenir, Helvetica, Arial, sans-serif; font-size: 0.95rem; padding: 10px; font-weight: bold; flex: 0 0 30%; color: #555; text-align: left;">{{ item.label }}</div>
                                <div class="spec-item-content" style="font-family: Avenir, Helvetica, Arial, sans-serif; text-align: left; padding: 10px;">
                                    <div v-for="(line, idx) in item.content.split('\n')" :key="`line-${idx}`" style="font-family: Avenir, Helvetica, Arial, sans-serif; font-size: 0.95rem;">{{line}}<br></div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="spacer s40"></div>

                        <div class="buttons">
                            <!-- <span @click="goBack()" class="button is-info"><font-awesome-icon icon="chevron-left" />&nbsp; Go Back</span> -->
                            <span id="pdfButton" class="button is-primary" @click="savePDF()">{{ buttonText }}</span>
                        </div>

                        <div class="spacer s90 hideForPrint"></div>

                    </div>

                    <div v-else>No fields added yet.</div>

                </div>

                <div v-else>
                    <h4>No vessel found.</h4>
                </div>


            </div>

            <div v-else>
                <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" />&nbsp; Loading vessel...</span>
            </div>

         
            
            



        </div>
    </div>
</template>
  
  
  
  
<script>

import { db } from '../main.js';
import { collection, doc, orderBy, setDoc, getDoc, deleteDoc, query, where, onSnapshot } from "firebase/firestore";
import NProgress from 'nprogress';

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const addVesselRevision = httpsCallable(functions, 'addVesselRevision');

export default {

    props: {
        sentVesselID: Object,

    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        },
    },

    mounted: function () {
        this.vesselID = this.$route.query.vid ? this.$route.query.vid : this.currentVessel.vid ? this.currentVessel.vesselID : this.sentVesselID ? this.sentVesselID : '';
        this.loadVesselData();
    },

    data: function () {
        return {

            vesselID: '',
            vesselName: '',
            loadingData: true,
            specFields: [],
            message: '',
            buttonText: 'Download PDF',

        }
    },

    methods: {


        goBack() {
            this.$router.go(-1);
        },

        async loadVesselData() {
            let self = this;
            console.log('loading vessel data');
            if (this.vesselID == '') {
                setTimeout(() => {
                    self.loadingData = false;
                }, 1000);
                return;
            }
            // get doc
            const docRef = doc(db, 'vessels', this.vesselID);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                self.vesselName = docSnap.data().vesselName;
            } else {
                self.loadingData = false;
            }
            setTimeout(() => {
                self.loadSpecs();
            }, 500);
        },

        loadSpecs() {
            let self = this;
            console.log('loading vessel specs');
            const q = query(collection(db, "vessels", this.vesselID, "vesselSpecs"), orderBy("index", "asc"));
            const data = onSnapshot(q, (querySnapshot) => {
            self.specFields = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    self.specFields.push(data);
                });
            },
            (error) => {
                self.loadingData = false;
                console.log('Error',error);
            });
            setTimeout(() => {
                self.loadingData = false;
            }, 1000);
        },

        savePDF: function () {

            this.buttonText = 'Preparing PDF. Please wait...';
            var self = this;

            addVesselRevision({
                userName: '',
                userID: '',
                vesselName: self.vesselName,
                vesselID: self.vesselID,
                revisionDetails: 'Vessel specifications downloaded on OffshoreSMS QR Code page',
            });

            // First we take the HTML of the page
            var html = document.documentElement.outerHTML;
            var endpoint = 'https://pdfmyurl.com/api';

            // Now we prepare the data that we pass to the API
            // In this example we want to hide the section with the example so we pass custom CSS
            // Note that you don't need to pass any other parameters if your defaults in the members area are already good
            // var css = '.header { display:none !important;} #pdfButton {display:none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;}';
            var css = '.header { display:none !important;} #pdfButton {display:none;} .left-sidebar {display: none;} .buttons {display: none;} .vue-back-to-top {display: none;} .hideForPrint {display: none;} .narrow {display: none;} .main-fleet-content {padding: 1rem 15px !important; margin: 0 !important; width: 100% !important;} .header-spacer {display: none;} ul.scroller {display: none;} .modal {display: none;}';
            // var data = { html: html, license: 'E197E92RdrKd', css: css };
            // var data = { html: html, license: 'E197E92RdrKd', css: css, top: 5, bottom: 5, left: 0, right: 0, unit: 'mm' };
            var data = { html: html, license: 'E197E92RdrKd', css: css, orientation: 'Portrait', top: 10, bottom: 5, left: 10, right: 10, width: 210, height: 297, unit: 'mm' };
            var serialized = Object.keys(data).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
            }).join('&');

            //console.log(serialized);

            // You can insert an "in progress" message here

            // We now prepare the API call
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                var a;
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // The PDF is now generated
                    // You can remove the "in progress" message here

                    // Now we show the PDF to the user
                    a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhttp.response);
                    a.download = self.vesselName + "-specifications.pdf";
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    //console.log("One");
                    self.buttonText = 'Download PDF';
                }
            };

            // This is the actual call to our API
            xhttp.open("POST", "https://pdfmyurl.com/api", true);
            xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhttp.responseType = 'blob';
            xhttp.send(serialized);
            //console.log("Two");

            //NProgress.done();




        },




    },




}

</script>
  
  
  
<style lang="scss" scoped>
.container {
    max-width: 800px !important;
    padding: 1rem 20px !important;
}

.img-wrapper {
    width: 200px;
    margin: 0 auto;
}

h4 {
    color: #444;
    margin-bottom: 1.6rem;
}
h1 {
    color: #444;
    margin-bottom: 6px;
}
.spec-item-wrapper {
  // border-bottom: 1px solid #e4e4e4;
  .spec-item {
    display: flex;
    // cursor: pointer;
    position: relative;
    transition: 0.2s;
    border-bottom: 1px solid #e4e4e4;
    .spec-item-label {
      padding: 10px;
      font-weight: bold;
      flex: 0 0 30%;
      color: #555;
      text-align: left;
    }
    .drag-icon {
      position: absolute;
      opacity: 0;
      right: 10px;
      transition: 0.2s;
    }
    .spec-item-content {
      padding: 10px;
      text-align: left;
    }
    &.is-heading {
      background: #f2f2f2;
      padding: 10px;
      margin-top: 2rem;
      border-radius: 2px;
      display: block;
      text-align: left;
      // border-bottom: none;
      .spec-item-label {
        padding: 0px;
        width: 90%;
      } 
      .spec-item-content {
        padding: 0px;
        text-align: left;
      }
    }
    // &:hover {
    //   background: #e1f2f7;
    //   .drag-icon {
    //     opacity: 1.0;
    //   }
    // }
  }
  &:first-of-type {
    .spec-item {
      &.is-heading {
        margin-top: 0;
      }
    }
  }

  // &:last-of-type {
  //   border-bottom: none;
  //   .spec-item {
  //     border-bottom: none;
  //   }
  // }
}

@media only screen and (max-width: 767px) {
  .desktop {
    display: none;
  }
}

</style>
  
  
  
  
  
  
  
<!-- <template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div>
</template>
 -->

<template>
    <main id="app" class="white">
        <nprogress-container></nprogress-container>

        <div class="header">
            <!-- <router-link to="/">
        <img class="logo" src="./assets/offshoresms-new-logo.png" alt="Offshore SMS">
      </router-link> -->
            <router-link to="home">
                <img class="logo" src="./assets/offshoresms-new-logo.png" alt="Offshore SMS" />
            </router-link>
            <mobile-nav></mobile-nav>
        </div>

        <div class="header-spacer"></div>

        <router-view></router-view>

        <!-- <back-to-top bottom="60px" right="20px" visibleoffset="200">
      <div class="btt">
        <font-awesome-icon icon="chevron-up" />
      </div>
    </back-to-top> -->

        <div class="clear"></div>
    </main>
</template>

<script>
// import firebase from 'firebase/app';
// import 'firebase/functions';
// import Header from './components/Header.vue';

import MobileNav from './components/MobileNav.vue';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

import Vue from 'vue';

Vue.config.devtools = false;

// Custom Directive to close modals on click outside
let handleOutsideClick;
Vue.directive('closable', {
    bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        handleOutsideClick = (e) => {
            e.stopPropagation();
            // Get the handler method name and the exclude array from the object used in v-closable
            const { handler, exclude } = binding.value;
            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                // We only run this code if we haven't detected any excluded element yet
                if (!clickedOnExcludedEl) {
                    // Get the element using the reference name
                    const excludedEl = vnode.context.$refs[refName];
                    // See if this excluded element is the same element the user just clicked on
                    clickedOnExcludedEl = excludedEl.contains(e.target);
                }
            });
            // We check to see if the clicked element is not the modal element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the modal and not the button, then call the outside-click handler from the same component this directive is used in
                vnode.context[handler]();
            }
        };
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
        // If the element that has v-closable is removed, then unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    }
});

export default {
    components: {
        mobileNav: MobileNav,
        NprogressContainer
    },
    data() {
        return {
            // showRefreshButton: false,
            // registration: '',
        };
    },
    computed: {},
    mounted() {
        // console.log(this.$route);
    },
    created() {
        // when the app is created run the set user method
        // this uses Vuex to check if a user is signed in
        // check out mutations in the store.js file
        this.setUser();
        this.$store.dispatch('SETSUBSCRIPTIONINFO');

        // Listen for swUpdated event and display refresh snackbar as required.
        // document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

        // // Refresh all open app tabs when a new service worker is installed.
        // navigator.serviceWorker.addEventListener('controllerchange', () => {
        //   if (this.refreshing) return;
        //   this.refreshing = true;
        //   // window.location.reload();
        // });
    },
    methods: {
        toggle(event) {
            this.isActive = !this.isActive;
            this.seen = !this.seen;
        },
        setUser() {
            this.$store.dispatch('setUser');
        }

        // showRefreshUI(e) {
        //   // Display a snackbar inviting the user to refresh/reload the app due
        //   // to an app update being available.
        //   // The new service worker is installed, but not yet active.
        //   // Store the ServiceWorkerRegistration instance for later use.
        //   this.registration = e.detail;
        //   console.log(this.registration);
        //   this.showRefreshButton = true;
        // },

        // refreshApp() {
        //   this.showRefreshButton = false;

        //   // Protect against missing registration.waiting.
        //   if (!this.registration || !this.registration.waiting) { return; }

        //   this.registration.waiting.postMessage('skipWaiting');

        // },
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    // color: #fff;
    // background-color: #050b28;
    // background-image: linear-gradient(#050b28, #0061a8);
    // height: 100%;
    // min-height: 100vh;
    // background-size: 100%;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>

<template>
    <div>
        <div v-if="mounted" class="signatureWrapper">
            <!-- <VueSignaturePad ref="signaturePad" :options="{ onBegin, onEnd }" /> -->
            <VueSignaturePad :ref="refName" :options="{ onBegin, onEnd }" />
        </div>
        <!-- <div>
            <img :src="dataUrl" alt="" />
        </div> -->
        <div class="spacer s10"></div>
        <div class="buttons">
            <span class="button is-warning" @click="undo()">Undo</span>
            <span class="button is-danger" @click="clearSignature()">Clear</span>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import { db } from '../main.js';
import NProgress from 'nprogress';

export default {
    mounted() {
        setTimeout(() => {
            let self = this;
            this.mounted = true;
            // console.log('refName', this.refName);
            // console.log('dataUrl', this.dataUrl);
            // if (this.$refs && this.refName && this.dataUrl && this.dataUrl != '') {
            //     this.$refs[this.refName].fromDataURL(this.dataUrl);
            // }
        }, 500);
    },

    props: {
        index: Number,
        refName: String,
        dataUrl: String
    },

    data() {
        return {
            mounted: false,
            hasSignature: false,
            doodle: ''
        };
    },

    methods: {
        onBegin() {
            // console.log('=== Begin ===',);
        },

        onEnd() {
            this.checkSignature();
        },

        undo() {
            // console.log(this.$refs.signaturePad);
            this.$refs[this.refName].undoSignature();
            this.checkSignature();
        },

        clearSignature() {
            this.$refs[this.refName].clearSignature();
            this.doodle = '';
            this.checkSignature();
        },

        saveSignature() {
            // console.log('save');
            const { isEmpty, data } = this.$refs[this.refName].saveSignature();
            // console.log(data);
            this.doodle = data;
            let result = {
                index: this.index,
                doodle: data == null ? '' : data
            };
            this.$emit('saveDoodle', result);
        },

        checkSignature() {
            this.hasSignature = !this.$refs[this.refName].isEmpty();
            this.saveSignature();
        }
    }
};
</script>

<style lang="scss" scoped>
.signatureWrapper {
    width: 100%;
    border: 1px dashed #444;
    max-width: 400px;
    height: 500px;
    margin-bottom: 1rem;
}

.image {
    width: 50px;
    height: 100px;
    img {
        max-width: 100%;
    }
}
</style>

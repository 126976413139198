<template>
    <div class="site-wrapper white">
        <div class="container">
            <!-- <div class="button is-primary" @click="goBack()">Test Return ({{ mustReturn }})</div> -->

            <div v-if="!isLoggedIn" id="page-wrapper" class="container">
                <div id="recaptcha-container"></div>

                <div class="notification is-primary heading">
                    <h2 class="is-size-5 has-text-centered"><font-awesome-icon icon="lock" /> &nbsp;Member Sign-in</h2>
                </div>

                <!-- <div class="spacer s20"></div> -->

                <div v-if="attempts > 4" class="centered">
                    <h4 class="red bold">Too many failed attempts, sorry. Please click on Get Help below.</h4>
                </div>

                <div v-else>
                    <div v-if="showPasswordLogin" class="tabs is-centered is-boxed">
                        <ul>
                            <li @click="stopProgress" :class="[signinTab === 'otp' ? 'is-active' : '']">
                                <a @click="signinTab = 'otp'"><font-awesome-icon icon="lock" /><span>Secure Login</span></a>
                            </li>

                            <li @click="stopProgress" :class="[signinTab === 'email' ? 'is-active' : '']">
                                <a @click="signinTab = 'email'"><font-awesome-icon icon="envelope" /><span>Email & Password</span></a>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content">
                        <div v-if="signinTab === 'email'">
                            <!-- <form v-on:submit.prevent="signInByEmail"> -->

                            <!-- <div class="spacer s20"></div> -->

                            <div class="form-wrapper" :class="{ error: !emailValid }">
                                <label class="label">Email address</label>
                                <input
                                    autofocus
                                    class="input"
                                    type="email"
                                    v-model="email"
                                    placeholder="Your email address.."
                                    required
                                    @input="clearErrors()" />
                            </div>

                            <!-- <div class="form-group form-wrapper">
                                <input autofocus class="input" type="email" v-model="email" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Email address</label>
                            </div> -->
                            <div class="spacer s20"></div>

                            <div class="form-wrapper">
                                <label class="label">Password</label>
                                <!-- <input autofocus class="input" type="email" v-model="email"
                                    placeholder="Your email address.." required @input="clearErrors()"> -->
                                <input class="input" autocomplete="off" type="password" v-model="password" required @input="clearErrors()" />
                            </div>

                            <!-- <div class="form-group form-wrapper">
                                <input class="input" autocomplete="off" type="password" v-model="password" required>
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Password</label>
                            </div> -->

                            <div class="spacer s20"></div>

                            <div class="buttons has-text-centered">
                                <button
                                    :disabled="!emailValid || password == '' || password.length < 6"
                                    @click="signInByEmail"
                                    class="button is-primary">
                                    Sign-in
                                </button>
                                <!-- <button @click="launchResetPassword" class="button">Reset Password</button> -->
                                <p>&nbsp;</p>
                                <!-- <p class="simple-link" @click="launchResetPassword()">Forgot pasword?</p> -->
                                <p class="passwordMessage" v-if="pwResetMsg != ''">{{ pwResetMsg }}</p>
                            </div>

                            <div class="spacer s60"></div>
                            <!--  </form> -->
                        </div>

                        <div v-if="signinTab === 'otp'">
                            <div class="spacer s20"></div>

                            <div v-if="hasError" class="error-message">
                                <h4 class="headline red bold">{{ errorMessage }}</h4>
                            </div>

                            <div v-if="showInputs">
                                <div class="form-wrapper" :class="{ error: !emailValid }">
                                    <label class="label">Email address</label>
                                    <input
                                        autofocus
                                        class="input"
                                        type="email"
                                        v-model="email"
                                        placeholder="Your email address.."
                                        required
                                        @input="clearErrors()" />
                                </div>

                                <div class="spacer s20"></div>

                                <div class="form-wrapper" v-if="emailValid && email.includes('@')">
                                    <label class="label">Mobile phone number</label>
                                    <div class="phone-wrapper">
                                        <div class="country-code">
                                            <vue-country-code @onSelect="updateCode" :preferredCountries="['au', 'nz', 'us']"></vue-country-code>
                                        </div>
                                        <div class="phone-number">
                                            <input
                                                class="input inline"
                                                autocomplete="off"
                                                v-model="phoneNumber"
                                                placeholder="Enter a mobile number.."
                                                type="tel"
                                                :maxlength="15"
                                                v-on:keypress="isNumber(event), clearErrors()"
                                                required />
                                        </div>
                                    </div>
                                    <div class="spacer s10"></div>

                                    <div v-show="phoneNumber.length > 0" class="final-number">
                                        Your number: <span>{{ finalNumber }}</span>
                                    </div>
                                </div>

                                <div v-if="!showOTP">
                                    <div class="spacer s30"></div>
                                    <div v-if="phoneNumber.length > 8" class="buttons">
                                        <span class="button is-medium is-warning" @click="validateUser()">{{
                                            validating ? 'Validating...' : 'Validate Information'
                                        }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="spacer s30"></div>

                            <div v-if="crossChecked" class="cross-checked">
                                <div class="spacer s20"></div>
                                <h4 class="green">{{ statusMessage }}</h4>

                                <div v-if="codeSent">
                                    <!-- <h4 class="blue">A One Time code has been sent to your {{ sendMethod == 'email' ? 'email address' :
                            'phone number' }}. When it arrives, enter it below:</h4> -->
                                    <div class="spacer s10"></div>
                                    <div class="twilio-code">
                                        <input
                                            class="input inline"
                                            autocomplete="off"
                                            v-model="otpCode"
                                            placeholder="XXXXX"
                                            type="tel"
                                            :maxlength="6"
                                            v-on:keypress="isNumber(event)"
                                            required />
                                    </div>
                                    <div class="spacer s20"></div>
                                    <div class="buttons">
                                        <button
                                            :disabled="otpCode.length < 5"
                                            v-if="sendMethod == 'email'"
                                            class="button is-medium is-primary"
                                            @click="validateTwilioEmailCode()">
                                            Validate Code
                                        </button>
                                        <button
                                            :disabled="otpCode.length < 5"
                                            v-if="sendMethod == 'sms'"
                                            class="button is-medium is-primary"
                                            @click="validateTwilioSmsCode()">
                                            Validate Code
                                        </button>
                                    </div>
                                    <div class="spacer s30"></div>
                                </div>
                                <div v-else>
                                    <div class="spacer s20"></div>
                                    <div class="buttons">
                                        <span class="button is-medium is-skip" @click="smsTwilioCode()">{{
                                            sendingCode ? 'Sending Code..' : 'Text me a Code'
                                        }}</span>
                                        <span class="button is-medium is-warning" @click="emailTwilioCode()">{{
                                            sendingCode ? 'Sending Code..' : 'Email me a Code'
                                        }}</span>
                                    </div>
                                    <div class="spacer s30"></div>
                                </div>
                            </div>

                            <div class="spacer s30"></div>
                        </div>
                    </div>
                </div>

                <div v-if="!user" class="is-mobile is-centered">
                    <hr />
                    <div class="buttons centered">
                        <router-link class="button is-primary is-small" to="sign-up">Create a New Account</router-link>
                        <span class="button is-info is-small" @click="showHelpModal = true">Get Help</span>
                        <span class="button is-light is-small" @click="resetLogin()">Reset</span>
                    </div>
                </div>

                <!-- <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showSignUpModal }">
                    <div class="modal-background" @click="close"></div>
                    <div class="modal-card">

                        <header class="modal-card-head">
                            <p class="modal-card-title">Join Offshore SMS</p>
                        </header>
                        <section class="modal-card-body">

                            <div class="buttons has-text-centered">
                                <router-link class="button is-primary" to="sms-signup">Get an SMS from
                                    $249/year</router-link>
                                <a class="button is-info" @click.prevent="toSignUp">Or join for Free as a Crew</a>
                            </div>

                        </section>
                        <footer class="modal-card-foot">
                            <button class="button is-primary" @click="close">Close</button>
                        </footer>

                    </div>
                </div> -->

                <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showHelpModal }">
                    <div class="modal-background" @click="close"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Help signing in to OffshoreSMS</p>
                        </header>
                        <section class="modal-card-body">
                            <p>
                                If you are having trouble signing in, please call us on <a href="tel:+61-447-139-382">+61 447 139 382</a> or email us
                                at <a href="mailto:help@offshoresms.com.au?subject=Support Request for OffshoreSMS Login">help@offshoresms.com.au</a>.
                            </p>
                            <div class="spacer s20"></div>
                            <div class="buttons">
                                <span class="button is-light" @click="(showPasswordLogin = true), (signinTab = 'email'), close(), resetLogin()"
                                    >Try Email and Password Login</span
                                >
                            </div>
                            <div class="spacer s10"></div>
                        </section>
                        <footer class="modal-card-foot">
                            <button class="button is-primary" @click="close">Close</button>
                        </footer>
                    </div>
                </div>

                <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': isActivePasswordModal }">
                    <div class="modal-background" @click="close"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Reset your password:</p>
                            <button class="delete" aria-label="close" @click.prevent="close"></button>
                        </header>
                        <section class="modal-card-body">
                            <div class="form-group">
                                <input class="input" type="email" v-model="resetEmail" required />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Email address:</label>
                            </div>
                        </section>
                        <footer class="modal-card-foot">
                            <span class="button is-primary" @click="resetPassword" v-show="resetEmail.length">Send reset email</span>
                            <button class="button cancel" @click.prevent="close">Cancel</button>
                        </footer>
                    </div>
                </div>
            </div>

            <div v-else id="page-wrapper" class="container">
                <h2>You're already logged in {{ userFirstName }}</h2>
                <div class="spacer s20"></div>
                <div class="button is-info" @click="goBack()">Go Back</div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, doc, onSnapshot, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithCustomToken, sendPasswordResetEmail } from 'firebase/auth';
import NProgress from 'nprogress';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();
const addRevision = httpsCallable(functions, 'addRevision');
const userHasEngaged = httpsCallable(functions, 'userHasEngaged');
const checkUserExistsByEmail = httpsCallable(functions, 'checkUserExistsByEmail');
const sendTwilioVerifyEmailCode = httpsCallable(functions, 'sendTwilioVerifyEmailCode');
const sendTwilioVerifySmsCode = httpsCallable(functions, 'sendTwilioVerifySmsCode');
const checkTwilioVerifyEmailCode = httpsCallable(functions, 'checkTwilioVerifyEmailCode');
const checkTwilioVerifySmsCode = httpsCallable(functions, 'checkTwilioVerifySmsCode');
const createCustomLoginToken = httpsCallable(functions, 'createCustomLoginToken');
const auth = getAuth();

export default {
    props: {
        return: Boolean
    },

    mounted: function () {
        // this.mustReturn = this.return ? this.return : false;
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                console.log('current user', user);
                // ...
            } else {
                console.log('User is signed out');
                // ...
            }
        });
    },

    computed: {
        phoneError() {
            if (this.phoneNumber.length > 0 && this.phoneNumber.length < 10) {
                return this.phoneNumber.slice(0, 2) != '04' ? 'Australian mobile numbers must start with 04' : 'Enter a full mobile number';
            } else {
                if (this.phoneNumber.length == 10) {
                    return this.phoneNumber.slice(0, 2) != '04' ? 'Australian mobile numbers must start with 04' : '';
                } else {
                    return '';
                }
            }
        },
        finalNumber() {
            // return "+61" + this.phoneNumber.slice(1,10);
            let tempNum = this.phoneNumber.slice(0, 1) == '0' ? this.phoneNumber.slice(1) : this.phoneNumber;
            return '+' + this.areaCode + tempNum;
        },
        emailValid() {
            // return this.reg.test(this.email) ? true : !this.email.includes('@') ? true : false;
            return this.reg.test(this.email) && this.email.includes('@');
        },
        user() {
            return this.$store.getters.getUser;
        },
        isLoggedIn() {
            return this.user && this.user.uid && this.user.uid != '';
        },
        userData() {
            return this.$store.getters.getSubscriptionInfo;
        },
        userFirstName() {
            return this.userData.username ? this.userData.username.split(' ')[0] : '';
        }
    },

    data: function () {
        return {
            mustReturn: false,
            email: '',
            password: '',
            phoneNumber: '',
            signinTab: 'otp',
            pwResetMsg: '',
            attempts: 0,
            showHelpModal: false,
            showPasswordLogin: false,
            isActivePasswordModal: false,
            hasError: false,
            resetEmail: '',
            areaCode: '',
            event: '',
            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

            showInputs: true,
            errorMessage: '',
            crossChecked: false,
            showOTP: false,
            sendMethod: '',
            codeSent: false,
            otpCode: '',
            validating: false,
            checkingCode: false,
            sendingCode: false,
            codeValid: false,
            creatingToken: false,
            tokenValid: false,
            userID: '',
            userName: '',
            customToken: '',
            statusMessage: ''
        };
    },

    methods: {
        goBack() {
            if (this.return) {
                this.$router.back();
            } else {
                this.$router.push({
                    name: 'Home'
                });
            }
        },

        stopProgress() {
            NProgress.done();
        },

        resetLogin() {
            this.clearErrors();
            this.phoneNumber = '';
            this.email = '';
            this.showInputs = true;
            this.userID = '';
            this.userName = '';
            this.customToken = '';
            this.password = '';
        },

        clearErrors() {
            this.errorMessage = '';
            this.hasError = false;
            this.crossChecked = false;
            this.showOTP = false;
            this.sendMethod = '';
            this.statusMessage = '';
        },

        updateCode({ name, iso2, dialCode }) {
            // console.log(name, iso2, dialCode);
            this.areaCode = dialCode.toString();
        },

        close() {
            this.isActivePasswordModal = false;
            this.resetEmail = '';
            this.showSignUpModal = false;
            this.showHelpModal = false;
        },

        isNumber(evt) {
            this.errorMessage = '';
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        signInByEmail: function () {
            NProgress.start();
            var self = this;
            signInWithEmailAndPassword(auth, self.email, self.password).then(
                (user) => {
                    // console.log(user);
                    // console.log(user.user.uid);
                    self.$store.dispatch('updateUserState').then(() => {
                        self.$store.dispatch('SETSUBSCRIPTIONINFO').then(() => {
                            // console.log('GOT ROLE', this.$store.getters.getUserRole);
                            //const userName = user.user.displayName;
                            const userName = self.email;
                            const userID = user.user.uid;
                            userHasEngaged({
                                userID: userID,
                                userName: userName,
                                message: 'A user has signed in to Offshore SMS by email login.',
                                subject: 'A user has signed in by email'
                            });
                            NProgress.done();
                            // self.$router.replace('my-dashboard');
                            self.goBack();
                        });
                        // console.log('GOT ROLE', this.$store.getters.getUserRole);
                        // //const userName = user.user.displayName;
                        // const userName = self.email;
                        // const userID = user.user.uid;
                        // var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
                        // userHasEngaged({
                        //   userID: userID,
                        //   userName: userName,
                        //   message: 'A user has signed in to Offshore SMS by email login.',
                        //   subject: 'A user has signed in by email'
                        // });

                        // self.$router.replace('my-dashboard');
                    });
                },
                (error) => {
                    NProgress.done();
                    alert(error.message);
                }
            );
        },

        launchResetPassword: function () {
            this.isActivePasswordModal = true;
        },

        validateUser() {
            let self = this;
            this.clearErrors();
            this.validating = true;
            this.attempts++;
            if (this.attempts > 4) {
                userHasEngaged({
                    userID: self.userID,
                    userName: self.userName,
                    message: 'A has reached 5 attempts to validate their login credentials on the web',
                    subject: self.attempts + ' OTP validation attempts with email address ' + self.email + ' and phone number ' + self.finalNumber
                });
            }
            NProgress.start();

            let success = false;
            checkUserExistsByEmail({
                sentEmailAddress: self.email
            })
                .then(function (result) {
                    // var sanitizedMessage = result.data.text;
                    // console.log('Result',sanitizedMessage);
                    // console.log('Message', result.data.message);
                    if (result.data.text != null) {
                        // userRecord["uid"] = result.data.text["uid"] ? result.data.text["uid"] : '';
                        // userRecord["phoneNumber"] = result.data.text["phoneNumber"] ? result.data.text["phoneNumber"] : '';
                        // userRecord["displayName"] = result.data.text["displayName"] ? result.data.text["displayName"] : '';
                        console.log('USER:', result.data.text);
                        if (result.data.message == 'Success') {
                            self.crossCheckNumber(result.data.text);
                        } else {
                            self.hasError = true;
                            self.validating = false;
                            self.errorMessage =
                                'The email address and phone number that you entered do not match in our system. Please check and try again.';
                            NProgress.done();
                        }
                    } else {
                        self.errorMessage = 'Validation error - please check your details and try again.';
                        self.validating = false;
                        NProgress.done();
                    }
                })
                .catch(function (error) {
                    console.log('Error', error.text);
                    NProgress.done();
                });
        },

        async emailTwilioCode() {
            let self = this;
            NProgress.start();
            this.sendMethod = 'email';
            this.sendingCode = true;

            sendTwilioVerifyEmailCode({
                recipient: self.email
            })
                .then(function (result) {
                    console.log('Twilio Data', result.data);

                    setTimeout(() => {
                        self.showInputs = false;
                        self.codeSent = true;
                        self.statusMessage = 'A One Time code has been sent to your email address. When it arrives, enter it below:';
                        self.sendingCode = false;
                        NProgress.done();
                    }, 300);
                })
                // .then(() => {
                //   var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
                //   userHasEngaged({
                //     userID: self.userID,
                //     userName: self.userName,
                //     message: self.userName + ' has requested an OTP code to ' + self.email,
                //     subject: self.userName + ' has requested an OTP Code by email',
                //   });
                // })
                .catch(function (error) {
                    console.log('Error sending auth Code', error.text);
                    NProgress.done();
                });
        },

        async smsTwilioCode() {
            let self = this;
            NProgress.start();
            this.sendMethod = 'sms';
            this.sendingCode = true;

            sendTwilioVerifySmsCode({
                recipient: self.finalNumber
            })
                .then(function (result) {
                    console.log('Twilio Data', result.data);

                    setTimeout(() => {
                        self.showInputs = false;
                        self.codeSent = true;
                        self.statusMessage = 'A One Time code has been sent to your phone number. When it arrives, enter it below:';
                        self.sendingCode = false;
                        NProgress.done();
                    }, 300);
                })
                // .then(() => {
                //   var userHasEngaged = firebase.functions().httpsCallable('userHasEngaged');
                //   userHasEngaged({
                //     userID: self.userID,
                //     userName: self.userName,
                //     message: self.userName + ' has requested an OTP code to ' + self.finalNumber,
                //     subject: self.userName + ' has requested an OTP Code by text',
                //   });
                // })
                .catch(function (error) {
                    console.log('Error sending auth Code', error.text);
                    NProgress.done();
                });
        },

        validateTwilioEmailCode() {
            let self = this;
            NProgress.start();
            this.checkingCode = true;

            console.log(self.email, self.otpCode);
            checkTwilioVerifyEmailCode({
                recipient: self.email,
                otpCode: self.otpCode
            })
                .then(function (result) {
                    console.log('Twilio Data', result.data);
                    let verification = result.data.text;
                    console.log('verification', verification);

                    if (verification.status == 'approved') {
                        self.creatingToken = true;
                        self.generateCustomToken();
                        self.statusMessage = 'Code confirmed. Retrieving account info...';
                    } else {
                        self.hasError = true;
                        self.errorMessage = 'Your code was incorrect, sorry. Please try again or click Get Help below.';
                        NProgress.done();
                    }
                    self.checkingCode = false;
                })
                .catch(function (error) {
                    console.log('Error sending auth Code', error.text);
                    NProgress.done();
                });
        },

        validateTwilioSmsCode() {
            let self = this;
            NProgress.start();
            this.checkingCode = true;

            checkTwilioVerifySmsCode({
                recipient: self.finalNumber,
                otpCode: self.otpCode
            })
                .then(function (result) {
                    console.log('Twilio Data', result.data);
                    let verification = result.data.text;
                    console.log('verification', verification);

                    if (verification.status == 'approved') {
                        self.creatingToken = true;
                        self.generateCustomToken();
                        self.statusMessage = 'Code confirmed. Retrieving account info...';
                    } else {
                        self.hasError = true;
                        self.errorMessage = 'Your code was incorrect, sorry. Please try again or click Get Help below.';
                        NProgress.done();
                    }
                    self.checkingCode = false;
                })
                .catch(function (error) {
                    console.log('Error sending auth Code', error.text);
                    NProgress.done();
                });
        },

        generateCustomToken() {
            let self = this;

            createCustomLoginToken({
                uid: self.userID
            })
                .then(function (result) {
                    let success = result.data.message == 'Success';
                    if (result.data.text) {
                        self.customToken = result.data.text;
                        self.loginWithCustomToken();
                    } else {
                        self.hasError = true;
                        self.errorMessage = 'There was an error validating your code, sorry. Please click Get Help below.';
                        NProgress.done();
                    }
                })
                .catch(function (error) {
                    console.log('Error generating token', error.text);
                    NProgress.done();
                });
        },

        async loginWithCustomToken() {
            let self = this;

            signInWithCustomToken(auth, this.customToken)
                .then((userCredential) => {
                    ``;
                    self.$store.dispatch('updateUserState').then(() => {
                        self.$store.dispatch('SETSUBSCRIPTIONINFO').then(() => {
                            console.log('GOT ROLE', this.$store.getters.getUserRole);
                            userHasEngaged({
                                userID: self.userID,
                                userName: self.userName,
                                message: self.userName + ' has signed in to the Offshore SMS web dashboard by OTP ' + self.sendMethod + ' login.',
                                subject: self.userName + ' has signed in by OTP ' + self.sendMethod
                            });
                            NProgress.done();
                            // self.$router.replace('my-dashboard');
                            self.goBack();
                        });
                    });
                })
                .catch((err) => {
                    console.log('Error during signInWithCustomToken', err);
                    self.hasError = true;
                    self.errorMessage = 'There was an error logging you in, sorry. Please click Get Help below.';
                    NProgress.done();
                });
        },

        firstName(fullname) {
            return fullname == '' || fullname == undefined || fullname == null ? '' : fullname.split(' ')[0];
        },

        crossCheckNumber(user) {
            // console.log('Cross check user', user);
            if (user.phoneNumber == this.finalNumber) {
                console.log('Cross check successful');
                this.crossChecked = true;
                NProgress.done();
                this.validating = false;
                this.showOTP = true;
                this.validating = false;
                this.showInputs = false;
                this.userID = user.uid;
                this.userName = user.displayName;
                this.statusMessage =
                    'So far so good ' + this.firstName(user.displayName) + '! We now need to send you a one-time security code by email or text';
            } else {
                console.log('Cross check NOT successful');
                this.hasError = true;
                this.validating = false;
                this.statusMessage = '';
                this.errorMessage = 'The email address and phone number that you entered do not match in our system. Please check and try again.';
                NProgress.done();
            }
        },

        resetPassword: function () {
            //console.log(this.resetEmail);
            //this.resetEmail = '';
            //this.isActivePasswordModal = false;
            NProgress.start();
            var self = this;

            // var auth = firebase.auth();

            var actionCodeSettings = {
                url: 'https://app.offshoresms.com.au/sign-in'
                //url: 'https://www.example.com/?email=user@example.com',
                // iOS: {
                //   bundleId: 'com.example.ios'
                // },
                // android: {
                //   packageName: 'com.example.android',
                //   installApp: true,
                //   minimumVersion: '12'
                // },
                // handleCodeInApp: true
            };

            sendPasswordResetEmail(this.resetEmail, actionCodeSettings)
                .then(function () {
                    // Email sent.
                    self.isActivePasswordModal = false;
                    self.resetEmail = '';
                    NProgress.done();
                    self.pwResetMsg = 'Password reset email sent. Please check your email inbox (or maybe Junk mail).';
                })
                .catch(function (error) {
                    // An error happened.
                    self.isActivePasswordModal = false;
                    self.resetEmail = '';
                    //console.log(error);
                    self.pwResetMsg = 'Something went wrong. Please refresh and try again.';
                    NProgress.done();
                });
        }
    }
};
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: pink;
    letter-spacing: 1px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: pink;
    letter-spacing: 1px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: pink;
    letter-spacing: 1px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: pink;
    letter-spacing: 1px;
}

h1 {
    color: #444;
}

.tabs {
    ul {
        li {
            a {
                span {
                    margin-left: 10px;
                }
            }
        }
    }
}

.final-number {
    margin-bottom: 1rem;
    // text-align: center;
    color: mediumseagreen;
    font-size: 1.1rem;

    span {
        letter-spacing: 1px;
    }
}

.form-group {
    &.inline {
        display: inline-block;
        width: calc(100% - 80px);
        margin-left: 10px;
    }
}

.passwordMessage {
    color: red;
    margin: 1rem auto;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
}

.notification {
    margin-top: 5px !important;
    padding: 0.9rem 2rem 0.7rem 2rem;
}

.bd-notification {
    margin-top: 1rem;
}

h2 {
    line-height: 1.5rem;
}

p {
    &.error {
        color: red;
        font-size: 0.8rem;
        text-indent: 6px;
    }
}

.form-group {
    &.tuck {
        margin-bottom: 0.5rem;
    }
}

.form-wrapper {
    text-align: left;
    input[type='email'],
    input[type='password'] {
        padding: 12px 10px;
        height: auto;
        border-radius: 3px;
        border-color: #bbb;
        box-shadow: none;

        &:focus {
            border-color: dodgerblue;
        }
    }
    label {
        margin-bottom: 12px;
    }

    &.error {
        input[type='email'] {
            border-color: red;
        }
    }
}

.phone-wrapper {
    display: flex;

    .country-code {
        width: 78px;

        .vue-country-select {
            padding: 6px;
        }
    }

    .phone-number {
        input[type='tel'] {
            padding: 12px 10px;
            height: auto;
            border-radius: 3px;
            border-color: #bbb;
            box-shadow: none;

            &:focus {
                border-color: dodgerblue;
            }
        }
    }
}

.twilio-code {
    input[type='tel'] {
        padding: 12px 10px;
        height: auto;
        border-radius: 3px;
        border-color: #bbb;
        -webkit-box-shadow: none;
        box-shadow: none;
        max-width: 180px;
        font-weight: bold;
        font-size: 1.7rem;
        letter-spacing: 1.5px;
        text-align: center;
        color: dodgerblue;
    }
}
</style>

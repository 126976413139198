<template>
  <div class="site-wrapper">
    <div class="container">

      <h3>Safety Information</h3>

      <div v-if="currentVessel != '' && currentVessel != undefined">

        <h1>{{ currentVessel.vesselName }}</h1>

        <span v-if="currentVessel.savedAppInfoSections.includes('Vessel Hazards')"
          class="button complex is-medium is-orange" @click="toPage('VesselHazards')">
          <div class="button-icon"><span><font-awesome-icon icon="exclamation-triangle" /></span></div>
          <div class="button-text">
            <div class="button-title">Vessel Hazards</div>
            <div class="button-subtitle">All hazards on this vessel</div>
          </div>
        </span>


        <span v-if="currentVessel.savedAppInfoSections.includes('Emergency Procedures')" class="button complex is-medium"
          @click="toPage('EmergencyProcedures')">
          <div class="button-icon"><span><font-awesome-icon icon="ship" /></span></div>
          <div class="button-text">
            <div class="button-title">Emergency Procedures</div>
            <div class="button-subtitle">Know what to do and when</div>
          </div>
        </span>


        <span v-if="currentVessel.savedAppInfoSections.includes('Emergency Contacts')"
          class="button complex is-medium is-green" @click="toPage('EmergencyContacts')">
          <div class="button-icon"><span><font-awesome-icon icon="users" /></span></div>
          <div class="button-text">
            <div class="button-title">Emergency Contacts</div>
            <div class="button-subtitle">Important contacts on this vessel</div>
          </div>
        </span>


        <span v-if="currentVessel.savedAppInfoSections.includes('General Safety Briefing')"
          class="button complex is-medium is-red" @click="toPage('SafetyBriefing')">
          <div class="button-icon"><span><font-awesome-icon icon="info" /></span></div>
          <div class="button-text">
            <div class="button-title">General Safety Briefing</div>
            <div class="button-subtitle">A safety checklist for this vessel</div>
          </div>
        </span>


        <div v-if="customLogs.length > 0">
          <div v-for="(log, idx) in customLogs" :key="`custom-${idx}`" class="button complex is-medium is-skip" @click="toCustomLog(log)">
              <div class="button-icon"><span><font-awesome-icon icon="pen" /></span></div>
              <div class="button-text">
                  <div class="button-title">{{log.checklistName}}</div>
                  <div class="button-subtitle">{{ truncate(log.logDescription,100) }}</div>
              </div>
          </div>
        </div>
        <div v-if="loadingCustomLogs">
          <div class="spacer s10"></div>
          <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /> Loading custom logs...</span>
          <div class="spacer s10"></div>
        </div>

        

      </div>

      <div class="spacer s20"></div>

      <div class="buttons centered">
        <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
      </div>

      <div class="spacer s50"></div>


    </div>
  </div>
</template>


<script>

import { db } from '../main.js';
import { collection, doc, onSnapshot, query, orderBy, setDoc, getDoc } from "firebase/firestore";

export default {

  mounted: function () {
    setTimeout(() => {
      this.loadAvailableCustomLogs();      
    }, 500);
  },

  computed: {
    currentVessel() {
      return this.$store.getters.getCurrentVessel;
    },
    currentManifestID() {
      return this.$store.getters.getCurrentManifestID;
    },
  },

  data: function () {
    return {
      loadingCustomLogs: false,
      linkedCustomLogs: [],
      customLogs: [],
    }
  },

  methods: {

    async loadAvailableCustomLogs() {
      // console.log(this.currentVessel.savedAppInfoSections,this.currentManifestID)
      let self = this;
      
      if (this.currentVessel.savedAppInfoSections.includes('Custom Logs') && this.currentManifestID && this.currentManifestID != '') {
        this.loadingCustomLogs = true;
        const docRef = doc(db, 'vessels/' + this.currentVessel.vesselID + '/passengerManifests', this.currentManifestID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          self.linkedCustomLogs = docSnap.data().linkedCustomLogs ? docSnap.data().linkedCustomLogs : [];
        } else {
          self.loadingCustomLogs = false;
        }
        setTimeout(() => {
          if (self.linkedCustomLogs.length > 0) {
            self.loadCustomLogs();
          } else {
            this.loadingCustomLogs = true;
          }
        }, 500);
      } 

      
      // if (this.currentVessel.savedAppInfoSections.includes('Custom Logs') && this.currentManifestID && this.currentManifestID != '') {
      //   this.loadingCustomLogs = true;
      //   db.collection('vessels').doc(this.currentVessel.vesselID).collection('passengerManifests').doc(this.currentManifestID).get()
      //   .then((doc) => {
      //     self.linkedCustomLogs = doc.data().linkedCustomLogs ? doc.data().linkedCustomLogs : [];
      //   })
      //   .then((_) => {
      //     if (self.linkedCustomLogs.length > 0) {
      //       self.loadCustomLogs();
      //     } else {
      //       this.loadingCustomLogs = true;
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     self.loadingCustomLogs = false;
      //   });
      // } 
    },

    toPage(page) {
      this.$router.push({
        name: page,
        params: {},
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    truncate(string,limit) {
        // console.log(string,limit);
        if (string == undefined || string == '') return '';
        return string.length > limit ? string.slice(0,limit) + '...' : string;
    },

    async loadCustomLogs() {
      let self = this;
      this.loadingCustomLogs = true;
      const q = query(collection(db, "vessels", this.currentVessel.vesselID, "customChecklists"), orderBy("index", "asc"));
      const data = onSnapshot(q, (querySnapshot) => {
          self.customLogs = [];
          querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              let allowWebLink = data.allowWebLink ? data.allowWebLink : false;
              let showInQR = data.webLinkInQr ? data.webLinkInQr : false;
              if (allowWebLink && showInQR && self.linkedCustomLogs.includes(doc.id)) self.customLogs.push(data);
          });
      },
      (error) => {
          console.log('error',error);
          self.loadingCustomLogs = false;
      });
      setTimeout(() => {
          console.log('loaded');
          self.loadingCustomLogs = false;
      }, 1500);

    },

    // formatDate: function (sentDate, format) {
    //     // return moment(sentDate).format(format);
    //     return new Date(sentDate).toDateString();
    // },

    toCustomLog(log) {
        console.log(log);
        let currentCustomLog = log.id;
        let webLink = log.webLink ? log.webLink : '';
        this.$store.dispatch("SETCURRENTCUSTOMLOG", { currentCustomLog });
        let self = this;
        this.$router.push({
            name: 'VesselLogs',
            params: {
                hasLog: webLink != '',
                webLink: webLink,
                sentCustomLog: log,
            },
        });
    }


  },




}

</script>



<style lang="scss" scoped></style>













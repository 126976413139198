import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    { path: '/check-in', name: 'CheckIn', component: () => import('../components/PassengerCheckIn'), props: true },

    { path: '/user-login', name: 'UserLogin', component: () => import('../components/UserLogin'), props: true },

    { path: '/about-this-vessel', name: 'AboutThisVessel', component: () => import('../components/AboutThisVessel') },

    { path: '/vessel-info', name: 'VesselInfo', component: () => import('../components/VesselInfo'), props: true },

    { path: '/custom-logs', name: 'CustomLogs', component: () => import('../components/CustomLogs'), props: true },

    { path: '/vessel-specs', name: 'VesselSpecs', component: () => import('../components/VesselSpecs'), props: true },

    { path: '/defects', name: 'Defects', component: () => import('../components/Defects'), props: true },

    { path: '/shared-sms', name: 'SharedSms', component: () => import('../components/SharedSms'), props: true },

    { path: '/vessels', name: 'Vessels', component: () => import('../components/Vessels'), props: true },

    { path: '/vessel-logs', name: 'VesselLogs', component: () => import('../components/VesselLogs'), props: true },

    { path: '/risk-calculator', name: 'RiskCalculator', component: () => import('../components/RiskCalculator'), props: true },

    { path: '/short', name: 'Short', component: () => import('../components/Short'), props: true },

    { path: '/safety-info', name: 'SafetyInfo', component: () => import('../components/SafetyInfo') },

    { path: '/visitor-check-in', name: 'VisitorCheckIn', component: () => import('../components/VisitorCheckIn') },

    { path: '/view-trip', name: 'ViewTrip', component: () => import('../components/ViewTrip'), props: true },

    { path: '/passenger-manifests', name: 'PassengerManifests', component: () => import('../components/PassengerManifests') },

    { path: '/vessel-hazards', name: 'VesselHazards', component: () => import('../components/VesselHazards') },

    { path: '/emergency-procedures', name: 'EmergencyProcedures', component: () => import('../components/EmergencyProcedures') },

    { path: '/emergency-contacts', name: 'EmergencyContacts', component: () => import('../components/EmergencyContacts') },

    { path: '/safety-briefing', name: 'SafetyBriefing', component: () => import('../components/SafetyBriefing') },

    { path: '/404', name: '404', component: () => import('../components/Error404') },

    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;

<template>
    <div class="site-wrapper">
        <div class="container">
            <h3>Vessel Hazards</h3>

            <div v-if="currentVessel != '' && currentVessel != undefined">
                <h1>{{ currentVessel.vesselName }}</h1>

                <h1 v-if="loadingData">
                    <span class="loading-spinner"><font-awesome-icon icon="spinner" class="fa-spin 3x" /></span>
                </h1>

                <!-- ============================================================================== -->

                <div v-if="!loadingData">
                    <div v-if="checklist.length > 0" class="checklist-wrapper">
                        <div class="checklist-item orange" :class="item.rating" v-for="item in checklist" :key="item.id" @click="showDetails(item)">
                            <div class="item-content">{{ item.title }}</div>
                            <div v-if="item.details != ''" class="details-icon">
                                <font-awesome-icon icon="info-circle" />
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="" v-else>No vessel hazards found.</div>
                </div>

                <!-- ============================================================================== -->
            </div>

            <div class="spacer s20"></div>

            <div class="buttons centered">
                <span @click="goBack()" class="button is-warning"><font-awesome-icon icon="chevron-left" /> &nbsp; Back</span>
            </div>

            <div class="spacer s50"></div>

            <details-modal :title="selectedItem.title" :theme="selectedItem.rating" @closed="close()" v-if="showDetailsModal">
                <div class="spacer s10"></div>
                <div class="hazard-item">
                    <div class="hazard-title">Hazard</div>
                    <div class="hazard-details">{{ selectedItem.title }}</div>
                </div>
                <div class="hazard-item">
                    <div class="hazard-title">Risks</div>
                    <div class="hazard-details">
                        <div v-for="(line, index) in selectedItem.risks.split('\n')" :key="`risk-${index}`">{{ line }}<br /></div>
                    </div>
                </div>
                <div class="hazard-item">
                    <div class="hazard-title">Controls</div>
                    <div class="hazard-details">
                        <div v-for="(line, index) in selectedItem.controls.split('\n')" :key="`control-${index}`">{{ line }}<br /></div>
                    </div>
                </div>
                <div class="hazard-item">
                    <div class="hazard-title">RISK RATING</div>
                    <div class="hazard-details bold" :class="selectedItem.rating">
                        <span class="rating caps">{{ selectedItem.rating }}</span>
                    </div>
                </div>
                <div class="hazard-item">
                    <div class="hazard-title">Who's Responsible?</div>
                    <div class="hazard-details">
                        {{ responsibleList(selectedItem.personResponsible) }}
                    </div>
                </div>
                <div class="spacer s10"></div>
                <h4 class="heading" v-if="selectedItem.versioned">Last Edit: {{ simpleDate(selectedItem.versionDate) }}</h4>
            </details-modal>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { collection, query, doc, orderBy, onSnapshot, getDoc, getDocs } from 'firebase/firestore';
const DetailsModal = () => import('../reusables/DetailsModal.vue');

export default {
    mounted: function () {
        this.loadVersionedChecklist();
        // this.loadFleetChecklist();
    },

    components: {
        DetailsModal
    },

    computed: {
        currentVessel() {
            return this.$store.getters.getCurrentVessel;
        }
    },

    data: function () {
        return {
            checklist: [],
            loadingData: false,
            showDetailsModal: false,
            selectedItem: ''
        };
    },

    methods: {
        toPage(page) {},

        close() {
            this.showDetailsModal = false;
            this.selectedItem = '';
        },

        goBack() {
            this.$router.go(-1);
        },

        simpleDate(sentDate, isDate, full) {
            const longOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
            const shortOptions = { month: 'short', day: 'numeric', year: 'numeric' };
            const dateOptions = full ? longOptions : shortOptions;
            if (!sentDate || sentDate == '') return '';
            return isDate ? sentDate.toLocaleDateString('en-US', dateOptions) : sentDate.toDate().toLocaleDateString('en-US', dateOptions);
        },

        responsibleList(array) {
            var tempList = array ? array.join(', ') : '';
            return tempList;
        },

        loadVersionedChecklist() {
            let self = this;
            const q = query(collection(db, 'vessels', this.currentVessel.vesselID, 'smsHazards'), orderBy('index', 'asc'));
            const data = onSnapshot(
                q,
                (querySnapshot) => {
                    self.checklist = [];
                    querySnapshot.forEach(async (docA) => {
                        console.log(docA.data());
                        let loadVersion = docA.data().currentVersionID ? true : false;
                        let archived = docA.data().archived ? docA.data().archived : false;
                        if (loadVersion && !archived) {
                            const refA = doc(
                                db,
                                'vessels/' + self.currentVessel.vesselID + '/smsHazards/' + docA.id + '/smsHazardsVersions',
                                docA.data().currentVersionID
                            );
                            const docRef = await getDoc(refA);
                            if (docRef.exists()) {
                                console.log(docRef.data());
                                self.checklist.push({
                                    id: docRef.id,
                                    title: docRef.data().title ? docRef.data().title : '',
                                    risks: docRef.data().risks ? docRef.data().risks : '',
                                    controls: docRef.data().controls ? docRef.data().controls : '',
                                    rating: docRef.data().newRating ? docRef.data().newRating : '',
                                    postedBy: docRef.data().postedBy ? docRef.data().postedBy : '-',
                                    timestamp: docRef.data().timestamp ? docRef.data().timestamp : '',
                                    personResponsible: docRef.data().personResponsible ? docRef.data().personResponsible : [],
                                    uploadUrls: docRef.data().uploadUrls ? docRef.data().uploadUrls : [],
                                    versioned: true,
                                    savedByName: docRef.data().savedByName ? docRef.data().savedByName : '',
                                    versionDate: docRef.data().versionDate ? docRef.data().versionDate : '',
                                    likelihood: docRef.data().newLikelihood ? docRef.data().newLikelihood.label : '',
                                    consequence: docRef.data().newConsequence ? docRef.data().newConsequence.label : '',
                                    index: docA.data().index ? docA.data().index : 999
                                });
                            }
                        } else {
                            if (!archived)
                                self.checklist.push({
                                    id: docA.id,
                                    title: docA.data().title ? docA.data().title : '',
                                    risks: docA.data().risks ? docA.data().risks : '',
                                    controls: docA.data().controls ? docA.data().controls : '',
                                    rating: docA.data().newRating ? docA.data().newRating : '',
                                    postedBy: docA.data().postedBy ? docA.data().postedBy : '-',
                                    timestamp: docA.data().timestamp ? docA.data().timestamp : '',
                                    personResponsible: docA.data().personResponsible ? docA.data().personResponsible : [],
                                    uploadUrls: docA.data().uploadUrls ? docA.data().uploadUrls : [],
                                    likelihood: docA.data().newLikelihood ? docA.data().newLikelihood.label : '',
                                    consequence: docA.data().newConsequence ? docA.data().newConsequence.label : '',
                                    versioned: false,
                                    index: docA.data().index ? docA.data().index : 999
                                });
                        }
                    });
                },
                (error) => {
                    self.loadingData = false;
                }
            );
            if (this.currentVessel.vesselID && this.currentVessel.isCompanyVessel) this.loadFleetChecklist();
            setTimeout(() => {
                console.log('checklist loaded');
                self.loadingData = false;
            }, 1500);
        },

        loadFleetChecklist() {
            let self = this;

            if (this.currentVessel.vesselID && this.currentVessel.isCompanyVessel) {
                const q = query(collection(db, 'companies', this.currentVessel.vesselOwnerID, 'companyHazards'), orderBy('index', 'asc'));
                const data = onSnapshot(
                    q,
                    (querySnapshot) => {
                        querySnapshot.forEach(async (docB) => {
                            if (self.currentVessel.savedCompanyHazards && self.currentVessel.savedCompanyHazards.includes(docB.id)) {
                                // new
                                let loadVersion = docB.data().currentVersionID ? true : false;
                                let archived = docB.data().archived ? docB.data().archived : false;
                                if (self.currentVessel.savedCompanyHazards.includes(docB.id) && loadVersion && !archived) {
                                    const refB = doc(
                                        db,
                                        'companies/' + self.currentVessel.vesselOwnerID + '/companyHazards/' + docB.id + '/companyHazardsVersions',
                                        docB.data().currentVersionID
                                    );
                                    const docRefTwo = await getDoc(refB);
                                    if (docRefTwo.exists()) {
                                        self.checklist.push({
                                            id: docRefTwo.id,
                                            title: docRefTwo.data().title ? docRefTwo.data().title : '',
                                            risks: docRefTwo.data().risks ? docRefTwo.data().risks : '',
                                            controls: docRefTwo.data().controls ? docRefTwo.data().controls : '',
                                            rating: docRefTwo.data().newRating ? docRefTwo.data().newRating : '',
                                            postedBy: docRefTwo.data().postedBy ? docRefTwo.data().postedBy : '-',
                                            timestamp: docRefTwo.data().timestamp ? docRefTwo.data().timestamp : '',
                                            personResponsible: docRefTwo.data().personResponsible ? docRefTwo.data().personResponsible : [],
                                            uploadUrls: docRefTwo.data().uploadUrls ? docRefTwo.data().uploadUrls : [],
                                            versioned: true,
                                            savedByName: docRefTwo.data().savedByName ? docRefTwo.data().savedByName : '',
                                            versionDate: docRefTwo.data().versionDate ? docRefTwo.data().versionDate : ''
                                        });
                                    }
                                } else {
                                    if (self.currentVessel.savedCompanyHazards.includes(docB.id) && !archived)
                                        self.checklist.push({
                                            id: docB.id,
                                            title: docB.data().title ? docB.data().title : '',
                                            risks: docB.data().risks ? docB.data().risks : '',
                                            controls: docB.data().controls ? docB.data().controls : '',
                                            rating: docB.data().newRating ? docB.data().newRating : '',
                                            //imageURL: (docB.data().image ? docB.data().image : ''),
                                            postedBy: docB.data().postedBy ? docB.data().postedBy : '-',
                                            timestamp: docB.data().timestamp ? docB.data().timestamp : '',
                                            personResponsible: docB.data().personResponsible ? docB.data().personResponsible : [],
                                            uploadUrls: docB.data().uploadUrls ? docB.data().uploadUrls : [],
                                            likelihood: docB.data().newLikelihood ? docB.data().newLikelihood.label : '',
                                            consequence: docB.data().newConsequence ? docB.data().newConsequence.label : '',
                                            versioned: false
                                        });
                                }
                            }
                        });
                    },
                    (error) => {
                        console.log('Fleet snap error');
                    }
                );
            }
        },

        showDetails(item) {
            // console.log(item);
            this.selectedItem = item;
            if (item.details != '') {
                this.showDetailsModal = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.hazard-item {
    margin-bottom: 1.5rem;

    .hazard-title {
        font-size: 0.8rem;
        text-transform: uppercase;
        color: #666;
        margin-bottom: 5px;
    }

    .hazard-details {
        padding-left: 6px;

        &.low {
            color: green;
        }

        &.medium {
            color: orange;
        }

        &.high {
            color: red;
        }
    }
}

.checklist-item {
    &.low {
        background: #67bf8e;
    }

    &.medium {
        background: #ffbb3e;
    }

    &.high {
        background: #dd7979;
    }
}
h4.heading {
    margin: 1rem 0;
}
</style>
